export async function getPincodeDetails(pincode: number) {
  try {
    const res = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
    const data = await res.json();
    const state = data[0].PostOffice[0].State;
    const city = data[0].PostOffice[0].District;
    return { city, state };
  } catch (error) {
    throw new Error("Invalid pincode or can't get the details.");
  }
}

export function checkPhoneNumber(event: any, field: any) {
  // Extract the input value
  let val = event.target.value;

  // Remove any non-digit characters
  val = val.replace(/\D/g, "");

  // Limit the value to a maximum of 10 digits
  if (val.length > 10) {
    val = val.slice(0, 10);
  }

  // Update the form field value
  field.onChange({ target: { value: val } });
}
