import EmptyMenu from "../components/EmptyMenu";
import { useEffect, useState } from "react";
import { MenuTable } from "../components/MenuTable";
import { getMenuList } from "@/services/menuService";

export default function Menu() {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    getMenuList()
      .then((res) => {
        setData(res?.data?.menus);
      })
      .catch((err) => console.log(err));
  }, []);

  if (!data.length) {
    return <EmptyMenu />;
  }

  return <MenuTable data={data} />;
}
