import UploadFile from "@/components/media/UploadFile";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { getStoreDetails, updateStoreDetails } from "@/services/storeServices";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
interface file {
  _id: string;
  store_id: string;
  title: string;
  url: string;
  type: string;
}

function StoreMeta() {
  const [favicon, setFavicon] = useState<file[]>([]);
  const { handleSubmit, register, setValue } = useForm({
    defaultValues: {
      meta: {
        title: "",
        description: "",
      },
    },
  });

  useEffect(() => {
    getStoreDetails()
      .then((res: any) => {
        const prefill = res?.data?.data;
        setFavicon(prefill?.meta?.favicon);
        setValue("meta", prefill?.meta);
      })
      .catch((err) => console.log(err));
  }, []);

  function onSubmit(payload: any) {
    updateStoreDetails({
      meta: { ...payload.meta, favicon: favicon },
    })
      .then((res) => {
        res.status == 200 &&
          toast({
            duration: 3000,
            title: "Updated",
            description: "Store settings has been updated successfully",
          });
        console.log(res);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
        console.log(error);
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid gap-2">
        <Label>Favicon</Label>
        <UploadFile
          accept="image"
          maxLength={1}
          files={favicon}
          setFiles={setFavicon}
        />
      </div>
      <div className="grid gap-2">
        <Label>Title</Label>
        <Input {...register("meta.title")} />
      </div>
      <div className="grid gap-2">
        <Label>Description</Label>
        <Textarea {...register("meta.description")} />
      </div>

      <Button size="sm" type="submit">
        Update
      </Button>
    </form>
  );
}

export default StoreMeta;
