import CustomerForm from "../components/CustomerForm";
import OrderTable from "@/modules/Orders/components/OrderTable";
import { getCustomerDetails } from "@/services/customerServices";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderListByCustomer } from "@/services/orderService";

type user = {
  _id: string;
  email: string;
  password: string;
  name: string;
  store_id: string;
  address: [];
};

const CustomerDetails = () => {
  const { id } = useParams();

  const [userData, setUserData] = useState<user | null>(null);
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    getCustomerDetails(id)
      .then((res) => setUserData(res.data.customer))
      .catch((err) => console.log(err));

    getOrderListByCustomer(id)
      .then((res) => {
        setOrderList(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-full gap-6 flex flex-col">
      <div className=" w-full flex flex-col lg:flex-row gap-6">
        <CustomerForm userData={userData} />
        <div className="w-full">
          {
            // <EmptyTable
            //   data={{
            //     moduleTitle: "Orders",
            //     title: "Mohit hasn't placed any order yet.",
            //     description:
            //       "Manage customer details, see customer order history, separate list for online & offline customers. Add your first customer.",
            //   }}
            // />
          }

          <OrderTable data={orderList} />
        </div>
      </div>
      {/* <Card className="flex flex-col gap-4 border-none shadow-none">
        <CardHeader className="p-0">
          <CardTitle>Saved Addresses</CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 p-0 gap-4">
          {Array(5)
            .fill({})
            .map((_, index) => (
              <Card className="p-4 xl:max-w-[600px] w-full" key={index}>
                <CardDescription>
                  <div>Address line 1</div>
                  <div>Address line 2</div>
                  <div>Country</div>
                  <div className="flex gap-4">
                    <div>state</div>
                    <div>city</div>
                    <div>Pincode</div>
                  </div>
                </CardDescription>
              </Card>
            ))}
        </CardContent>
      </Card> */}
    </div>
  );
};

export default CustomerDetails;
