import { Card, CardTitle } from "../ui/card";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { Textarea } from "../ui/textarea";

function MetaForm({ register }: { register: UseFormRegister<FieldValues|any> }) {
  return (
    <Card className=" p-4 flex flex-col gap-4">
      <CardTitle>Meta</CardTitle>
      <>
        <div>
          <Label>Title</Label>
          <Input {...register("meta.title")} />
        </div>

        <div>
          <Label>Slug</Label>
          <Input {...register("slug",{required:true})} />
        </div>

        <div>
          <Label>Description</Label>
          <Textarea {...register("meta.description")} />
        </div>
      </>
    </Card>
  );
}

export default MetaForm;
