import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import { variantInterface } from "./interface";

type props = {
  modal: variantInterface | null;
  setModal: React.Dispatch<React.SetStateAction<variantInterface | null>>;
  options: any;
  setVariants: React.Dispatch<React.SetStateAction<variantInterface[]>>;
};

function EditVariant({ modal, setModal, options, setVariants }: props) {
  const [formData, setFormData] = useState<variantInterface | null>(
    structuredClone(modal)
  );

  function handleInput(type: string, value: string | number) {
    setFormData((prev: any) => ({
      ...prev,
      [type]: value,
    }));
  }

  function handleUpdateButton() {
    setVariants((prev: any) => {
      const index = prev.indexOf(modal);
      prev[index] = formData;

      return [...prev];
    });

    setModal(null);
  }

  return (
    <Dialog open={Boolean(modal)} onOpenChange={() => setModal(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Variant</DialogTitle>
        </DialogHeader>
        <>
          <div className=" flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <Label className="capitalize">{formData!?.option_1_name}</Label>
              <Select
                onValueChange={(value) =>
                  handleInput(formData!?.option_1_name, value)
                }
                value={formData?.option_1_value}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                  {options
                    .find((item: any) => item.name == formData?.option_1_name)
                    .values.map((option: string, index: number) => (
                      <SelectItem key={index} value={option} className="capitalize">
                        {option}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>

            {formData?.option_2_name && (
              <div className="flex flex-col gap-2">
              <Label className="capitalize">{formData!?.option_2_name}</Label>
                <Select
                  onValueChange={(value) =>
                    handleInput(formData?.option_2_name, value)
                  }
                  value={formData?.option_2_value}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Theme" />
                  </SelectTrigger>
                  <SelectContent>
                    {options
                      .find((item: any) => item.name == formData?.option_2_name)
                      .values.map((option: string, index: number) => (
                        <SelectItem key={index} value={option} className="capitalize">
                          {option}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>

          <Separator />

          <div className=" flex flex-col justify-between items-center gap-3">
            <div className="w-full gap-2 flex flex-col">
              <Label>Original Price</Label>
              <Input
                type="number"
                value={formData?.price}
                onChange={(e) => handleInput("price", e.target.value)}
              />
            </div>

            <div className="w-full gap-2 flex flex-col">
              <Label>Discount Price</Label>
              <Input
                type="number"
                value={formData?.discount_price}
                onChange={(e) =>
                  handleInput("discount_price", e.target.value)
                }
              />
            </div>

            <div className="w-full gap-2 flex flex-col">
              <Label>Inventory Count</Label>
              <Input
                type="number"
                value={formData?.inventory}
                onChange={(e) => handleInput("inventory", e.target.value)}
              />
            </div>

            <div className="w-full gap-2 flex flex-col">
              <Label>Weight <span className="text-gray-400">(in kgs)</span></Label>
              <Input
                type="number"
                placeholder="1.2"
                value={formData?.weight}
                onChange={(e) => handleInput("weight", e.target.value)}
              />
            </div>
          </div>
        </>
        <DialogFooter>
          <Button type="button" size="sm" variant="outline" onClick={() => setModal(null)}>
            Discard changes
          </Button>

          <Button type="button" size="sm" onClick={handleUpdateButton} className="bg-primary">
            Update changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EditVariant;
