import { CardTitle } from "@/components/ui/card";
// import OnboardingList from "../components/OnboardingList";
import Metrics from "../components/Metrics";
import { useEffect, useState } from "react";
import { getMetrics } from "@/services/metricService";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { verifyShiprocketToken } from "@/services/shiprocket";
import { NavLink } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import { getDomains } from "@/services/domainService";
import { getStoreDetails } from "@/services/storeServices";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
type primaryDomainType = {
  url: string;
  type: string;
  status: string;
};

export function Home() {
  const [duration, setDuration] = useState("week");
  const [metrics, setMetrics] = useState(null);
  const [primaryDomain, setPrimaryDomain] = useState<primaryDomainType>({
    url: "",
    type: "",
    status: "",
  });
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    getMetrics(duration)
      .then((res) => {
        setMetrics(res.data);
      })
      .catch((err) => console.log(err));
  }, [duration]);

  // check shiprocket token
  useEffect(() => {
    verifyShiprocketToken()
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getDomains().then((res: any) => {
      const primary = res?.data?.domain?.find((i: any) => i?.is_primary);
      setPrimaryDomain(primary);
    });

    getStoreDetails().then((res: any) => {
      setStoreName(res?.data?.data?.store_name);
    });
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <CardTitle className="text-xl">Welcome to {storeName} store</CardTitle>
        <div className="flex gap-4 items-center">
          {primaryDomain?.url ? 
            primaryDomain?.status === "Connected" ? 
            <NavLink
              to={
                primaryDomain?.type === "default"
                  ? `https://${primaryDomain?.url}.pinecart.in`
                  : `https://${primaryDomain?.url}`
              }
              target="_blank"
              className="text-[14px] text-gray-400 leading-4 flex items-center gap-1 hover:text-green-900 underline"
            >
              Visit Website
              <ExternalLink size={10} className="text-green-900" />
            </NavLink>
           :
          <HoverCard>
            <HoverCardTrigger asChild>
              <div className="text-sm text-orange-500 underline decoration-dotted decoration-orange-400 decoration-1 cursor-pointer">
                Verifying Website
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">
              <p className="text-xs">
                Your domain is marked for verification. It will be live soon and
                you'll be notified on email.
              </p>
            </HoverCardContent>
          </HoverCard> : <></>}
          <Select
            onValueChange={(value) => setDuration(value)}
            value={duration}
          >
            <SelectTrigger className="w-[180px] shadow-none focus:ring-0">
              <SelectValue placeholder="Select Option" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="week">Week</SelectItem>
              <SelectItem value="month">Month</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      {metrics && <Metrics metrics={metrics} duration={duration} />}
      {/* <OnboardingList /> */}
    </div>
  );
}
