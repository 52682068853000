import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { CheckCircle2, Copy } from "lucide-react";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { domainSettingFormSchema } from "@/modules/Store/schemas/domainSettings";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { DialogTitle } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";

type props = {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  data?: {
    type: String;
  };
};

export default function DomainConnect({ openMenu, setOpenMenu, data }: props) {
  const form = useForm<z.infer<typeof domainSettingFormSchema>>({
    resolver: zodResolver(domainSettingFormSchema),
    defaultValues: {
      url: "",
    },
  });



  async function submitHandler(
    formData: z.infer<typeof domainSettingFormSchema>
  ) {
    const payload = {
      ...formData,
    };
    console.log(payload);
  }

  return (
    <Drawer direction="right" open={openMenu} onOpenChange={setOpenMenu}>
      <DrawerContent className="bg-white flex flex-col rounded-none outline-none h-full max-w-[350px] w-full !left-auto right-0">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(submitHandler)}
            className="h-full bg-red-400"
          >
            <ScrollArea className="h-full bg-slate-800 w-full flex justify-center items-center">
              <div className="p-4 bg-white flex flex-col gap-6 min-h-screen">
                <DialogTitle>Domain Setting</DialogTitle>
                <DrawerDescription className="grid gap-4">
                  <div className="grid gap-2">
                    <Label>Domain</Label>
                    {data?.type == "default" ? (
                      <FormField
                        control={form.control}
                        name="url"
                        render={({ field }) => (
                          <FormItem className=" flex items-center gap-1">
                            <FormControl>
                              <Input
                                placeholder="www.domain.com"
                                {...field}
                                className="max-w-[150px]"
                              />
                            </FormControl>
                            <p>.pinecart.com</p>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ) : (
                      <FormField
                        control={form.control}
                        name="url"
                        render={({ field }) => (
                          <FormItem className=" flex items-center gap-1">
                            <FormControl>
                              <Input placeholder="www.domain.com" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                  {data?.type !== 'default' && 
                  <div className="flex flex-col gap-4">
                  <div className="grid gap-6">
                    <div className="grid gap-2">
                      <Label>DNS instructions</Label>
                      <p>
                        Change your DNS records in your third-party domain
                        provider account. On your domain provide's website, log
                        in to your account. Find the DNS settings or domain
                        management area.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <Label className="text-gray-600">Point A record to 23.227.38.65</Label>
                      <p>
                        Point A record to Pinecart IP 23.227.38.65. You can have
                        only one A record associated with your primary domain.
                        If your domain is already associated with an A record,
                        point it to the Pinecart IP address, then remove the old
                        A record.
                      </p>

                      <div className="relative">
                        <Input
                          defaultValue={"premiumpixels.com A 23.227.38.65"}
                          contentEditable="false"
                        />
                        <span
                          className="absolute right-2 top-0 bottom-0 flex justify-center 
                      items-center p-1"
                        >
                          <Copy
                            onClick={() =>
                              navigator.clipboard.writeText(
                                "premiumpixels.com A 23.227.38.65"
                              )
                            }
                            size={16}
                            className="text-gray-400"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="grid gap-2">
                      <Label className="text-gray-600">
                        Point www CNAME to pattyclub.pinecart.com
                      </Label>
                      <p>
                        Point the CNAME record with the name www to
                        pattyclub.pinecart.com.
                      </p>

                      <div className="relative">
                        <Input
                          defaultValue={"www CNAME pattyclub.pinecart.com"}
                          contentEditable="false"
                        />
                        <span
                          className="absolute right-2 top-0 bottom-0 flex justify-center 
                      items-center p-1"
                        >
                          <Copy
                            onClick={() =>
                              navigator.clipboard.writeText(
                                "www CNAME pattyclub.pinecart.com"
                              )
                            }
                            className="text-gray-400"
                            size={16}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-4">
                    <div className="flex items-center gap-2">
                      <CheckCircle2 className="text-green-500" />
                      <Label className="font-normal">
                        DNS Settings verified
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <CheckCircle2 className="text-green-500" />
                      <Label className="font-normal">
                        SSL Certificate created
                      </Label>
                    </div>
                  </div>
                  </div>
                  }
                </DrawerDescription>
                <DrawerFooter className="flex gap-2">
                  <Button>Verify Domain</Button>
                  <DrawerClose>Cancel</DrawerClose>
                </DrawerFooter>
              </div>
            </ScrollArea>
          </form>
        </Form>
      </DrawerContent>
    </Drawer>
  );
}
