import { FileVideo } from 'lucide-react'

function VideoMediaCard({data}:any) {
  return (
    <div className='relative overflow-hidden flex justify-center items-center h-full'>
        <FileVideo className="opacity-100 text-white absolute z-10" size={40} />
        <div className='bg-black w-full h-full absolute opacity-40' />
        <video src={data?.url} controls={false} className='w-full h-full object-cover'/>
    </div>
  )
}

export default VideoMediaCard