import { Link, NavLink, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Card,
  // CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { registerFormSchema } from "@/modules/Authentication/schemas/register";
import { z } from "zod";
import { EyeOff, Loader2 } from "lucide-react";
import { Eye } from "lucide-react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { googleSignup, registerUser } from "@/services/authService";
import AuthVisual from "../components/AuthVisual";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "@/components/ui/use-toast";

export default function RegisterForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const form = useForm<z.infer<typeof registerFormSchema>>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
  });

  function submitHandler(formData: z.infer<typeof registerFormSchema>) {
    setIsLoading(true);
    registerUser(formData)
      .then(() => {
        localStorage.setItem("first_name", formData.first_name);
        form.reset();
        setHasRegistered(true);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }

  function handleGoogleSign(credentialResponse: any) {
    googleSignup({credential:credentialResponse.credential})
      .then((res) => {
        const { first_name, token } = res.data;
        localStorage.setItem("first_name", first_name);
        navigate(`/create-store?secret=${token}`, {
          replace: true,
        });
      })
      .catch((err) => 
      {
        toast({
          duration: 3000,
          title: "Error",
          description: "You are already registered. Please login to continue!",
        });
        console.log(err);
      })
  }

  return (
    <div className="w-full md:grid md:min-h-[600px] h-full p-4 md:grid-cols-2">
      <div className="flex flex-col items-center justify-center py-12 px-6">
        {!hasRegistered ? (
          <div className="mx-auto grid max-w-sm gap-6">
            <div className="grid gap-2">
              <h1 className="text-3xl font-medium">
                Create a Pinecart account
              </h1>
              <p className="text-balance text-muted-foreground">
                Enter your information to create an account
              </p>
            </div>
            <div>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(submitHandler)}>
                  <div className="grid gap-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid gap-2">
                        <FormField
                          control={form.control}
                          name="first_name"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>First name</FormLabel>
                              <FormControl>
                                <Input placeholder="John" {...field} className="placeholder:text-gray-300 shadow-none"/>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="grid gap-2">
                        <FormField
                          control={form.control}
                          name="last_name"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Last name</FormLabel>
                              <FormControl>
                                <Input placeholder="Doe" {...field} className="placeholder:text-gray-300 shadow-none"/>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid gap-2">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                              <Input
                                placeholder="johndoe@gmail.com"
                                className="placeholder:text-gray-300 shadow-none"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="grid gap-2">
                      <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                              <div className="relative">
                                <Input
                                  placeholder="Password"
                                  className="placeholder:text-gray-300 shadow-none"
                                  type={showPassword ? "text" : "password"}
                                  {...field}
                                />
                                <div
                                  className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-500"
                                  onClick={() => setShowPassword((p) => !p)}
                                >
                                  {showPassword ? (
                                    <Eye size={16} />
                                  ) : (
                                    <EyeOff size={16} />
                                  )}
                                </div>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <Button
                      type="submit"
                      className="w-full bg-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Please wait
                        </>
                      ) : (
                        "Create Account"
                      )}
                    </Button>
                    <div className="w-full text-center flex items-center justify-center">
                      <GoogleLogin
                        onSuccess={handleGoogleSign}
                        text="continue_with"
                        width={370}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </div>
                  </div>
                </form>
              </Form>
              <div className="mt-4 text-center text-sm">
                Already have a Pinecart account?{" "}
                <Link to="/login" className="underline font-semibold text-primary">
                  Login
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <Card className="mx-auto max-w-sm">
            <CardHeader>
              <CardTitle className="text-xl">Welcome aboard!</CardTitle>
              <CardDescription className="leading-snug">
                We've sent a confirmation email to your provided email address.
                Please click the link in the email to verify your account and
                continue.
              </CardDescription>
            </CardHeader>
          </Card>
        )}
        {!hasRegistered && <div className="flex items-start absolute bottom-4">
          <p className="max-w-sm text-sm">
            By creating an account, you agree to our{" "}
            <NavLink to={'https://www.pinecart.com/policies/terms-and-conditions'} target="_blank" className="underline text-primary">terms of use</NavLink>.
          </p>
        </div>}
      </div>
      <AuthVisual />
    </div>
  );
}
