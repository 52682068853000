import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";

type props = {
  modal: boolean;
  setModal: any;
  handleDelete: any;
};

function ConfirmationModal({ modal, setModal, handleDelete }: props) {
  return (
    <Dialog open={modal} onOpenChange={setModal}>
      <DialogContent>
        <DialogHeader className="flex flex-col gap-4">
          <DialogTitle>Are you absolutely sure you want to delete?</DialogTitle>
          <div className="flex gap-2 w-full justify-end">
            <Button size="sm" onClick={() => setModal(false)} className="bg-primary">Cancel</Button>
            <Button size="sm" variant="outline" className="border border-red-400 text-red-600" onClick={handleDelete}>Delete</Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationModal;
