import RichText from "@/components/common/RichText";
import { Card, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  createPage,
  getPageDetails,
  updatePage,
} from "@/services/pageServices";
import MetaForm from "@/components/common/MetaForm";

function PageForm() {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const pageId = searchParam.get("id");

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      title: "",
      content: "",
      slug: "",
      meta: {
        title: "",
        description: "",
      },
      status: "",
    },
  });

  useEffect(() => {
    if (pageId) {
      getPageDetails(pageId)
        .then((res) => {
          for (let key in res.data) {
            // @ts-ignore
            setValue(key, res.data[key]);
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {};
  }, []);

  function onSubmit(data: any) {
    if (pageId) {
      updatePage(data, pageId)
        .then(() => navigate(-1))
        .catch((err) => console.log(err));
    } else {
      createPage(data)
        .then(() => navigate(-1))
        .catch((err) => console.log(err));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" flex flex-col gap-4">
      <div className="flex gap-4">
        <div className=" w-3/4 flex flex-col gap-4">
          <Card className=" p-4 flex flex-col gap-4">
            <div>
              <Label>Title</Label>
              <Input
                {...register("title", {
                  onBlur(event) {
                    setValue(
                      "slug",
                      event?.target?.value
                        .toLocaleLowerCase()
                        .replace(/[^A-Z0-9]/gi, "-")
                    );
                  },
                })}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="content"
                render={({ field }) => (
                  <>
                    <Label>Content</Label>
                    <RichText field={field} />
                  </>
                )}
              />
            </div>
          </Card>

          {/* ----------------- meta --------------- */}
          <MetaForm register={register} />
        </div>
        <div className=" w-1/4">
          <Card className=" p-4 flex flex-col gap-4">
            <CardTitle>Visibility</CardTitle>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="active">Active</SelectItem>
                    <SelectItem value="draft">Draft</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
          </Card>
        </div>
      </div>

      <Button type="submit" className=" w-full">
        {pageId ? "Update" : "Create"}
      </Button>
    </form>
  );
}

export default PageForm;
