import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  // CardTitle,
} from "@/components/ui/card";
import StoreCard from "../components/StoreCard";
import classes from "./SelectStore.module.css";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "@/components/loaders/Spinner";
import { getStoreAccessToken, getStores } from "@/services/storeServices";

type Store = {
  _id: string;
  store_name: string;
  store_type: string;
  domain: string;
};

type StoreList = {
  primaryStore: Store[];
  accessStore: { store_id: Store }[];
  type: number;
};

const SelectStore = () => {
  const navigate = useNavigate();
  const [storeList, setStoreList] = useState<StoreList>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    getStores()
      .then(({ data }) => {
        if (data?.primaryStore.length || data?.accessStore.length) {
          setStoreList(data);
          return;
        }

        navigate("/create-store");
      })
      .catch((error) => console.log(error));
  }, []);

  function selectStoreHandler(item: Store, type?: string) {
    getStoreAccessToken(item?._id, type)
      .then((res) => {
        localStorage.setItem("access_token", res.data?.accessToken);
        localStorage.setItem("store_name", item?.store_name);
        navigate("/");
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={`flex h-full justify-center ${classes.container}`}>
      <div className={`flex container h-full justify-center items-center`}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="flex  justify-center flex-col gap-2">
            <Card className="mx-auto max-w-sm shadow-none bg-slate-50 border">
              <CardHeader>
                {/* <CardTitle className="text-2xl flex  items-center gap-2">
                  {storeList.length > 0
                    ? "Select Store"
                    : "Create your first store"}
                </CardTitle> */}
                <CardDescription>
                  Get a quick overview of your store's performance or dive into
                  detailed reports. Choose your store to start!
                </CardDescription>
              </CardHeader>
              <CardContent className="flex flex-col gap-3 max-h-[250px] overflow-auto no-scrollbar">
                {storeList?.primaryStore?.map((item, index) => (
                  <div key={index} onClick={() => selectStoreHandler(item)}>
                    <StoreCard data={item} />
                  </div>
                ))}

                {storeList?.accessStore?.map(({ store_id }, index) => (
                  <div
                    key={index}
                    onClick={() => selectStoreHandler(store_id, "secondary")}
                  >
                    <StoreCard data={store_id} />
                  </div>
                ))}
              </CardContent>
            </Card>
            {Boolean(storeList?.type) && (
              <Link to={`/create-store`} className="flex justify-center">
                <Button className="w-full">Create store</Button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectStore;
