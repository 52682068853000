import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { Textarea } from "@/components/ui/textarea";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { useEffect } from "react";

import { Switch } from "@/components/ui/switch";
import WarehouseCreateHeader from "../components/WarehouseCreateHeader";
import { Button } from "@/components/ui/button";
import {
  createWarehouse,
  getWarehouseDetails,
  updateWarehouseDetails,
} from "@/services/warehouseServices";
import { getPincodeDetails } from "@/utils/utils";

export function WarehouseForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const warehouse_id = searchParams.get("id");

  const { control, register, handleSubmit, setValue } = useForm<any>({
    defaultValues: {
      is_active: false,
      is_default: false,
    },
  });

  useEffect(() => {
    if (warehouse_id) {
      getWarehouseDetails(warehouse_id)
        .then((res) => {
          const response = res?.data;
          for (let key in response?.data) {
            setValue(key, response.data[key]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const onSubmit: SubmitHandler<any> = (data) => {
    const payload = {
      ...data,
    };

    if (warehouse_id) {
      updateWarehouseDetails(warehouse_id, payload)
        .then(() => {
          navigate(-1);
          toast({
            duration: 3000,
            title: "Warehouse Updated",
            description: "Warehouse has been updated successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    } else {
      createWarehouse(payload)
        .then(() => {
          navigate(-1);
          toast({
            duration: 3000,
            title: "Warehouse Added",
            description: "Warehouse has been added successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    }
  };

  async function getPinDetails(pincode: number) {
    try {
      const details = await getPincodeDetails(pincode);
      if (details?.state && details?.city) {
        setValue("address.state", details?.state);
        setValue("address.city", details?.city);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <form
      className="grid flex-1 items-start gap-4 md:gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid flex-1 auto-rows-max gap-4">
        <WarehouseCreateHeader create={warehouse_id ? false : true} />
        <div className="grid gap-4 lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-3 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0 ">
              <CardHeader>
                <CardTitle>General Details</CardTitle>
                <CardDescription>
                  Manage your warehouse details here.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="name">Warehouse Name</Label>
                    <Input
                      id="name"
                      type="text"
                      className="w-full"
                      placeholder="Enter warehouse name"
                      {...register("name", {
                        onBlur(event) {
                          setValue("name", event?.target?.value);
                        },
                      })}
                    />
                  </div>

                  <div className="grid gap-3">
                    <Label htmlFor="description">Description</Label>
                    <Textarea
                      id="description"
                      placeholder="Enter warehouse sort description"
                      className="min-h-20"
                      {...register("description")}
                    />
                  </div>

                  <div className="grid gap-3">
                    <Controller
                      control={control}
                      name="is_default"
                      render={({ field }) => (
                        <div className="flex gap-4 items-center">
                          <Label htmlFor="is_default">
                            This is my Primary warehouse
                          </Label>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            id="is_default"
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="grid gap-3">
                    <Controller
                      control={control}
                      name="is_active"
                      render={({ field }) => (
                        <>
                          <Label htmlFor="is_active">Active</Label>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            id="is_active"
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className="grid gap-4">
                    <div className="font-semibold text-lg">Address</div>
                    <div className="grid gap-3">
                      <Label htmlFor="address_one" className="text-slate-500">
                        Address 1
                      </Label>
                      <Input
                        id="address_one"
                        type="text"
                        className="w-full"
                        placeholder="Enter warehouse address 1"
                        {...register(
                          "address.address_one",

                          {
                            required: true,
                            onBlur(event) {
                              setValue(
                                "address.address_one",
                                event?.target?.value
                                  .toLocaleLowerCase()
                                  .replace(/[^A-Z0-9]/gi, "-")
                              );
                            },
                          }
                        )}
                      />
                    </div>
                    <div className="grid gap-3">
                      <Label htmlFor="address_two" className="text-slate-500">
                        Address 2 (optional)
                      </Label>
                      <Input
                        id="address_two"
                        type="text"
                        className="w-full"
                        placeholder="Enter warehouse address 2"
                        {...register("address.address_two", {
                          onBlur(event) {
                            setValue(
                              "address.address_two",
                              event?.target?.value
                                .toLocaleLowerCase()
                                .replace(/[^A-Z0-9]/gi, "-")
                            );
                          },
                        })}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      <div className="grid gap-3">
                        <Label htmlFor="city" className="text-slate-500">
                          City
                        </Label>
                        <Input
                          id="city"
                          type="text"
                          className="w-full"
                          placeholder="Enter city"
                          {...register("address.city", {
                            onBlur(event) {
                              setValue(
                                "address.city",
                                event?.target?.value
                                  .toLocaleLowerCase()
                                  .replace(/[^A-Z0-9]/gi, "-")
                              );
                            },
                          })}
                        />
                      </div>
                      <div className="grid gap-3">
                        <Label htmlFor="state" className="text-slate-500">
                          State
                        </Label>
                        <Input
                          id="state"
                          type="text"
                          className="w-full"
                          placeholder="Enter state"
                          {...register("address.state", {
                            onBlur(event) {
                              setValue(
                                "address.state",
                                event?.target?.value
                                  .toLocaleLowerCase()
                                  .replace(/[^A-Z0-9]/gi, "-")
                              );
                            },
                          })}
                        />
                      </div>
                      <div className="grid gap-3">
                        <Label htmlFor="pin_code" className="text-slate-500">
                          Pincode
                        </Label>
                        <Input
                          id="pin_code"
                          type="text"
                          className="w-full"
                          placeholder="Enter pincode"
                          {...register("address.pin_code", {
                            onBlur(event) {
                              if (event?.target?.value?.length == 6) {
                                getPinDetails(event?.target?.value);
                                setValue(
                                  "address.pin_code",
                                  event?.target?.value
                                    .toLocaleLowerCase()
                                    .replace(/[^A-Z0-9]/gi, "-")
                                );
                              }
                            },
                            onChange(event) {
                              const tgt = event?.target;
                              if (tgt && tgt.type === "text") {
                                let val = tgt.value;
                                val = val.replace(/\D/g, "");
                                if (val.length > 6) {
                                  val = val.slice(0, 6);
                                }
                                tgt.value = val;
                              }
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <Button className="w-full" size="sm">
                    {warehouse_id ? "Update Warehouse" : `Create Warehouse`}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </form>
  );
}
