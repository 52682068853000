import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function createStore(data: object) {
  return axiosInterceptor.post("/tag", data);
}

export function getTags() {
  return axiosInterceptor.get("/tag");
}

export function createTag(data: any) {
  return axiosInterceptor.post(`/tag`, data);
}

export function deleteTag(id: string | null) {
  return axiosInterceptor.delete(`/tag/${id}`);
}
