import { getFeedbackList } from "@/services/feedbackServices";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

type feedback = {
  email: string;
  message: string;
  name: string;
  phone_number: string;
  _id: string;
};

function Feedback() {
  const [data, setData] = useState<feedback[]>([]);

  useEffect(() => {
    init();
  }, []);

  function init() {
    getFeedbackList()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <Card x-chunk="dashboard-06-chunk-0">
        <CardHeader>
          <CardTitle>Store feedback</CardTitle>
        </CardHeader>
        <CardContent>
          {data?.length === 0 ? (
            <h3>No Data Found</h3>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone Number</TableHead>
                  <TableHead>Message</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((feedback) => (
                  <TableRow key={feedback._id}>
                    <TableCell className="font-medium capitalize">
                      {feedback.name}
                    </TableCell>
                    <TableCell>{feedback.email}</TableCell>
                    <TableCell>{feedback.phone_number}</TableCell>
                    <TableCell>
                      {feedback.message}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default Feedback;
