import {
  Tabs,
  TabsContent,
  // TabsList,
  // TabsTrigger,
} from "../../../components/ui/tabs";
// import {
//   DropdownMenu,
//   DropdownMenuCheckboxItem,
//   DropdownMenuContent,
//   DropdownMenuLabel,
//   DropdownMenuSeparator,
//   DropdownMenuTrigger,
// } from "../../../components/ui/dropdown-menu";
// import { Button } from "../../../components/ui/button";
// import { File, ListFilter } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Badge } from "../../../components/ui/badge";
import { Link } from "react-router-dom";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { ScrollArea } from "@/components/ui/scroll-area";
import dateFormat from "@/utils/dateFormt";
import Pagination from "@/components/common/Pagination";

export default function OrderTable({ data,page,setPage,meta }: any) {
  return (
    <Tabs defaultValue="all">
      {/* <span className="items-center hidden md:flex">
        <TabsList>
          <TabsTrigger value="all">All</TabsTrigger>
          <TabsTrigger value="confirmed">Confirmed</TabsTrigger>
          <TabsTrigger value="processing">Processing</TabsTrigger>
          <TabsTrigger value="completed">Completed</TabsTrigger>
          <TabsTrigger value="cancelled">Cancelled</TabsTrigger>
        </TabsList>
        <div className="ml-auto flex items-center gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-7 gap-1 text-sm">
                <ListFilter className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only">Filter</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Filter by</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem checked>
                Fulfilled
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Declined</DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Refunded</DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button size="sm" variant="outline" className="h-7 gap-1 text-sm">
            <File className="h-3.5 w-3.5" />
            <span className="sr-only sm:not-sr-only">Export</span>
          </Button>
        </div>
      </span> */}
      <TabsContent value="all">
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="px-7">
            <CardTitle>Orders</CardTitle>
            <CardDescription>Recent orders from your store.</CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <ScrollArea className="h-[390px]">
                <TableHeader>
                  <TableRow className="text-[12px]">
                    <TableHead className="hidden sm:table-cell">
                      Order ID
                    </TableHead>
                    <TableHead>Customer</TableHead>
                    <TableHead>Payment status</TableHead>
                    <TableHead>Shipping status</TableHead>
                    <TableHead>Items</TableHead>
                    <TableHead className="hidden md:table-cell">Date</TableHead>
                    <TableHead>Total</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.map((item: any, index: any) => (
                    <TableRow className="bg-accent" key={index}>
                      <TableCell className="hidden sm:table-cell font-semibold">
                        <Link
                          to={{
                            pathname: `/orders/${item._id}`,
                          }}
                        >
                          <p className="text-xs">#{item?._id}</p>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: `/orders/${item._id}`,
                          }}
                        >
                          <p className="text-xs capitalize">
                            {item?.user?.name}
                          </p>
                        </Link>
                      </TableCell>

                      <TableCell>
                        <Badge
                          className={`text-xs shadow-none select-none capitalize ${
                            item?.type === "Digital"
                              ? item.paymentInfo?.status === "captured"
                                ? "bg-primary text-white hover:bg-[#4D5F47cc]"
                                : "bg-red-600 text-white hover:bg-red-600" 
                              : "bg-yellow-300 text-gray-700 hover:bg-yellow-200"
                          }`}
                        >
                          {item?.type === "Digital"
                            ? item?.paymentInfo?.status === "captured"
                              ? "Success"
                              : "Failed"
                            : "COD"}
                        </Badge>
                      </TableCell>

                      <TableCell>
                        <Badge
                          variant="default"
                          className={`text-xs shadow-none select-none ${item?.status === 'pending' ? 'bg-gray-200 text-gray-800 hover:text-gray-50' : 'bg-primary'}`}
                        >
                          {item?.status === 'pending' ? 'Pending' : 'Fulfilled'}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <HoverCard>
                          <HoverCardTrigger asChild>
                            <div className="text-xs underline decoration-dotted decoration-gray-400 decoration-1 cursor-pointer">
                              {item?.products[0]?.name}
                              {item?.products?.length > 1 &&
                                ` + ${item?.products?.length - 1}`}
                            </div>
                          </HoverCardTrigger>
                          <HoverCardContent className="w-80">
                            <div className="flex flex-col justify-between gap-4">
                              {item?.products?.map((i: any, index: any) => {
                                return (
                                  <div key={index}>
                                    <h4 className="text-sm font-semibold">
                                      {i?.name}
                                    </h4>
                                    <p className="text-sm">
                                      ₹{i?.discount_price} x {i?.quantity} unit
                                      {i?.quantity > 1 ? "s" : ""}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </HoverCardContent>
                        </HoverCard>
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        <p className="text-xs">{dateFormat(item?.createdAt)}</p>
                      </TableCell>
                      <TableCell>
                        <p className="text-xs">₹{item?.total}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ScrollArea>
            </Table>
          </CardContent>

          <Pagination
            type="orders"
            page={page}
            data={meta}
            setPage={setPage}
            nextPage={data?.length < 10}
          />


        </Card>
      </TabsContent>
    </Tabs>
  );
}
