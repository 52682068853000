import { Search } from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import PowerupCard from "./PowerupCard";
import { Input } from "../../../components/ui/input";
import React, { useEffect, useState } from "react";
import { getCredentials } from "@/services/credentialService";
import integrationData from "@/utils/integrationData";

type card = {
  name: string;
  category: { id: number; name: string };
  icon: string;
  description: string;
  is_installed: boolean;
  is_active: boolean;
  key: string;
  formFields: {
    name: string;
    type: string;
  }[];
};

export default function List() {
  const [powerCards, setPowerCards] = useState<card[]>([]);

  useEffect(() => {
    getCredentials()
      .then((res) => {
        const credentials = res.data?.data?.credentials ?? [];

        if (credentials?.length) {
          const dummyMap = credentials.reduce((acc: any, element: any) => {
            // @ts-ignore
            acc[element.plugin_key] = element;
            return acc;
          }, {});

          const updateData = integrationData.map((item) => {
            // @ts-ignore
            const matchedObj = dummyMap[item.key];
            return matchedObj
              ? { ...item, ...matchedObj, script: item?.script }
              : item;
          });

          setPowerCards(updateData);
        } else {
          setPowerCards(integrationData);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Tabs defaultValue="all">
      <div className="mb-4 flex flex-col gap-4">
        <div className="flex flex-col justify-between md:flex-row items-center">
          <div>
            <h3 className="font-bold">Power-Ups</h3>
            <p>Supercharge your store with add-on features and tools.</p>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div>
            <TabsList>
              <TabsTrigger value="all">All (6)</TabsTrigger>
              <TabsTrigger value="active">Active (2)</TabsTrigger>
              <TabsTrigger value="inactive">Inactive (4)</TabsTrigger>
            </TabsList>
          </div>
          <div className="relative hidden md:block">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground z-100" />
            <Input
              type="search"
              placeholder="Search..."
              className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]"
            />
          </div>
        </div>
      </div>
      <TabsContent value="all">
        <div className=" gap-3 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
          {powerCards.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <PowerupCard data={item} />
              </React.Fragment>
            );
          })}
        </div>
      </TabsContent>
    </Tabs>
  );
}
