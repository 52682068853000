import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

export default function OrderMetrics({ data }: any) {

  return (
    <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
      <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-0">
        <CardHeader className=" h-full flex flex-col justify-center">
          <CardTitle>Your Orders</CardTitle>
          <CardDescription className="max-w-lg text-balance leading-relaxed">
            Introducing Our Dynamic Orders Dashboard for Seamless Management and
            Insightful Analysis.
          </CardDescription>
        </CardHeader>
        {/* <CardFooter>
          <NavLink to={"/orders/add"}>
            <Button>Create New Order</Button>
          </NavLink>
        </CardFooter> */}
      </Card>
      <Card>
        <CardHeader className="pb-2">
          <CardDescription>This Week Revenue</CardDescription>
          <CardTitle className="text-4xl">
            ₹{data?.revenue?.current}
          </CardTitle>
        </CardHeader>
      </Card>
      <Card>
        <CardHeader className="pb-2">
          <CardDescription>Average Order Value</CardDescription>
          <CardTitle className="text-4xl">
            ₹{data?.orders?.current === 0 ? 0 : (data?.revenue?.current/data?.orders?.current).toFixed(0)}
          </CardTitle>
        </CardHeader>
      </Card>
    </div>
  );
}
