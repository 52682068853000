import { File, ListFilter, MoreHorizontal, PlusCircle } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { ScrollArea } from "@/components/ui/scroll-area";
import { deletePage, getPageList } from "@/services/pageServices";
import dateFormat from "@/utils/dateFormt";
import Pagination from "@/components/common/Pagination";

type page = {
  _id: string;
  title: string;
  status: string;
  createdAt: Date;
};

export function PageTable() {
  const [data, setData] = useState<page[]>([]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectedId = useRef("");

  const [filter, setFilter] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState();

  useEffect(() => {
    init();
  }, [filter, page]);

  function init() {
    getPageList(filter)
      .then((res) => {
        setData(res.data.data);
        setMeta(res.data.meta);
      })
      .catch((err) => console.log(err));
  }

  function deleteProductHandler() {
    deletePage(selectedId.current)
      .then(() => {
        setConfirmationModal(false);
        init();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="flex h-full w-full flex-col gap-2 bg-muted/40">
      {confirmationModal && (
        <ConfirmationModal
          modal={confirmationModal}
          setModal={setConfirmationModal}
          handleDelete={deleteProductHandler}
        />
      )}
      <Tabs
        defaultValue="all"
        onValueChange={(value) =>
          setFilter(() => (value == "all" ? null : value))
        }
      >
        <div className="flex items-center">
          <TabsList>
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="draft">Draft</TabsTrigger>
            <TabsTrigger value="archived" className="hidden sm:flex">
              Archived
            </TabsTrigger>
          </TabsList>
          <div className="ml-auto flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-7 gap-1">
                  <ListFilter className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filter
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem checked>
                  Active
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Draft</DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Archived</DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button size="sm" variant="outline" className="h-7 gap-1">
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Export
              </span>
            </Button>
            <NavLink to="/page/create">
              <Button size="sm" className="h-7 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Create Page
                </span>
              </Button>
            </NavLink>
          </div>
        </div>
      </Tabs>
      <Card x-chunk="dashboard-06-chunk-0">
        <CardHeader>
          <CardTitle>Pages</CardTitle>
          <CardDescription>Manage your page data</CardDescription>
        </CardHeader>
        <CardContent>
          {!data?.length ? (
            <h2>No data found</h2>
          ) : (
            <>
              <ScrollArea className="h-[400px]">
                <Table>
                  <TableHeader>
                    <TableRow className="text-[12px]">
                      <TableHead>Title</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead className="hidden md:table-cell">
                        Created at
                      </TableHead>
                      <TableHead>
                        <span className="sr-only">Actions</span>
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <NavLink
                              to={`/page/edit?id=${item._id}`}
                              className="flex flex-row items-center gap-4 text-xs"
                            >
                              {item?.title}
                            </NavLink>
                          </TableCell>

                          <TableCell>
                            <Badge
                              variant="outline"
                              className={`capitalize border-none shadow-none font-normal ${
                                item?.status === "active"
                                  ? "bg-[#8D9967] text-white"
                                  : item.status === "draft"
                                  ? "bg-yellow-300"
                                  : "bg-red-300"
                              }`}
                            >
                              {item?.status}
                            </Badge>
                          </TableCell>
                          <TableCell className="hidden md:table-cell text-xs">
                            {dateFormat(item?.createdAt)}
                          </TableCell>
                          <TableCell>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  aria-haspopup="true"
                                  size="icon"
                                  variant="ghost"
                                >
                                  <MoreHorizontal className="h-4 w-4" />
                                  <span className="sr-only">Toggle menu</span>
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <NavLink to={`/page/edit?id=${item._id}`}>
                                  <DropdownMenuItem>Edit</DropdownMenuItem>
                                </NavLink>
                                <DropdownMenuItem className=" p-0">
                                  <button
                                    className=" w-full text-left p-2"
                                    onClick={() => {
                                      selectedId.current = item?._id;
                                      setConfirmationModal(true);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </ScrollArea>
              <Pagination
                type="pages"
                page={page}
                data={meta}
                setPage={setPage}
                nextPage={data.length < 10}
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
