import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function availableCouriers(payload: {
  pickup_postcode: string;
  delivery_postcode: string;
  cod: number;
  weight: number;
}) {
  return axiosInterceptor.post(`/shiprocket/courierList`, payload);
}

export function createShippingOrder(payload: any) {
  return axiosInterceptor.post(`/shiprocket/createOrder`, payload);
}

export function verifyShiprocketToken() {
  return axiosInterceptor.get(`/shiprocket/verify`);
}

export function shipmentDetail(shipment_id: number) {
  return axiosInterceptor.get(`/shiprocket/shipmentDetails`, {
    params: {
      shipment_id,
    },
  });
}

export function pickup(payload: any) {
  return axiosInterceptor.post(`/shiprocket/pickup`, payload);
}

export function cancelShipment(payload: any) {
  return axiosInterceptor.post(`/shiprocket/cancel`, payload);
}

export function shiprocketDocument(
  type: "label" | "invoice" | "manifest",
  payload: any
) {
  return axiosInterceptor.post(`/shiprocket/document/${type}`, payload);
}

export function pickupAddress() {
  return axiosInterceptor.get(`/shiprocket/pickupAddress`);
}

// export function createShippingOrder1(payload: any) {
//   return axios.post(
//     "https://apiv2.shiprocket.in/v1/external/orders/create/adhoc",
//     payload,
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// }

// export function courierList(order_id: number) {
//   return axios.get(
//     `https://apiv2.shiprocket.in/v1/external/courier/serviceability/?order_id=${order_id}`,
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// }

// export function courierListWithoutOrder({
//   pickup_postcode,
//   delivery_postcode,
//   cod,
//   weight,
// }: any) {
//   return axios.get(
//     `https://apiv2.shiprocket.in/v1/external/courier/serviceability/?pickup_postcode=${pickup_postcode}&delivery_postcode=${delivery_postcode}&cod=${cod}&weight=${weight}`,
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// }

// export function generateAWB(payload: {
//   shipment_id: number;
//   courier_id: number;
// }) {
//   return axios.post(
//     "https://apiv2.shiprocket.in/v1/external/courier/assign/awb",
//     payload,
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// }

// export function pickupRequest(shipment_id: number) {
//   return axios.post(
//     "https://apiv2.shiprocket.in/v1/external/courier/generate/pickup",
//     { shipment_id: [shipment_id] },
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// }
