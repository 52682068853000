import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { NavLink } from "react-router-dom";

export default function CreateDiscountHeader() {
  return (
    <div className="flex items-center gap-4">
      <NavLink to="/discount">
        <Button variant="outline" size="icon" className="h-7 w-7">
          <ChevronLeft className="h-4 w-4" />
          <span className="sr-only">Back</span>
        </Button>
      </NavLink>
      <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
        Add Discount
      </h1>
      <div className="hidden items-center gap-2 md:ml-auto md:flex">
        <NavLink to="/discount">
          <Button variant="outline" size="sm" className="h-7 gap-1">
            Discard
          </Button>
        </NavLink>
        <Button size="sm" type="submit" className="h-7 gap-1 bg-primary">
          Create Discount
        </Button>
      </div>
    </div>
  );
}
