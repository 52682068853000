import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getMenuList() {
  return axiosInterceptor.get("/menu");
}

export function createMenu(data: any) {
  return axiosInterceptor.post("/menu", data);
}

export function menuDetail(id: string) {
  return axiosInterceptor.get(`/menu/${id}`);
}

export function updatedMenu(id: string, data: any) {
  return axiosInterceptor.put(`/menu/${id}`, data);
}

export function deleteMenu(id: string|undefined) {
  return axiosInterceptor.delete(`/menu/${id}`);
}
