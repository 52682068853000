import {
  File,
  ImageOff,
  ListFilter,
  MoreHorizontal,
  PlusCircle,
  Search,
} from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { EmptyCategory } from "./EmptyCategory";
import { deleteCategory, getCategoryList } from "@/services/categoryService";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { ScrollArea } from "@/components/ui/scroll-area";
import dateFormat from "@/utils/dateFormt";
import Pagination from "@/components/common/Pagination";
import { Input } from "@/components/ui/input";
import { inputDebounce } from "@/utils/debounce";

export function CategoryTable() {
  const [data, setData] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectedId = useRef("");

  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    init();
    return () => {
      setData([]);
    };
  }, [filter, page, searchText]);

  function init() {
    getCategoryList(filter, page, searchText)
      .then((res) => {
        setData(res.data.data);
        setMeta(res.data.meta);
      })
      .catch((err) => console.log(err));
  }

  function deleteProductHandler() {
    deleteCategory(selectedId.current)
      .then(() => {
        setConfirmationModal(false);
        toast({
          duration: 3000,
          title: "Deleted",
          description: "Category has been deleted successfully",
        });
        init();
      })
      .catch((error) => {
        console.log(error);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
      });
  }

  const debounce = inputDebounce();

  return (
    <div className="flex h-full w-full flex-col gap-2 bg-muted/40">
      {confirmationModal && (
        <ConfirmationModal
          modal={confirmationModal}
          setModal={setConfirmationModal}
          handleDelete={deleteProductHandler}
        />
      )}
      <Tabs
        value={filter}
        onValueChange={(value) => {
          setPage(1);
          setFilter(() => (value == "" ? "" : value));
        }}
      >
        <div className="flex items-center">
          <div className=" flex gap-4">
            <TabsList>
              <TabsTrigger value="">All</TabsTrigger>
              <TabsTrigger value="published">Published</TabsTrigger>
              <TabsTrigger value="draft">Draft</TabsTrigger>
              <TabsTrigger value="archived" className="hidden sm:flex">
                Archived
              </TabsTrigger>
            </TabsList>

            <div className="flex gap-2">
              <div className="relative ml-auto flex-1 md:grow-0">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search Category"
                  className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      debounce(() => {
                        setPage(1);
                        setSearchText(e.target.value);
                      });
                    } else {
                      setPage(1);
                      setSearchText("");
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="ml-auto flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-7 gap-1">
                  <ListFilter className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filter
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem checked>
                  Active
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Draft</DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Archived</DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button size="sm" variant="outline" className="h-7 gap-1">
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Export
              </span>
            </Button>
            <NavLink to="/category/add">
              <Button size="sm" className="h-7 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Create Category
                </span>
              </Button>
            </NavLink>
          </div>
        </div>
      </Tabs>
      {data.length === 0 ? (
        <EmptyCategory />
      ) : (
        <Card x-chunk="dashboard-06-chunk-0">
          <CardHeader>
            <CardTitle>Category</CardTitle>
            <CardDescription>
              Manage your category and view their sales performance.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <ScrollArea className="h-[400px]">
                <TableHeader>
                  <TableRow className="text-[12px]">
                    <TableHead>Category</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Products</TableHead>
                    <TableHead className="hidden md:table-cell">
                      Created at
                    </TableHead>
                    <TableHead>
                      <span className="sr-only">Actions</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <NavLink
                            to={`/category/edit?id=${item._id}`}
                            className="flex flex-row items-center gap-4 text-xs"
                          >
                            <div>
                              {item?.images ? (
                                <img
                                  src={item?.images[0]?.url}
                                  alt="Category"
                                  className="object-cover rounded-md aspect-square bg-gray-200 w-[40px]"
                                  width="64"
                                  height="64"
                                />
                              ) : (
                                <div className="bg-slate-50 aspect-square w-[40px] border-[1px] border-gray-200 rounded-md flex justify-center flex-col items-center text-center text-slate-300">
                                  <ImageOff />
                                </div>
                              )}
                            </div>
                            {item?.name}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <Badge
                            className={`capitalize border-none shadow-none font-normal ${
                              item?.status === "active"
                                ? "bg-primary"
                                : item?.status === "draft"
                                ? "bg-yellow-400 text-primary"
                                : "bg-red-600"
                            }`}
                          >
                            {item?.status}
                          </Badge>
                        </TableCell>
                        <TableCell>{item?.product_count}</TableCell>
                        <TableCell className="hidden md:table-cell">
                          {dateFormat(item?.createdAt)}
                        </TableCell>
                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                aria-haspopup="true"
                                size="icon"
                                variant="ghost"
                              >
                                <MoreHorizontal className="h-4 w-4" />
                                <span className="sr-only">Toggle menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <NavLink to={`/category/edit?id=${item._id}`}>
                                <DropdownMenuItem>Edit</DropdownMenuItem>
                              </NavLink>
                              <DropdownMenuItem className=" p-0">
                                <button
                                  className=" w-full text-left p-2"
                                  onClick={() => {
                                    selectedId.current = item?._id;
                                    setConfirmationModal(true);
                                  }}
                                >
                                  Delete
                                </button>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </ScrollArea>
            </Table>
          </CardContent>

          <Pagination
            type="category"
            page={page}
            data={meta}
            setPage={setPage}
            nextPage={data.length < 10}
          />
        </Card>
      )}
    </div>
  );
}
