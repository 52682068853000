import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Content() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/pages");

    }, [])
    
  return (
    <></>
  )
}

export default Content