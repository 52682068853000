import { MoreHorizontal, PlusCircle } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { Tabs } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import dateFormat from "@/utils/dateFormt";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import { EmptyWarehouse } from "./EmptyWarehouse";
import { deleteWarehouse, getWarehouses } from "@/services/warehouseServices";

export function WarehouseTable() {
  const [data, setData] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectedId = useRef("");

  useEffect(() => {
    init();
    return () => {
      setData([]);
    };
  }, []);

  function init() {
    getWarehouses()
      .then((res) => {
        setData(res?.data?.warehouse);
      })
      .catch((err) => console.log(err));
  }

  function deleteProductHandler() {
    deleteWarehouse(selectedId.current)
      .then(() => {
        setConfirmationModal(false);
        toast({
          duration: 3000,
          title: "Deleted",
          description: "Warehouse has been deleted successfully",
        });
        init();
      })
      .catch((error) => {
        console.log(error);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
      });
  }

  return (
    <div className="flex h-full w-full flex-col gap-2 bg-muted/40">
      {confirmationModal && (
        <ConfirmationModal
          modal={confirmationModal}
          setModal={setConfirmationModal}
          handleDelete={deleteProductHandler}
        />
      )}
      <Tabs>
        <div className="flex items-center">
          <div className="ml-auto flex items-center gap-2">
            <NavLink to="/settings/warehouse/add">
              <Button size="sm" className="h-7 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Create Warehouse
                </span>
              </Button>
            </NavLink>
          </div>
        </div>
      </Tabs>
      {data?.length === 0 ? (
        <EmptyWarehouse />
      ) : (
        <Card x-chunk="dashboard-06-chunk-0">
          <CardHeader>
            <CardTitle>Warehouses</CardTitle>
            <CardDescription>Manage your warehouse here.</CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <ScrollArea className="h-[400px]">
                <TableHeader>
                  <TableRow className="text-[12px]">
                    <TableHead>Name</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>City</TableHead>
                    <TableHead className="hidden md:table-cell">
                      Created at
                    </TableHead>
                    <TableHead>
                      <span className="sr-only">Actions</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <NavLink
                            to={`/settings/warehouse/edit?id=${item._id}`}
                            className="flex flex-row items-center gap-4 text-xs"
                          >
                            {item?.name}{" "}
                            {item?.is_default && (
                              <Badge className="bg-yellow-100 shadow-none text-yellow-700 hover:bg-yellow-100">
                                Primary
                              </Badge>
                            )}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <Badge
                            variant="outline"
                            className={`capitalize border-none shadow-none font-normal ${
                              item?.is_active
                                ? "bg-[#8D9967] text-white"
                                : "bg-red-300"
                            }`}
                          >
                            {item?.is_active ? "Active" : "Inactive"}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <Badge
                            variant="outline"
                            className={`capitalize border-none shadow-none font-normal `}
                          >
                            {item?.address?.city}
                          </Badge>
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {dateFormat(item?.createdAt)}
                        </TableCell>
                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                aria-haspopup="true"
                                size="icon"
                                variant="ghost"
                              >
                                <MoreHorizontal className="h-4 w-4" />
                                <span className="sr-only">Toggle menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <NavLink
                                to={`/settings/warehouse/edit?id=${item._id}`}
                              >
                                <DropdownMenuItem>Edit</DropdownMenuItem>
                              </NavLink>
                              <DropdownMenuItem className=" p-0">
                                <button
                                  className=" w-full text-left p-2"
                                  onClick={() => {
                                    selectedId.current = item?._id;
                                    setConfirmationModal(true);
                                  }}
                                >
                                  Delete
                                </button>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </ScrollArea>
            </Table>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
