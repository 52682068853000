import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Controller, useForm } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { getStoreDetails, updateStoreDetails } from "@/services/storeServices";
import { useEffect } from "react";

function ShippingSetting() {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    // formState: { errors },
  } = useForm<any>({
    defaultValues: {
      radio_purchase_amount: "1",
      radio_shipping: "1",
    },
  });

  const watch_radio_purchase_amount = watch("radio_purchase_amount");
  const watch_radio_shipping = watch("radio_shipping");

  useEffect(() => {
    getStoreDetails()
      .then((res) => {
        const prefill = res?.data?.data?.shipping;

        if (prefill) {
          parseInt(prefill["cart_value"]) &&
            setValue("radio_purchase_amount", "2");
          parseInt(prefill["min_shipping"]) && setValue("radio_shipping", "2");

          setValue("cart_value", prefill.cart_value);
          setValue("min_shipping", prefill.min_shipping);
          setValue("shipping_charges", prefill.shipping_charges);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function onSubmit(values: any) {
    updateStoreDetails({
      shipping: {
        ...values,
      },
    })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className=" p-4">
        <CardContent className="grid gap-6">
          <div className="grid gap-3">
            <Label htmlFor="shipping_charges">Shipping Charges</Label>
            <Input
              id="shipping_charges"
              type="text"
              required
              className="w-full"
              placeholder="Enter Shipping Cost"
              {...register("shipping_charges")}
            />
          </div>

          <div className="grid gap-3">
            <Label htmlFor="name">Minimum Purchase Amount</Label>
            <Controller
              name="radio_purchase_amount"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  // defaultValue="No minimum purchase"
                  onValueChange={(e) => {
                    if (e == "1") {
                      setValue("cart_value", 0);
                    }

                    field.onChange(e);
                  }}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="1" id="r_1" />
                    <Label htmlFor="r_1" className="font-normal">
                      No minimum purchase
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="2" id="r_2" />
                    <Label htmlFor="r_2" className="font-normal">
                      Set minimum purchase
                    </Label>
                  </div>
                </RadioGroup>
              )}
            />
            {watch_radio_purchase_amount === "2" && (
              <Input
                id="cart_value"
                type="text"
                className="w-full"
                placeholder="Enter Minimum Cart Amount"
                {...register("cart_value")}
              />
            )}
          </div>

          <div className="grid gap-3">
            <Label htmlFor="limit">Free Shipping</Label>

            <Controller
              name="radio_shipping"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  onValueChange={(e) => {
                    if (e == "1") {
                      setValue("min_shipping", 0);
                    }

                    field.onChange(e);
                  }}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="1" id="r1" />
                    <Label htmlFor="r1" className="font-normal">
                      No Free Shipping
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="2" id="r2" />
                    <Label htmlFor="r2" className="font-normal">
                      Set Free Shipping
                    </Label>
                  </div>
                </RadioGroup>
              )}
            />

            {watch_radio_shipping == "2" && (
              <Input
                id="min_shipping"
                type="text"
                className="w-full"
                placeholder="Enter Cart Amount"
                {...register("min_shipping")}
              />
            )}
          </div>

          <Button type="submit">Update</Button>
        </CardContent>
      </Card>
    </form>
  );
}

export default ShippingSetting;
