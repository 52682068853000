import { z } from "zod";

export const loginFormSchema = z.object({
  email: z.string().min(5, "Invalid email address").max(30).email("Invalid email address"),
  password: z
    .string()
    .min(6, "Password must contain atleast 6 characters")
    .max(32, "Password is too looooooooooooong")
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]+$/, "Wrong password format")
    .trim(),
});
