import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function addUser(data: object) {
  return axiosInterceptor.post("/store-permission/add", data);
}

export function getUserList() {
  return axiosInterceptor.get("/users");
}

export function getUserDetails(id: string) {
  return axiosInterceptor.get(`/user/${id}`);
}

// export function updateUserDetails(id: string | null, data: object) {
//   return axiosInterceptor.put(`/user/${id}`, data);
// }

export function removeUser(id: string | null) {
  return axiosInterceptor.delete(`/user/${id}`);
}
