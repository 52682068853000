// import RecentSales from "@/modules/Analytics/components/RecentSales";
// import { Overview } from "@/modules/Analytics/components/Overview";
import Metrics from "../components/Metrics";
import { useEffect, useState } from "react";
import { getMetrics } from "@/services/metricService";


export function Analytics() {

  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    getMetrics('month')
      .then((res) => {
        setMetrics(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="flex flex-col gap-6">
      {metrics && <Metrics metrics={metrics} duration={'month'} />}
      {/* <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-2">
        <Overview />
        <RecentSales />
      </div> */}
    </div>
  );
}