import { Button } from "@/components/ui/button"
import { NavLink } from "react-router-dom"

export function EmptyMedia() {
  return (
    <div className="grid h-[86vh] w-full">
      <div className="flex flex-col">
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
          <div className="flex items-center">
            <h1 className="text-lg font-semibold md:text-2xl">Media Files</h1>
          </div>
          <div
            className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm"
          >
            <div className="flex flex-col items-center gap-1 text-center">
              <h3 className="text-2xl font-bold tracking-tight">
                You have no media files
              </h3>
              <p className="text-sm text-muted-foreground">
                You can add media files by clicking below.
              </p>
              <NavLink to="#">
              <Button className="mt-4" size="sm">Upload Media</Button>
              </NavLink>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
