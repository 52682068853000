import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { createStoreFormSchema } from "@/modules/Store/schemas/createStore";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { createStore, verifyStoreDomainUnique } from "@/services/storeServices";
import { useEffect, useState } from "react";
import { verifyUser } from "@/services/authService";
import { X, Check, Loader2 } from "lucide-react";

const CreateStore = () => {
  const [searchparams] = useSearchParams();
  const [isDomainAvailable, setIsDomainAvailable] = useState({
    status: false,
    type: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const secret = searchparams.get("secret");
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof createStoreFormSchema>>({
    resolver: zodResolver(createStoreFormSchema),
    defaultValues: {
      store_name: "",
      store_type: undefined,
      store_description: "",
      domain: "",
      contact_information: {
        email_address: "",
        contact_number: "",
      },
      store_address: {
        address_one: "",
        address_two: "",
        country: "india",
        city: "",
        state: "",
        pin_code: "",
      },
    },
  });

  async function submitHandler(
    formData: z.infer<typeof createStoreFormSchema>
  ) {
    setIsLoading(true);

    createStore(formData)
      .then(async () => {
        navigate("/select-store");
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }

  const store_type = [
    {
      name: "Books",
      value: "books",
    },
    {
      name: "Home Decor",
      value: "home-decor",
    },
    {
      name: "Furniture",
      value: "furniture",
    },
    {
      name: "Electronics",
      value: "electronics",
    },
    {
      name: "Beauty",
      value: "beauty",
    },
    {
      name: "Medical",
      value: "medical",
    },
    {
      name: "Sports",
      value: "sports",
    },
    {
      name: "Services",
      value: "service",
    },
    {
      name: "Cafe",
      value: "cafe",
    },
    {
      name: "Restaurant",
      value: "restaurant",
    },
    {
      name: "Dairy",
      value: "dairy",
    },
    {
      name: "Grocery",
      value: "grocery",
    },
    {
      name: "Others",
      value: "other",
    },
  ];

  useEffect(() => {
    if (secret) {
      verifyUser(secret)
        .then((res) => {
          localStorage.setItem("token", res?.data?.token);
        })
        .catch((error) => {
          console.log(error);
          localStorage.clear();
          navigate("/login");
        });
    }
  }, []);

  function isDomainUnique(e: any) {
    if (e?.target?.value) {
      verifyStoreDomainUnique(e?.target?.value)
        .then((res) => {
          console.log(res);
          setIsDomainAvailable({ status: true, type: "checked" });
        })
        .catch((err) => {
          console.log(err);
          setIsDomainAvailable({ status: false, type: "checked" });
        });
    } else {
      setIsDomainAvailable({
        status: false,
        type: "",
      });
    }
  }

  return (
    <div className="h-full container flex flex-col gap-3 items-center overflow-auto py-4">
      <div className="flex gap-2 flex-col items-center">
        <CardHeader className="flex flex-col items-center max-w-[700px] text-center">
          <CardTitle className="text-3xl text-[#4D5F47]">
            Create Your Store Now
          </CardTitle>
          <CardDescription>
            Sell online in minutes! Build your store, manage listings, and track
            sales - all in one easy dashboard.
          </CardDescription>
        </CardHeader>
      </div>
      <Card className="mx-auto max-w-[500px] w-full">
        <CardContent className="mt-10 ">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submitHandler)}>
              <div className="grid gap-4">
                <div className="grid grid-cols-1 gap-4">
                  <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
                    <div className="grid gap-2">
                      <FormField
                        control={form.control}
                        name="store_name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Store Name</FormLabel>
                            <FormControl>
                              <Input placeholder="ABC Store" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="grid gap-2">
                      <FormField
                        control={form.control}
                        name="store_type"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Store Type</FormLabel>
                            <FormControl>
                              <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                              >
                                <SelectTrigger
                                  id="model"
                                  className="items-start [&_[data-description]]:hidden"
                                >
                                  <SelectValue
                                    placeholder="Select Type"
                                    defaultValue=""
                                  />
                                </SelectTrigger>
                                <SelectContent>
                                  {store_type.map((item, index) => {
                                    return (
                                      <SelectItem
                                        value={item.value}
                                        key={index}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    );
                                  })}
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="store_description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Description{" "}
                            <span className="text-slate-500 font-normal">
                              (Optional)
                            </span>
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder="Write a small introduction about your store."
                              className="max-h-[100px]"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-2 ">
                    <Label>Create Domain</Label>
                    <FormField
                      control={form.control}
                      name="domain"
                      render={({ field }) => (
                        <FormItem className="flex items-center gap-1">
                          <FormControl>
                            <Input
                              placeholder="yourstore"
                              {...field}
                              className="max-w-[150px]"
                              onBlur={(e) => isDomainUnique(e)}
                            />
                          </FormControl>
                          <Label className="text-md h-full m-0 p-0 font-normal flex items-center gap-2">
                            .pinecart.com{" "}
                            {isDomainAvailable?.type == "checked" && (
                              <>
                                {isDomainAvailable?.status ? (
                                  <Check
                                    size={20}
                                    className="bg-green-500 text-white rounded-full p-1"
                                  />
                                ) : (
                                  <X
                                    size={20}
                                    className="bg-red-500 text-white rounded-full p-1"
                                  />
                                )}
                              </>
                            )}
                          </Label>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="contact_information.email_address"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Store email</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="store@business.com"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="store-contact">Phone number</Label>
                    <FormField
                      control={form.control}
                      name="contact_information.contact_number"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <div className="w-full flex gap-1">
                            <div className="h-9 rounded-md px-2 flex items-center text-sm font-semibold text-slate-400 border">
                              +91
                            </div>
                            <FormControl>
                              <Input
                                type="tel"
                                placeholder="9876543210"
                                className="!mt-0"
                                {...field}
                              />
                            </FormControl>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="flex flex-col gap-2 ">
                    <Label>Address</Label>
                    <div className="flex flex-col gap-2">
                      <div className="grid gap-2">
                        <FormField
                          control={form.control}
                          name="store_address.address_one"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Apartment or Building"
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="grid gap-2">
                        <FormField
                          control={form.control}
                          name="store_address.address_two"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Street address or P.O Box"
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="grid gap-2 grid-cols-1 md:grid-cols-3">
                        <div>
                          <FormField
                            control={form.control}
                            name="store_address.city"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="text-slate-400  font-normal">
                                  city
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="City"
                                    className="!mt-1"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                        <div>
                          <FormField
                            control={form.control}
                            name="store_address.state"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="text-slate-400 font-normal">
                                  State
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="State"
                                    className="!mt-1"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                        <div>
                          <FormField
                            control={form.control}
                            name="store_address.pin_code"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="text-slate-400 font-normal">
                                  ZIP Code
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="201301"
                                    className="!mt-1"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button type="submit" className="w-full">
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Please wait
                    </>
                  ) : (
                    "Create store +"
                  )}
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateStore;
