import {
  File,
  ListFilter,
  MoreHorizontal,
  PlusCircle,
  Search,
} from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { EmptyProduct } from "./EmptyProduct";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import {
  deleteProduct,
  exportProductCSV,
  getProductList,
} from "@/services/productService";
import { ImageOff } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import dateFormat from "@/utils/dateFormt";
import Pagination from "@/components/common/Pagination";
import { Input } from "@/components/ui/input";
import { inputDebounce } from "@/utils/debounce";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import ProductImport from "./ProductImport";

type res = {
  images: any[];
  name: string;
  status: string;
  category_id: { name: string }[];
  createdAt: string;
  price: string;
  _id: string;
};

export function ProductTable() {
  const [data, setData] = useState<res[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [meta, setMeta] = useState({});

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectedId = useRef("");

  const [page, setPage] = useState(1);

  useEffect(() => {
    init();
    return () => {
      setData([]);
    };
  }, [filter, page, searchText]);

  function init() {
    getProductList(filter, page, searchText)
      .then((res) => {
        setData(res.data.data);
        setMeta(res.data.meta);
      })
      .catch((error) => console.log(error));
  }

  function deleteProductHandler() {
    deleteProduct(selectedId.current)
      .then(() => {
        setConfirmationModal(false);
        toast({
          duration: 3000,
          title: "Deleted",
          description: "Product has been deleted successfully",
        });
        init();
      })
      .catch((error) => 
      {
        console.log(error);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
      });
  }

  const debounce = inputDebounce();

  function downloadCSV() {
    
    exportProductCSV()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
        const a = document.createElement("a");
        a.href = url;
        a.download = "data.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="flex h-full w-full gap-2 flex-col bg-muted/40">
      {confirmationModal && (
        <ConfirmationModal
          modal={confirmationModal}
          setModal={setConfirmationModal}
          handleDelete={deleteProductHandler}
        />
      )}
      <Tabs
        value={filter}
        onValueChange={(value) => {
          setPage(1);
          setFilter(() => (value == "" ? "" : value));
        }}
      >
        <div className="flex items-center">
          <div className=" md:flex gap-4 hidden">
            <TabsList>
              <TabsTrigger value="">All</TabsTrigger>
              <TabsTrigger value="active">Active</TabsTrigger>
              <TabsTrigger value="draft">Draft</TabsTrigger>
              <TabsTrigger value="archived" className="hidden sm:flex">
                Archived
              </TabsTrigger>
            </TabsList>

            <div className="flex gap-2">
              <div className="relative ml-auto flex-1 md:grow-0">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search Products"
                  className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      debounce(() => {
                        setPage(1);
                        setSearchText(e.target.value);
                      });
                    } else {
                      setPage(1);
                      setSearchText("");
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="ml-auto flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-7 gap-1">
                  <ListFilter className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filter
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem checked>
                  Active
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Draft</DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Archived</DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
              size="sm"
              variant="outline"
              className="h-7 gap-1"
              type="button"
              onClick={downloadCSV}
            >
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Export
              </span>
            </Button>

            <ProductImport />

            <NavLink to="/products/add">
              <Button size="sm" className="h-7 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Add Product
                </span>
              </Button>
            </NavLink>
          </div>
        </div>
      </Tabs>

      {data?.length === 0 ? (
        <EmptyProduct />
      ) : (
        <Card x-chunk="dashboard-06-chunk-0">
          <CardHeader>
            <CardTitle>Products</CardTitle>
            <CardDescription>
              Manage your products and view their sales performance.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <ScrollArea className="h-[400px]">
                <TableHeader>
                  <TableRow className="text-[12px]">
                    <TableHead>Product</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Price</TableHead>
                    <TableHead className="hidden md:table-cell">
                      Category
                    </TableHead>
                    <TableHead className="hidden md:table-cell">
                      Created at
                    </TableHead>
                    <TableHead>
                      <span className="sr-only">Actions</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.map((item, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="font-regular">
                          <NavLink
                            to={`/products/edit?id=${item._id}`}
                            className="flex gap-4 items-center text-xs"
                          >
                            <div className="hidden sm:table-cell">
                              {item?.images?.length ? (
                                <img
                                  src={item?.images[0].url}
                                  alt="Product image"
                                  className="object-cover w-[40px] rounded-md aspect-square"
                                  width="64"
                                  height="64"
                                />
                              ) : (
                                <div className="bg-slate-50 aspect-square w-[40px] border-[1px] border-gray-200 rounded-md flex justify-center flex-col items-center text-center text-slate-300">
                                  <ImageOff />
                                </div>
                              )}
                            </div>
                            {item?.name}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <Badge
                            className={`shadow-none capitalize font-normal border-none ${
                              item?.status === "active"
                                ? "bg-primary"
                                : item?.status === "draft"
                                ? "bg-yellow-400 text-primary"
                                : "bg-red-600"
                            }`}
                          >
                            {item?.status}
                          </Badge>
                        </TableCell>
                        <TableCell className="text-xs">
                          ₹{item?.price}
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {item?.category_id?.length > 0 ? (
                            item?.category_id?.length > 1 ? (
                              <div className="flex gap-1 items-center">
                                <Badge
                                  variant="outline"
                                  className="font-normal"
                                >
                                  {item?.category_id[0]?.name}
                                </Badge>
                                +{/* here */}
                                <HoverCard>
                                  <HoverCardTrigger
                                    className="py-0 px-0"
                                    asChild
                                  >
                                    <Button
                                      variant="link"
                                      className="text-xs underline decoration-dotted decoration-gray-400 decoration-1"
                                    >
                                      {item?.category_id?.length - 1} more
                                    </Button>
                                  </HoverCardTrigger>
                                  <HoverCardContent className="w-auto">
                                    <div className="flex flex-col justify-between gap-4">
                                      {item?.category_id
                                        ?.filter((_, index) => index > 0)
                                        ?.map((i: any, index: any) => {
                                          return (
                                            <div key={index}>
                                              <h4 className="text-sm font-semibold">
                                                {i?.name}
                                              </h4>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </HoverCardContent>
                                </HoverCard>
                                {/* to here */}
                              </div>
                            ) : (
                              <Badge variant="outline" className="font-normal">
                                {item?.category_id[0]?.name}
                              </Badge>
                            )
                          ) : (
                            <Badge
                              variant="outline"
                              className="font-normal bg-slate-50 border-none"
                            >
                              uncategorised
                            </Badge>
                          )}
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          <p className="text-xs">
                            {dateFormat(item?.createdAt)}
                          </p>
                        </TableCell>
                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                aria-haspopup="true"
                                size="icon"
                                variant="ghost"
                              >
                                <MoreHorizontal className="h-4 w-4" />
                                <span className="sr-only">Toggle menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <NavLink to={`/products/edit?id=${item._id}`}>
                                <DropdownMenuItem>Edit</DropdownMenuItem>
                              </NavLink>

                              <DropdownMenuItem className=" p-0">
                                <button
                                  className="w-full text-left p-2"
                                  onClick={() => {
                                    selectedId.current = item?._id;
                                    setConfirmationModal(true);
                                  }}
                                >
                                  Delete
                                </button>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </ScrollArea>
            </Table>
          </CardContent>
          <Pagination
            type="products"
            page={page}
            data={meta}
            setPage={setPage}
            nextPage={data.length < 10}
          />
        </Card>
      )}
    </div>
  );
}
