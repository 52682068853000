import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { DateRange } from "react-day-picker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import CreateDiscountHeader from "../components/CreateDiscountHeader";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { addDays, format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  createDiscount,
  updateDiscount,
  getDiscountDetail,
} from "@/services/discountService";

export function DiscountForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const discount_id = searchParams.get("id");

  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(),
    to: addDays(new Date(), 20),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    // formState: { errors },
  } = useForm<any>({
    defaultValues: {
      status: "draft",
      type: "percentage",
      mpr: "nmp",
      "limit-option": "no-limit",
    },
  });

  const watchType = watch("type");
  const watchMpr = watch("mpr");
  const watchLimit = watch("limit-option");

  useEffect(() => {
    if (discount_id) {
      getDiscountDetail(discount_id)
        .then((res) => {
          const response = res.data;

          response.data["minimum_amount"] && setValue("mpr", "smp");
          response.data["limit"] && setValue("limit-option", "set-limit");

          for (let key in response.data) {
            // @ts-ignore
            setValue(key, response.data[key]);
          }

          setDate(response.data.date);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const onSubmit: SubmitHandler<any> = (data) => {
    const payload = {
      ...data,
      date,
    };
    
    if (discount_id) {
      updateDiscount(payload, discount_id)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => console.log(error));
    } else {
      createDiscount(payload)
        .then(() => {
          navigate(-1);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <form
      className="grid flex-1 items-start gap-4 md:gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid flex-1 auto-rows-max gap-4">
        <CreateDiscountHeader />
        <Card>
          <CardHeader>
            <CardTitle>Create a discount coupon</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6">
              <div className="grid gap-3">
                <Label htmlFor="title">Discount Title</Label>
                <Input
                  id="title"
                  type="text"
                  required
                  className="w-full"
                  placeholder="Enter discount title"
                  {...register("title")}
                />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="code">Discount Code</Label>
                <Input
                  id="code"
                  type="text"
                  required
                  className="w-full"
                  maxLength={14}
                  placeholder="Enter discount code"
                  {...register("code")}
                />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="type">Discount Value</Label>
                <div className="flex flex-col md:flex-row gap-3">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger id="type" aria-label="Select an option">
                          <SelectValue placeholder="Select an option" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="percentage">Percentage</SelectItem>
                          <SelectItem value="fixed">Fixed Amount</SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  />
                  <div className="w-full relative">
                    <Input
                      id="value"
                      type="number"
                      required
                      placeholder="Enter discount value"
                      {...register("value", {
                        onBlur(event) {
                          setValue(
                            "slug",
                            event?.target?.value
                              .toLocaleLowerCase()
                              .replace(/[^A-Z0-9]/gi, "-")
                          );
                        },
                      })}
                    />
                    <p className="absolute top-[7px] right-3 text-sm text-gray-500">
                      {watchType == "percentage" ? "%" : "₹"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid gap-3">
                <Label htmlFor="name">Minimum Purchase Requirements</Label>
                <Controller
                  name="mpr"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      // defaultValue="No minimum purchase"
                      onValueChange={(e) => {
                        if (watchMpr === "smp") {
                          setValue("minimum_amount", 0);
                        }

                        field.onChange(e);
                      }}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="nmp" id="r1" />
                        <Label htmlFor="r1" className="font-normal">
                          No minimum purchase
                        </Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="smp" id="r2" />
                        <Label htmlFor="r2" className="font-normal">
                          Set minimum purchase
                        </Label>
                      </div>
                    </RadioGroup>
                  )}
                />
                {watchMpr === "smp" && (
                  <Input
                    id="minimum_amount"
                    type="text"
                    className="w-full"
                    placeholder="Enter minimum purchase amount"
                    {...register("minimum_amount")}
                  />
                )}
              </div>
              <div className="grid gap-3">
                <Label htmlFor="limit">Limit Usage</Label>

                <Controller
                  name="limit-option"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      onValueChange={(e) => {
                        if (watchLimit == "set-limit") {
                          setValue("limit", 0);
                        }

                        field.onChange(e);
                      }}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="no-limit" id="r1" />
                        <Label htmlFor="r1" className="font-normal">
                          No limit
                        </Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="set-limit" id="r2" />
                        <Label htmlFor="r2" className="font-normal">
                          Set limit
                        </Label>
                      </div>
                    </RadioGroup>
                  )}
                />

                {watchLimit == "set-limit" && (
                  <Input
                    id="limit"
                    type="text"
                    className="w-full"
                    placeholder="Enter limit count"
                    {...register("limit")}
                  />
                )}
              </div>
              <div className="grid gap-3">
                <Label htmlFor="date">Discount Duration (Optional)</Label>
                <Controller
                  control={control}
                  name="date"
                  render={() => (
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          id="date"
                          variant={"outline"}
                          className={cn(
                            "w-[300px] justify-start text-left font-normal",
                            !date && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {date?.from ? (
                            date.to ? (
                              <>
                                {format(date.from, "LLL dd, y")} -{" "}
                                {format(date.to, "LLL dd, y")}
                              </>
                            ) : (
                              format(date.from, "LLL dd, y")
                            )
                          ) : (
                            <span className="text-gray-500">
                              Pick start and end date
                            </span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          initialFocus
                          mode="range"
                          defaultMonth={date?.from}
                          selected={date}
                          onSelect={setDate}
                          numberOfMonths={1}
                        />
                      </PopoverContent>
                    </Popover>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="status">Status</Label>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                      defaultValue="draft"
                    >
                      <SelectTrigger id="status" aria-label="Select status">
                        <SelectValue placeholder="Select status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="active">Active</SelectItem>
                        <SelectItem value="draft">Draft</SelectItem>
                        <SelectItem value="scheduled">Scheduled</SelectItem>
                        <SelectItem value="expired">Expired</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </form>
  );
}
