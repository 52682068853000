const ProfileAvatar = () => {
  return (
    <img
      src="/assets/images/avatar.png"
      alt="user profile"
      className="object-cover w-20 h-20"
    />
  );
};

export default ProfileAvatar;
