import { FileStack, FileVideo } from "lucide-react";

import { Card, CardContent } from "../ui/card";
import FileUploadBox from "./FileUploadBox";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getMedia } from "@/services/mediaServices";

type props = {
  model: boolean;
  onChangeModel: React.Dispatch<React.SetStateAction<boolean>>;
  inputType: string;
  maxImage: number;
  setSelected: React.Dispatch<React.SetStateAction<file[]>>;
  selected: file[];
};

type file = {
  _id: string;
  store_id: string;
  title: string;
  url: string;
  type: string;
};

const MediaBoxUpload = ({
  onChangeModel,
  model,
  inputType,
  setSelected,
  selected,
  maxImage,
}: props) => {
  const [assets, setAssets] = useState<file[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<file[]>([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<{ total: number; page: number }>();

  useEffect(() => {
    getAllAssets();
    if (selected.length) {
      setSelectedAssets(selected);
    }
  }, [page]);

  function getAllAssets() {
    getMedia("", page)
      .then((res) => {
        if (res?.data?.data) {
          setMeta(res.data.meta);
          setAssets((prev) => [...prev, ...res.data.data]);
        }
      })
      .catch((err) => console.log(err));
  }

  function handleImageSelection(file: any) {
    if (!file) {
      return false;
    }

    const index = selectedAssets.findIndex((item) => item._id == file._id);

    if (index == -1) {
      selectedAssets.length < maxImage &&
        setSelectedAssets((prev) => [...prev, file]);
    } else {
      setSelectedAssets((prev) => {
        const temp = [...prev];
        temp.splice(index, 1);
        return temp;
      });
    }
  }

  function onSubmit() {
    setSelected(structuredClone(selectedAssets));
    onChangeModel(false);
  }

  function handleNewImageUploaded(medias: any) {
    setAssets((prev) => [...medias.data, ...prev]);
  }

  return (
    <Dialog open={model} onOpenChange={onChangeModel}>
      <DialogContent>
        <DialogTitle>Select Images</DialogTitle>

        <Card className="rounded-md shadow-none bg-slate-100 flex flex-col justify-center items-center">
          <FileUploadBox
            inputType={inputType}
            successFunction={handleNewImageUploaded}
          />
        </Card>
        <DialogHeader>
          <div>
            <div className="flex flex-col gap-4">
              <div className="grid gap-2">
                <DialogTitle>Media Library</DialogTitle>
              </div>

              <div className="flex flex-col gap-4">
                <Card className="rounded-none shadow-none border-none flex flex-col gap-2">
                  {!!assets?.length ? (
                    <div className=" flex justify-center items-center flex-col gap-2 ">
                      <CardContent
                        className={`gap-2 shadow-none grid  ${
                          !!assets?.length ? "grid-cols-4" : "grid-cols-1"
                        } max-h-[300px] overflow-auto p-1 border rounded-md  `}
                      >
                        {assets?.map((item, index) => (
                          <div
                            key={index}
                            className={`aspect-square overflow-hidden p-[3px] 
                            ${
                              selectedAssets.some((el) => el._id == item._id) &&
                              "border-[#697A51] border-2 rounded-lg"
                            }
                          `}
                            onClick={() => handleImageSelection(item)}
                          >
                            {item.type == "image" ? (
                              <img
                                src={item.url}
                                alt="Media asset image"
                                className="object-cover w-full h-full rounded-md"
                                width={100}
                                height={100}
                              />
                            ) : item.type == "video" ? (
                              <div className="border flex justify-center items-center aspect-square rounded-md">
                                <FileVideo className="opacity-40" size={40} />
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </CardContent>

                      {!Boolean(assets.length >= (meta?.total ?? 0)) && (
                        <Button onClick={() => setPage((prev) => prev + 1)} size="sm" className="bg-primary">
                          Load more
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="w-full rounded-none flex flex-col gap-2 justify-center items-center p-10 border-none shadow-none text-lg text-slate-400">
                      <FileStack size={30} />
                      <span className="text-sm">
                        No files to show at the moment.
                      </span>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </DialogHeader>

        <DialogFooter>
          <Button
            type="button"
            size="sm"
            className="bg-primary"
            onClick={onSubmit}
          >
            Update & Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MediaBoxUpload;
