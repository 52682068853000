// // ? create folder
// export function createMediaFolder(
//   store_name: string | null,
//   folderName: string
// ) {
//   return axiosFileUploadInterceptor.post(
//     `/folder/${container_name}/${store_name}`,
//     "",
//     {
//       params: {
//         folder: folderName,
//       },
//     }
//   );
// }

// // ? get images
// export function getFolderData(store_name: string | null, folder?: string) {
//   return axiosFileUploadInterceptor.get(
//     `/folder/${container_name}/${store_name}`,
//     {
//       params: {
//         folder,
//       },
//     }
//   );
// }

// // ? upload images
// export function uploadFiles(folder: string, files: any) {
//   const formData = new FormData();

//   for (let key in files) {
//     formData.append("file", files[key]);
//   }

//   const store_id = localStorage.getItem("storeId");

//   return axiosFileUploadInterceptor.post("/", formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     params: {
//       containerName: container_name,
//       foldername: store_id,
//       folder: folder,
//     },
//   });
// }

import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getMedia(type: string, page: number) {
  return axiosInterceptor.get(`/media?type=${type}&page=${page}`);
}

export function updateAltTag(id: string|undefined, alt: string) {
  return axiosInterceptor.put(`/media/${id}`, {
    alt,
  });
}

export function uploadFiles(files: FileList) {
  const formData = new FormData();

  for (let file of files) {
    formData.append("file", file);
  }

  return axiosInterceptor.post("/media", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
