import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getCustomerList() {
  return axiosInterceptor.get("/customer");
}

export function getCustomerDetails(id: string | undefined) {
  return axiosInterceptor.get(`/customer/${id}`);
}

export function createCustomer(payload: any) {
  return axiosInterceptor.post(`/offline-customer`, payload);
}

// export function updateCustomer(payload: any, id: any) {
//   return axiosInterceptor.put(`/offline-customer/${id}`, payload);
// }

// export function deleteCustomer(id: any) {
//   return axiosInterceptor.delete(`/offline-customer/${id}`);
// }
