import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { Textarea } from "@/components/ui/textarea";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  createProduct,
  editProduct,
  getProductDetails,
} from "@/services/productService";
import { getCategoryOption } from "@/services/categoryService";
import { Switch } from "@/components/ui/switch";
import Tags from "@/components/Tags";
import { getTags } from "@/services/tagServices";
import ProductVariants from "@/components/product/variants/ProductVariants";
import MetaForm from "@/components/common/MetaForm";
import ReactSelect from "react-select";
import {
  variantInterface,
  variantOption,
} from "@/components/product/variants/interface";
import ProductFeatures from "../components/ProductFeatures";
import { ChevronLeft, X } from "lucide-react";
import UploadFile from "@/components/media/UploadFile";
import { Badge } from "@/components/ui/badge";

interface Features {
  title: string | null;
  description: string | null;
}

type OptionType = {
  value: string;
  label: string;
};

interface categoryType {
  label: string;
  value: string;
}

interface file {
  _id: string;
  store_id: string;
  title: string;
  url: string;
  type: string;
}

const ProductDetails = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const product_id = params.get("id");

  const [categoryFormData, setCategoryFormData] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<OptionType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<categoryType[]>([]);
  const [productImages, setProductImages] = useState<file[]>([]);
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
  const [tags, setTags] = useState<OptionType[]>([]);
  const [variants, setVariants] = useState<variantInterface[]>([]);
  const [options, setOptions] = useState<variantOption[]>([]);
  const [features, setFeatures] = useState<Features[]>([]);

  const { control, register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      status: "draft",
      name: "",
      slug: "",
      description: "",
      price: 0,
      discount_price: 0,
      is_featured: false,
      hsn: "",
      inventory: 0,
      weight: 0,
    },
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (categoryFormData.length) {
      const selectedOption = categoryList.filter(({ value }) =>
        categoryFormData.includes(value)
      );
      setSelectedCategory(selectedOption);
    }
  }, [categoryFormData]);

  async function init() {
    try {
      const categories = await getCategoryOption();
      const option = categories.data.data.map((el: any) => ({
        value: el._id,
        label: el.name,
      }));
      setCategoryList(option);

      const tagOption = await getTags();
      setTags(
        tagOption.data.map((item: any) => ({
          value: item?._id,
          label: item?.name,
        }))
      );

      if (product_id) {
        productDetails(tagOption.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function productDetails(tagsList: any) {
    getProductDetails(product_id)
      .then((res) => {
        const response = res?.data?.data;
        for (const key in response) {
          // @ts-ignore
          setValue(key, response[key]);
        }
        setProductImages(response?.images);

        const tags = response.tags.map((id: string) => {
          const tag = tagsList.find((item: any) => item._id == id);
          return {
            value: tag?._id,
            label: tag?.name,
          };
        });
        setCategoryFormData(response.category_id);
        setSelectedTags(tags);
        setVariants(JSON.parse(JSON.stringify(response?.variants)));
        setOptions(response?.variantOptions);
        setFeatures(response?.features);
      })
      .catch((err) => console.log(err));
  }

  function handleCategorySelection(item: categoryType) {
    const index = selectedCategory.findIndex(
      (category) => category?.value == item?.value
    );
    if (index !== -1) {
      setSelectedCategory((prev) => prev.filter((_, i) => i !== index));
    } else {
      setSelectedCategory((prev) => [...prev, item]);
    }
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const payload = {
      ...data,
      images: productImages?.map((item) => item._id),
      tags: selectedTags.map((item) => item?.value),
      variants: variants,
      category_id: selectedCategory.map((item) => item?.value),
      variantOptions: options,
      features: features?.filter(
        (feature) =>
          feature?.title?.length !== 0 || feature?.description?.length !== 0
      ),
    };

    if (product_id) {
      editProduct(product_id, payload)
        .then(() => {
          toast({
            duration: 3000,
            title: "Updated",
            description: "Product has been updated successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    } else {
      createProduct(payload)
        .then(() => {
          toast({
            duration: 3000,
            title: "Product Added",
            description: "Product has been created successfully",
          });
          navigate("/products");
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    }
  };

  const totalInventory = useMemo(() => {
    const total = variants.reduce((acc, cur) => (acc += +cur.inventory), 0);
    setValue("inventory", total);
    return total;
  }, [variants]);

  return (
    <form className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8">
      <div className="grid flex-1 auto-rows-max gap-4">
        {/* ----------------- header section ----------- */}
        <div className="flex items-center gap-4">
          <NavLink to="/products">
            <Button
              variant="outline"
              size="icon"
              className="h-7 w-7"
              type="button"
            >
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </Button>
          </NavLink>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            Add Product
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <NavLink to="/products">
              <Button
                variant="outline"
                size="sm"
                className="h-7 gap-1"
                type="button"
              >
                Discard
              </Button>
            </NavLink>
            <Button
              size="sm"
              type="button"
              className="h-7 gap-1"
              onClick={() => handleSubmit(onSubmit)()}
            >
              {product_id ? "Update Product" : "Create Product"}
            </Button>
          </div>
        </div>

        {/* ----------------- form ----------- */}
        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0">
              <CardHeader>
                <CardTitle>Product Details</CardTitle>
                <CardDescription>
                  Your central hub for managing your product information.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="name">Product Name</Label>
                    <Input
                      id="name"
                      type="text"
                      className="w-full"
                      placeholder="Enter product name"
                      {...register("name", {
                        onBlur(event) {
                          setValue(
                            "slug",
                            event?.target?.value
                              .toLocaleLowerCase()
                              .replace(/[^A-Z0-9]/gi, "-")
                          );
                        },
                      })}
                    />
                  </div>

                  <div className="grid gap-3">
                    <Label htmlFor="description">Description</Label>
                    <Textarea
                      id="description"
                      placeholder="Enter product description"
                      className="min-h-32"
                      {...register("description")}
                    />
                  </div>
                  <div className=" flex gap-4">
                    <div className="grid gap-3">
                      <Label htmlFor="price">Price</Label>
                      <Input
                        type="number"
                        id="price"
                        placeholder="Enter product price"
                        className="max-w-[200px]"
                        {...register("price", {
                          onChange(event) {
                            setValue("discount_price", event.target.value);
                          },
                        })}
                      />
                    </div>

                    <div className="grid gap-3">
                      <Label htmlFor="discount_price">Discount Price</Label>
                      <Input
                        type="number"
                        id="discount_price"
                        placeholder="Enter product discount price"
                        className="max-w-[200px]"
                        {...register("discount_price")}
                      />
                    </div>
                  </div>

                  <Controller
                    control={control}
                    name="is_featured"
                    render={({ field }) => (
                      <div className="grid gap-3">
                        <Label htmlFor="category">Featured</Label>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </div>
                    )}
                  />

                  <div className="grid gap-3">
                    <Label htmlFor="hsn">HSN Code</Label>
                    <Input
                      id="hsn"
                      placeholder="Enter product HSN code"
                      {...register("hsn")}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className=" grid gap-5 p-4">
                <div className="grid gap-3">
                  <Label htmlFor="inventory">Inventory</Label>
                  <Input
                    id="inventory"
                    type="number"
                    placeholder="Enter product inventory"
                    {...register("inventory")}
                    disabled={!!variants.length}
                    defaultValue={totalInventory}
                  />
                </div>

                <div className="grid gap-3">
                  <Label htmlFor="weight">Weight (in Kgs)</Label>
                  <Input
                    id="weight"
                    placeholder="0.3"
                    {...register("weight")}
                    disabled={!!variants.length}
                  />
                </div>
              </CardContent>
            </Card>

            {/* ---------- variants ------------ */}
            <Card>
              <CardHeader>
                <CardTitle>Manage Variants</CardTitle>
                <CardDescription>
                  Create, Update and Remove your product variations
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ProductVariants
                  variants={variants}
                  setVariants={setVariants}
                  discount_price={getValues("discount_price")}
                  price={getValues("price")}
                  options={options}
                  setOptions={setOptions}
                />
              </CardContent>
            </Card>

            <Card x-chunk="dashboard-07-chunk-2">
              <CardHeader>
                <CardTitle>Product Category</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6 sm:grid-cols-3">
                  <div className="grid gap-3">
                    <Label htmlFor="category">Category</Label>
                    <ReactSelect
                      menuPlacement="auto"
                      options={categoryList}
                      onChange={(value) =>
                        handleCategorySelection(value as categoryType)
                      }
                    />
                  </div>
                </div>

                <div className=" flex flex-wrap gap-2 mt-4">
                  {selectedCategory.map((category) => (
                    <Badge
                      key={category?.value}
                      className={`font-semibold cursor-default capitalize text-xs flex gap-1 items-center} bg-primary`}
                    >
                      {category.label}
                      <div
                        onClick={() => handleCategorySelection(category)}
                        className="cursor-pointer"
                      >
                        <X size={17} />
                      </div>
                    </Badge>
                  ))}
                </div>
              </CardContent>

              <CardContent className="flex flex-col gap-2">
                <Label htmlFor="category">Tags</Label>
                <Tags
                  selected={selectedTags}
                  setSelected={setSelectedTags}
                  option={tags}
                  setOption={setTags}
                />
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-3">
              <CardHeader>
                <CardTitle>Product Status</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="status">Status</Label>
                    <Controller
                      control={control}
                      name="status"
                      render={({ field }) => (
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger id="status" aria-label="Select status">
                            <SelectValue placeholder="Select status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="draft">Draft</SelectItem>
                            <SelectItem value="active">Active</SelectItem>
                            <SelectItem value="archived">Archived</SelectItem>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="overflow-hidden" x-chunk="dashboard-07-chunk-4">
              <CardHeader>
                <CardTitle>Product Images</CardTitle>
                <CardDescription>
                  You need at least 4 images. Pay attention to the quality of
                  the pictures you add
                </CardDescription>
              </CardHeader>
              <CardContent>
                <UploadFile
                  accept="image"
                  maxLength={4}
                  files={productImages}
                  setFiles={setProductImages}
                />
              </CardContent>
            </Card>

            <MetaForm register={register} />
            <ProductFeatures features={features} setFeatures={setFeatures} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProductDetails;
