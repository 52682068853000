import { Button } from "@/components/ui/button";
import { NavLink } from "react-router-dom";

const EmptyCustomer = () => {
  return (
    <div className="grid h-[80vh] w-full">
      <div className="flex flex-col">
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
          <div className="flex items-center">
            <h1 className="text-lg font-semibold md:text-2xl">Customers</h1>
          </div>
          <div
            className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm"
            x-chunk="dashboard-02-chunk-1"
          >
            <div className="flex flex-col items-center gap-1 text-center">
              <h3 className="text-2xl font-bold tracking-tight">
                Everything customer related at one place
              </h3>
              <p className="text-sm text-muted-foreground">
                Manage customer details, see customer order history, separate list for online & offline customers. Add your first customer.
              </p>
              <NavLink to="/customers/add">
                <Button className="mt-4">Add Customer</Button>
              </NavLink>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EmptyCustomer;
