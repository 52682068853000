import axios from "axios";

export async function downloadFile(type: string, url: string) {
  const response = await axios.get(url, {
    responseType: "blob",
  });

  const blob = new Blob([response.data], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${type}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
