import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { NavLink } from "react-router-dom";

type props = {
  handleSubmit: () => void;
  menuId: string | null;
};

export default function CreateMenuHeader({ handleSubmit, menuId }: props) {
  return (
    <div className="flex items-center gap-4">
      <NavLink to="/menu">
        <Button variant="outline" size="icon" className="h-7 w-7" type="button">
          <ChevronLeft className="h-4 w-4" />
          <span className="sr-only">Back</span>
        </Button>
      </NavLink>
      <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
        Add Menu
      </h1>
      <div className="hidden items-center gap-2 md:ml-auto md:flex">
        <NavLink to="/menu">
          <Button
            variant="outline"
            size="sm"
            className="h-7 gap-1"
            type="button"
          >
            Discard
          </Button>
        </NavLink>
        <Button
          size="sm"
          className="h-7 gap-1"
          type="button"
          onClick={handleSubmit}
        >
          {menuId ? "Update Menu" : "Create Menu"}
        </Button>
      </div>
    </div>
  );
}
