import MetaForm from "@/components/common/MetaForm";
import RichText from "@/components/common/RichText";
import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import UploadFile from "@/components/media/UploadFile";
import {
  createBlog,
  getBlogDetails,
  updateBlog,
} from "@/services/blogServices";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { file } from "@/utils/Interface";

function BlogForm() {
  const [searchParam] = useSearchParams();
  const blogId = searchParam.get("id");

  const [thumbnail, setThumbnail] = useState<file[]>([]);

  const navigate = useNavigate();

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      content: "",
      summary: {
        content: "",
      },
      slug: "",
      meta: {
        title: "",
        description: "",
      },
      status: "draft",
      thumbnail: "",
      organization: {
        author: "",
      },
    },
  });

  useEffect(() => {
    if (blogId) {
      getBlogDetails(blogId)
        .then((res) => {
          setThumbnail(res.data?.thumbnail);

          for (let key in res.data) {
            // @ts-ignore
            setValue(key, res.data[key]);
          }
        })
        .catch((err) => console.log(err));
    }

    return () => {};
  }, []);

  function onSubmit(data: any) {
    const author =
      data.organization.author || localStorage.getItem("first_name");

    const payload = {
      ...data,
      thumbnail: thumbnail.map((el) => el._id),
      organization: { author },
    };

    if (blogId) {
      updateBlog(payload, blogId)
        .then(() => navigate(-1))
        .catch((err) => console.log(err));
    } else {
      createBlog(payload)
        .then(() => navigate(-1))
        .catch((err) => console.log(err));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex gap-4 w-full ">
        <div className=" w-full flex flex-col gap-4">
          <Card className=" p-4 flex flex-col gap-4">
            <div>
              <Label>Title</Label>
              <Input
                {...register("title", {
                  onBlur(event) {
                    setValue(
                      "slug",
                      event?.target?.value
                        .toLocaleLowerCase()
                        .replace(/[^A-Z0-9]/gi, "-")
                    );
                  },
                })}
              />
            </div>

            <Controller
              control={control}
              name="content"
              render={({ field }) => (
                <>
                  <Label>Content</Label>
                  <RichText field={field} />
                </>
              )}
            />
          </Card>

          <Card className=" p-4 flex flex-col gap-4">
            <CardTitle>Summary</CardTitle>
            <CardDescription>
              Add a summary of the to appear on you blog{" "}
            </CardDescription>

            <Controller
              control={control}
              name="summary.content"
              render={({ field }) => (
                <>
                  <Label>Content</Label>
                  <RichText field={field} />
                </>
              )}
            />
          </Card>

          <MetaForm register={register} />
        </div>

        <div className=" w-[40%] flex flex-col gap-4">
          <Card className=" p-4 flex flex-col gap-4">
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <>
                  <CardTitle>Status</CardTitle>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="active">Active</SelectItem>
                      <SelectItem value="draft">Draft</SelectItem>
                    </SelectContent>
                  </Select>
                </>
              )}
            />
          </Card>

          <Card className=" p-4 flex flex-col gap-4">
            <CardTitle>Feature Image</CardTitle>
            <UploadFile
              maxLength={1}
              accept="image"
              files={thumbnail}
              setFiles={setThumbnail}
            />
          </Card>

          <Card className=" p-4 flex flex-col gap-4">
            <CardTitle>Organization</CardTitle>

            <div>
              <Label>Author</Label>
              <Input {...register("organization.author")} />
            </div>
          </Card>
        </div>
      </div>

      <Button className=" mt-6 w-full" type="submit">
        {blogId ? "Update" : "Create"}
      </Button>
    </form>
  );
}

export default BlogForm;
