import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Activity, CreditCard, IndianRupee, Users } from "lucide-react";

export default function Metrics({ metrics, duration }: any) {
  const data = [
    {
      type: "revenue",
      label: "Revenue",
      value: `₹${metrics?.revenue?.current}`,
      changeValue: `${
        metrics?.revenue.current === 0
          ? 0
          : ((metrics?.revenue?.current - metrics?.revenue?.previous) * 100) /
            metrics?.revenue?.current
      }`,
      changeType: "percentage",
    },
    {
      type: "customers",
      label: "Customers",
      value: `${metrics?.customers?.current}`,
      changeValue: `${
        metrics?.customers.current === 0
          ? 0
          : ((metrics?.customers?.current - metrics?.customers?.previous) *
              100) /
            metrics?.customers?.current
      }`,
      changeType: "percentage",
    },
    {
      type: "orders",
      label: "Orders",
      value: `${metrics?.orders?.current}`,
      changeValue: `${
        metrics?.orders.current === 0
          ? 0
          : ((metrics?.orders?.current - metrics?.orders?.previous) * 100) /
            metrics?.orders?.current
      }`,
      changeType: "percentage",
    },
    {
      type: "aov",
      label: "Average Order Value",
      value: `₹${(metrics?.orders?.current === 0
        ? 0
        : metrics?.revenue?.current / metrics?.orders?.current
      ).toFixed(0)}`,
      changeValue: `${
        (((metrics?.orders?.current === 0
          ? 0
          : metrics?.revenue?.current / metrics?.orders?.current) -
          (metrics?.orders?.previous === 0
            ? 0
            : metrics?.revenue?.previous / metrics?.orders?.previous)) *
          100) /
        (metrics?.orders?.current === 0
          ? 0
          : metrics?.revenue?.current / metrics?.orders?.current)
      }`,
      changeType: "percentage",
    },
  ];

  function SetIcon(type: any) {
    switch (type) {
      case "revenue":
        return <IndianRupee className="h-4 w-4" color="#4D5F47" />;
      case "customers":
        return <Users className="h-4 w-4" color="#4D5F47" />;
      case "orders":
        return <CreditCard className="h-4 w-4" color="#4D5F47" />;
      case "aov":
        return <Activity className="h-4 w-4" color="#4D5F47" />;
    }
  }
  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-4 lg:grid-cols-4">
      {data.map((item, index) => {
        return (
          <Card key={index} className="shadow-none ">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {item.label}
              </CardTitle>
              {SetIcon(item?.type)}
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold flex flex-nowrap overflow-hidden">
                <span className="truncate">{item.value}</span>
              </div>
              <p className="text-xs">
                {parseInt(item?.changeValue)}
                {item?.changeType === "percentage" && "%"} from last {duration}
              </p>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}
