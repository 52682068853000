import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { CourierRating } from "./CourierRating";
import { Badge } from "@/components/ui/badge";

function Courier({
  data,
  createOrder,
}: {
  data: any;
  createOrder: (item: any) => void;
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow className="text-[12px]">
          <TableHead>Courier</TableHead>
          <TableHead className="text-center">Ratings</TableHead>
          <TableHead className="text-center">Expected Pickup</TableHead>
          <TableHead className="text-center">Estimated Delivery Time</TableHead>
          <TableHead className="text-center">Charges</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data?.map((item: any, index: number) => (
          <TableRow key={index}>
            <TableCell className="max-w-[250px]">
              <div className="flex flex-col gap-1">
                <p className="font-semibold">{item?.courier_name}</p>
                <p className="text-[12px] flex gap-1 items-center">
                  Min-weight:{" "}
                  <span className="font-semibold">{item?.min_weight} Kg</span> |{" "}
                  <span className="flex items-center gap-1 font-semibold">
                    <StarFilledIcon
                      color={item?.rating > 4 ? "#4D5F47" : "red"}
                    />
                    {item?.rating}
                  </span>
                </p>
                <p className="text-[12px]">
                  RTO Charges:{" "}
                  <span className="font-semibold">₹{item?.rto_charges}</span>
                </p>
              </div>
            </TableCell>
            <TableCell className="text-center">
              <CourierRating data={item} />
            </TableCell>
            <TableCell className="text-center font-semibold text-[12px]">
              <div className="flex justify-center items-center flex-col gap-1">
                {/* {item?.etd} */}
                <Badge variant="outline" className="w-fit">
                  Tomorrow
                  {/* {new Date(item?.etd).toLocaleString("en-us", {
                    weekday: "long",
                  })} */}
                </Badge>
              </div>
            </TableCell>
            <TableCell className="text-center font-semibold text-[12px]">
              <div className="flex justify-center items-center flex-col gap-1">
                {item?.etd}
                <Badge variant="outline">
                  {item?.estimated_delivery_days} day
                  {item?.estimated_delivery_days > 1 ? "s" : ""}
                </Badge>
              </div>
            </TableCell>
            <TableCell className="text-center font-semibold text-[12px]">
              ₹{item?.freight_charge}
            </TableCell>
            <TableCell className="text-right">
              <Button
                size="sm"
                className="bg-[#4D5F47]"
                onClick={() => createOrder(item)}
              >
                Ship Now
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default Courier;
