import { Button } from "@/components/ui/button";
import { useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ArrowDown, ArrowUp, ListMinus, Pencil, Trash2 } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

type MenuItem = {
  name: string;
  route: string;
  childrens?: MenuItem[];
};

type EditData = {
  parentIndex: number;
  childIndex?: number;
  data: {
    name: string;
    route: string;
  };
};

type props = {
  data: MenuItem[];
  setData: React.Dispatch<React.SetStateAction<MenuItem[]>>;
};

export default function MenuItemContainer({ data, setData }: props) {
  const [modal, setModal] = useState(false);

  const parentIndex = useRef<number | null>(null);
  const [editData, setEditData] = useState<EditData | null>(null);

  function addChildren(newChild: MenuItem) {
    if (parentIndex.current == null) {
      return false;
    }

    const parent = data[parentIndex.current!];

    if (!parent.childrens) {
      parent.childrens = [];
    }

    parent.childrens.push(newChild);

    setData(JSON.parse(JSON.stringify(data)));

    parentIndex.current = null;
  }

  function removeChildren(pIndex: number, cIndex: number) {
    setData((prev) =>
      prev.map((el, index) => {
        if (index === pIndex) {
          return {
            ...el,
            childrens:
              el.childrens && el.childrens.filter((_, idx) => idx !== cIndex),
          };
        }
        return el;
      })
    );
  }

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const newBox = Array.from(data);
    const [draggedItem] = newBox.splice(result.source.index, 1);
    newBox.splice(result.destination.index, 0, draggedItem);
    setData(newBox);
  }

  function addNewMenu(event: any) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formObject = Object.fromEntries(formData.entries());

    if (editData) {
      if (editData.childIndex !== undefined) {
        console.log({ childIndex: data });
        setData((prev) => {
          let temp = structuredClone(prev);

          temp[editData.parentIndex] = {
            ...temp[editData.parentIndex],
            childrens: temp[editData.parentIndex].childrens?.map(
              (item, index) =>
                index === editData.childIndex
                  ? { ...item, ...formObject }
                  : item
            ),
          };
          return temp;
        });
      } else {
        setData((prev) => {
          let temp = structuredClone(prev);
          temp[editData.parentIndex] = {
            ...temp[editData.parentIndex],
            ...formObject,
          };
          return temp;
        });
      }
    } else {
      if (parentIndex.current != null) {
        //@ts-ignore
        addChildren(formObject);
      } else {
        setData((prev: any) => [...prev, formObject]);
      }
    }

    setModal(false);
    setEditData(null);
  }

  function shiftChildren(type: "up" | "down", parent: number, index: number) {
    const maxLength = data[parent].childrens?.length ?? 0;

    const newDate = JSON.parse(JSON.stringify(data));
    const children = newDate[parent].childrens;

    if (type == "up" && index > 0) {
      if (children) {
        const swapItem = children[index - 1];
        const currentItem = children[index];

        children[index - 1] = currentItem;
        children[index] = swapItem;
      }
    } else if (type == "down" && index < maxLength - 1) {
      if (children) {
        const swapItem = children[index + 1];
        const currentItem = children[index];

        children[index + 1] = currentItem;
        children[index] = swapItem;
      }
    }

    setData(newDate);
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {modal && (
        <Dialog onOpenChange={setModal} open={modal}>
          <DialogContent>
            <DialogHeader className="flex flex-col gap-4">
              <DialogTitle>Add your menu items here</DialogTitle>
              <form onSubmit={addNewMenu} className="flex flex-col gap-4">
                <>
                  <div className="flex flex-col gap-2">
                    <Label>Name</Label>
                    <Input
                      name="name"
                      placeholder="About"
                      required={true}
                      defaultValue={editData?.data?.name}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <Label>Link</Label>
                    <Input
                      name="route"
                      placeholder="https://www.yourstore.com/about or /about"
                      defaultValue={editData?.data?.route}
                    />
                  </div>

                  <Button type="submit">Add to Menu</Button>
                </>
              </form>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
      <CardTitle className="text-sm font-semibold">Menu Items</CardTitle>
      <Droppable droppableId="parent" type="parent">
        {(provided) => (
          <div
            ref={provided?.innerRef}
            {...provided?.droppableProps}
            className="flex flex-col gap-4 mb-2"
          >
            {data.map((element, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided?.innerRef}
                    {...provided?.draggableProps}
                    {...provided?.dragHandleProps}
                  >
                    <Card className="shadow-none rounded-md">
                      <div className="flex justify-between items-center p-2 px-4 rounded-sm">
                        <div className="font-normal text-sm">
                          {element?.name}
                        </div>
                        <div className="font-normal text-sm">
                          {element?.route}
                        </div>

                        <div className=" flex gap-4 items-center">
                          <Button
                            onClick={() => {
                              parentIndex.current = index;
                              setModal(true);
                            }}
                            size="sm"
                            variant="outline"
                            className="!m-0"
                          >
                            Add Sub-Item
                          </Button>

                          <Pencil
                            size={30}
                            className="cursor-pointer bg-slate-200 p-2 rounded-md"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditData({
                                parentIndex: index,
                                data: {
                                  name: element.name,
                                  route: element?.route,
                                },
                              });
                              setModal(true);
                            }}
                          />

                          <Trash2
                            size={30}
                            className="cursor-pointer bg-slate-200 p-2 rounded-md"
                            onClick={(e) => {
                              e.stopPropagation();
                              setData((prev) =>
                                prev.filter((_, idx) => idx != index)
                              );
                            }}
                          />
                        </div>
                      </div>
                      {element?.childrens!?.length > 0 && (
                        <CardContent className="flex flex-col gap-2">
                          {element?.childrens?.map((element2, idx) => (
                            <Card key={idx} className="shadow-none bg-gray-200">
                              <CardHeader className="flex flex-row justify-between items-center py-3">
                                <CardTitle className="font-normal text-[14px]">
                                  {element2?.name}
                                </CardTitle>
                                <CardTitle className="font-normal !m-0 text-[14px]">
                                  {element2?.route}
                                </CardTitle>
                                <div className="flex flex-row gap-2 items-center !m-0">
                                  <div
                                    onClick={() =>
                                      shiftChildren("up", index, idx)
                                    }
                                  >
                                    <ArrowUp
                                      className="text-gray-500"
                                      size={18}
                                    />
                                  </div>
                                  <div
                                    onClick={() =>
                                      shiftChildren("down", index, idx)
                                    }
                                  >
                                    <ArrowDown
                                      className="text-gray-500"
                                      size={18}
                                    />
                                  </div>
                                  <div
                                    onClick={() => removeChildren(index, idx)}
                                  >
                                    <ListMinus
                                      className="text-gray-500"
                                      size={18}
                                    />
                                  </div>

                                  <Pencil
                                    className=" cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditData({
                                        parentIndex: index,
                                        childIndex: idx,
                                        data: {
                                          name: element2.name,
                                          route: element2?.route,
                                        },
                                      });
                                      setModal(true);
                                    }}
                                  />
                                </div>
                              </CardHeader>
                            </Card>
                          ))}
                        </CardContent>
                      )}
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
      <Button
        type="button"
        onClick={() => setModal(true)}
        size="sm"
        className="w-fit"
      >
        Add Menu Items
      </Button>
    </DragDropContext>
  );
}
