import { File, ListFilter, MoreHorizontal } from "lucide-react";

// import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";

export function CustomerTable({
  customers,
  onlineCustomers,
}: any) {
  return (
    <Tabs defaultValue="website">
      <div className="flex items-center">
        {/* <div className="flex gap-2">
              <TabsList onChange={(e) => console.log({ e })}>
                <TabsTrigger
                  value="website"
                  onClick={() => setOnlineCustomers(true)}
                >
                  Website
                </TabsTrigger>
                <TabsTrigger
                  value="shop"
                  onClick={() => setOnlineCustomers(false)}
                >
                  Shop
                </TabsTrigger>
              </TabsList>
            </div> */}
        <div className="ml-auto hidden md:flex items-center gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-7 gap-1">
                <ListFilter className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Filter
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Filter by</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem checked>
                Active
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Draft</DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Archived</DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button size="sm" variant="outline" className="h-7 gap-1">
            <File className="h-3.5 w-3.5" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              Export
            </span>
          </Button>
          {/* <NavLink to="/customers/add">
            <Button size="sm" className="h-7 gap-1">
              <PlusCircle className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Add Customer
              </span>
            </Button>
          </NavLink> */}
        </div>
      </div>
      <TabsContent value={onlineCustomers ? "website" : "shop"} className="h-[80vh]">
        <Card>
          <CardHeader>
            <CardTitle>
              {onlineCustomers ? "Website" : "Offline"} Customers
            </CardTitle>
            <CardDescription>
              Manage your {onlineCustomers ? "website" : "offline"} customers
              and view their details.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow className="text-[12px]">
                  <TableHead>Customer Name</TableHead>
                  <TableHead className="hidden md:table-cell">Orders</TableHead>
                  <TableHead className="table-cell">
                    Amount Spent
                  </TableHead>
                  <TableHead>
                    <span className="sr-only">Actions</span>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {customers?.map((item: any, index: any) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <NavLink to={`/customers/detail/${item?._id}`} className="flex flex-row gap-4 items-center text-xs">
                        <Avatar className="hidden h-9 w-9 sm:flex">
                          <AvatarImage
                            src={item?.image}
                            alt="Avatar"
                            className="rounded-full object-cover"
                          />
                          <AvatarFallback className="uppercase">
                            {item?.name?.slice(0, 2)}
                          </AvatarFallback>
                        </Avatar>
                        <div>
                          <div className="font-medium capitalize">{item?.name}</div>
                          <div className="hidden text-xs text-muted-foreground md:inline">
                          {item?.email}
                        </div>
                        </div>
                        </NavLink>
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        {item?.order_count}
                      </TableCell>
                      <TableCell className="table-cell">
                        ₹{item?.order_total}
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            {/* <DropdownMenuLabel>Actions</DropdownMenuLabel> */}
                            <NavLink to="/customers/edit">
                              <DropdownMenuItem>Edit</DropdownMenuItem>
                            </NavLink>
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                {/* <Button variant="outline">Show Dialog</Button> */}
                                <DropdownMenuItem>Delete</DropdownMenuItem>
                              </AlertDialogTrigger>
                              <AlertDialogContent>
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    Are you absolutely sure?
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    This action cannot be undone. This will
                                    permanently delete your account and remove
                                    your data from our servers.
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                                  <AlertDialogAction>
                                    Continue
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}
