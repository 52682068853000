import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getPageList(filter?: string | null) {
  return axiosInterceptor.get("/page", {
    params: {
      filter,
    },
  });
}

export function getPageDetails(id: string | null) {
  return axiosInterceptor.get(`/page/${id}`);
}

export function createPage(payload: any) {
  return axiosInterceptor.post(`/page`, payload);
}

export function updatePage(payload: any, id: any) {
  return axiosInterceptor.put(`/page/${id}`, payload);
}

export function deletePage(id: any) {
  return axiosInterceptor.delete(`/page/${id}`);
}
