import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import "./App.css";
import { Login } from "./modules/Authentication/pages/Login";
import { Product } from "./modules/Products/pages/Product";
import Layout from "./components/layout/Layout";
import { Orders } from "./modules/Orders/pages/Orders";
import { Customers } from "./modules/Customers/pages/Customers";
import { Category } from "./modules/Categories/pages/Category";
import RegisterForm from "./modules/Authentication/pages/Register";
import CreateStore from "./modules/Store/pages/CreateStore";
import SelectStore from "./modules/Store/pages/SelectStore";
import StoreSettings from "./modules/Settings/pages/StoreSettings";
import ProductDetails from "./modules/Products/pages/ProductDetails";
import CustomerDetails from "./modules/Customers/pages/CustomerDetails";
import AddCustomer from "./modules/Customers/components/AddCustomer";
import { CategoryForm } from "./modules/Categories/pages/CategoryForm";
import { Integrations } from "./modules/Integrations/pages/Integrations";
import OrderDetailsPage from "./modules/Orders/pages/OrderDetailsPage";
import Plans from "./modules/Billing/pages/Plans";
import Feedback from "./modules/Marketing/Feedback/Feedback";
import StoreSettingLayout from "./components/layout/StoreSettingLayout";
import Blog from "./modules/Content/Blog/page/Blog";
import BlogForm from "./modules/Content/Blog/page/BlogForm";
import ComingSoon from "./components/common/ComingSoon";
import Page from "./modules/Content/Page/page/Page";
import PageForm from "./modules/Content/Page/page/PageForm";
import StoreMeta from "./modules/Store/StoreMeta";
import { Home } from "./modules/Home/pages/Home";
import { Analytics } from "./modules/Analytics/pages/Analytics";
import { Discount } from "./modules/Discount/pages/Discount";
import { DiscountForm } from "./modules/Discount/pages/DiscountForm";
import CustomDomain from "./modules/Settings/pages/CustomDomain";
// import OrderDetailsPage from "./pages/Order/OrderDetails";
// import UserProfile from "./modules/User/pages/UserProfile";
import Menu from "./modules/Appearance/Menu/pages/Menu";
import CreateMenu from "./modules/Appearance/Menu/components/CreateMenu";
import Media from "./modules/Content/Media/pages/Media";
import Customise from "./modules/Appearance/Customise/components/Customise";
import ShippingSetting from "./modules/Settings/pages/ShippingSetting";
import Warehouse from "./modules/Settings/Warehouse/pages/Warehouse";
import { WarehouseForm } from "./modules/Settings/Warehouse/pages/WarehouseForm";
import { UserTable } from "./modules/Settings/Users/components/UserTable";
import { UserForm } from "./modules/Settings/Users/pages/UserForm";
import Content from "./modules/Content/Content";
import Marketing from "./modules/Marketing/Marketing";
import { GoogleOAuthProvider } from "@react-oauth/google";
import './fonts.css';
import AbandonedCart from "./modules/Marketing/AbandonedCart/pages/AbandonedCart";

function App() {
  return (
    <div className="h-full">
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterForm />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/create-store" element={<CreateStore />} />
            <Route path="/select-store" element={<SelectStore />} />
            <Route element={<Layout />}>
              <Route path="" element={<Home />} />
              <Route path="/analytics" element={<Analytics />} />

              {/* Order Routes */}
              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/:id" element={<OrderDetailsPage />} />

              {/* Product Routes */}
              <Route path="products" element={<Product />} />
              <Route path="products/add" element={<ProductDetails />} />
              <Route path="products/edit" element={<ProductDetails />} />

              {/* Category Routes */}
              <Route path="category" element={<Category />} />
              <Route path="category/add" element={<CategoryForm />} />
              <Route path="category/edit" element={<CategoryForm />} />

              {/* Customer Routes */}
              <Route path="customers" element={<Customers />} />
              <Route path="customers/add" element={<AddCustomer />} />
              <Route
                path="customers/detail/:id"
                element={<CustomerDetails />}
              />

              {/* Integration Routes */}
              <Route path="/integrations" element={<Integrations />} />

              {/* Marketing Routes */}
              <Route path="/marketing" element={<Marketing />} />
              <Route path="abandoned-cart" element={<AbandonedCart />}/>
              <Route path="/plans" element={<Plans />} />
              <Route path="/feedback" element={<Feedback />} />

              {/* Content Routes */}
              <Route path="/content" element={<Content />} />

              {/* Blog Routes */}
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/create" element={<BlogForm />} />
              <Route path="/blog/edit" element={<BlogForm />} />

              {/* Media Routes */}
              <Route path="/media" element={<Media />} />

              {/* Pages Routes */}
              <Route path="/pages" element={<Page />} />
              <Route path="/page/create" element={<PageForm />} />
              <Route path="/page/edit" element={<PageForm />} />

              {/* Discount Routes */}
              <Route path="/discount" element={<Discount />} />
              <Route path="discount/edit" element={<DiscountForm />} />
              <Route path="discount/add" element={<DiscountForm />} />

              {/* Setting Routes */}
              <Route path="/settings" element={<StoreSettingLayout />}>
                <Route path="" element={<StoreSettings />} />
                <Route path="payment" element={<h1>Page 3</h1>} />
                <Route path="shipping" element={<ShippingSetting />} />
                <Route path="meta" element={<StoreMeta />} />
                <Route path="custom-domain" element={<CustomDomain />} />
                <Route path="warehouse" element={<Warehouse />} />
                <Route path="warehouse/add" element={<WarehouseForm />} />
                <Route path="warehouse/edit" element={<WarehouseForm />} />
                <Route path="user-management" element={<UserTable />} />
                <Route path="user-management/add" element={<UserForm />} />
                <Route path="user-management/edit" element={<UserForm />} />
              </Route>

              {/* Access Management Routes */}
              {/* <Route path="profile" element={<UserProfile />} /> */}

              {/* Customise Routes */}
              <Route path="/appearance" element={<ComingSoon />} />
              <Route path="/themes" element={<Blog />} />
              <Route path="/customise" element={<Customise />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/menu/add" element={<CreateMenu />} />
              <Route path="/menu/edit" element={<CreateMenu />} />
            </Route>
          </Route>
        </Routes>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;

function PrivateRoute() {
  const [searchparams] = useSearchParams();
  const location = useLocation();
  const secret = searchparams.get("secret");

  if (!localStorage?.getItem("first_name")) {
    localStorage.clear();
    <Navigate to={`/login`} />;
  }

  return localStorage?.getItem("token") ||
    (location?.pathname == `/create-store` && secret) ? (
    <Outlet />
  ) : (
    <Navigate to={`/login`} />
  );
}

function PublicRoute() {
  return !localStorage?.getItem("token") ? <Outlet /> : <Navigate to="" />;
}
