import React from "react";
import { Button } from "@/components/ui/button";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Minus, Plus } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface Features {
  title: string | null;
  description: string | null;
}

interface Props {
  setFeatures: React.Dispatch<React.SetStateAction<Features[]>>;
  features: Features[];
}

const ProductFeatures: React.FC<Props> = ({ setFeatures, features }) => {
  const addSection = () => {
    setFeatures([...features, { title: "", description: "" }]);
  };

  const removeSection = (index: number) => {
    const updatedSections = features.filter((_, i) => i !== index);
    setFeatures(updatedSections);
  };

  const handleTitle = (index: number, title: string) => {
    const updatedSections = [...features];
    updatedSections[index] = {
      ...updatedSections[index],
      title,
    };
    setFeatures(updatedSections);
  };

  const handleDescription = (index: number, description: string) => {
    const updatedSections = [...features];
    updatedSections[index] = {
      ...updatedSections[index],
      description,
    };
    setFeatures(updatedSections);
  };

  return (
    <Accordion type="single" collapsible className="w-full gap-2 grid">
      <CardTitle className="text-xl p-0">Features</CardTitle>
      <CardDescription>
        Easily expand the list of features to capture all aspects of your
        product experience.
      </CardDescription>
      {features.map((section, index) => (
        <div key={index} className="flex items-start gap-2 mt-2 ">
          <AccordionItem
            value={`${index + 1}`}
            className="border rounded-xl w-[95%] overflow-hidden"
          >
            <AccordionTrigger className="py-3 px-4 hover:bg-slate-50 hover:no-underline">
              <Label>Feature {index + 1}</Label>
            </AccordionTrigger>
            <AccordionContent className="px-4 pt-4">
              <div className="grid grid-cols-1 gap-4">
                <div className="grid gap-2">
                  <Label className="text-[12px] text-slate-600">Title</Label>
                  <Input
                    defaultValue={section.title ?? ""}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTitle(index, e?.target?.value)
                    }
                  />
                </div>
                <div className="grid gap-2">
                  <Label className="text-[12px] text-slate-600">
                    Description
                  </Label>
                  <Textarea
                    defaultValue={section.description ?? ""}
                    onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleDescription(index, e?.target?.value)
                    }
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
          <Minus
            onClick={() => removeSection(index)}
            size={20}
            className="bg-red-400 hover:bg-gray-950 cursor-pointer text-white p-1 aspect-square rounded-full my-2"
          />
          <Plus
            size={20}
            className={`bg-primary hover:bg-gray-950 cursor-pointer text-white p-1 aspect-square rounded-full my-2 ${
              index !== features.length - 1 ? "invisible" : ""
            }`}
            onClick={() => {
              if (index === features?.length - 1) {
                addSection();
              }
            }}
          />
        </div>
      ))}
      <div>
        {features.length < 1 && (
          <Button
            type="button"
            className="w-fit gap-2 mt-4"
            size={"sm"}
            onClick={addSection}
          >
            <Plus size={16} /> Add Features
          </Button>
        )}
      </div>
    </Accordion>
  );
};

export default ProductFeatures;
