import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function installPlugin(payload: any) {
  return axiosInterceptor.post("/credential/install_plugin", payload);
}

export function getCredentials() {
  return axiosInterceptor.get("/credential");
}

export function updateCredential(payload: any) {
  return axiosInterceptor.put("/credential", payload);
}

export function deactivatePlugin(payload: any) {
  return axiosInterceptor.put("/credential/deactivate", payload);
}

export function uninstallPlugin(payload: any) {
  return axiosInterceptor.put("/credential/uninstall_plugin", payload);
}

export function activatePlugin(payload: any) {
  return axiosInterceptor.put("/credential/activate", payload);
}

// export function updateCredential(payload: any) {
//   return axiosInterceptor.put("/credential", payload);
// }
