// import Image from "next/image"
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { loginFormSchema } from "@/modules/Authentication/schemas/login";
import { googleLogin, loginUser } from "@/services/authService";
import { zodResolver } from "@hookform/resolvers/zod";
import { GoogleLogin } from "@react-oauth/google";
import { Eye, EyeOff, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import AuthVisual from "../components/AuthVisual";
import { toast } from "@/components/ui/use-toast";

export function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function submitHandler(formData: z.infer<typeof loginFormSchema>) {
    setIsLoading(true);

    loginUser(formData)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("first_name", res.data.first_name);
        if (res?.data?.storeList?.length) {
          navigate("/select-store");
        } else {
          navigate("/create-store");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }

  function handleGoogleSign(credentialResponse: any) {
    googleLogin({credential:credentialResponse.credential})
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("first_name", res.data.first_name);
        if (res?.data?.storeList?.length) {
          navigate("/select-store");
        } else {
          navigate("/create-store");
        }
      })
      .catch((err) => 
      {
        toast({
          duration: 3000,
          title: "Error",
          description: "Email is not associated with any account. Create your account to continue",
        });
        console.log(err);
      })
  }

  return (
    <div className="w-full md:grid md:min-h-[600px] h-full p-4 md:grid-cols-2">
      <div className="flex items-center justify-center py-12 px-6">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2">
            <h1 className="text-3xl font-medium">Welcome Back!</h1>
            <p className="text-balance text-gray-700 text-base">
              Enter your email below to login to your account
            </p>
          </div>
          <div className="grid gap-4">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(submitHandler)}
                className="grid gap-4"
              >
                <div className="grid gap-2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email address</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="johndoe@gmail.com"
                            {...field}
                            className="placeholder:text-gray-300 shadow-none"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid gap-2">
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Input
                              placeholder="Enter Password"
                              className="placeholder:text-gray-300 shadow-none"
                              type={showPassword ? "text" : "password"}
                              {...field}
                            />
                            <div
                              className={`absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-500 ${
                                field?.value?.length > 0 ? "block" : "hidden"
                              }`}
                              onClick={() => setShowPassword((p) => !p)}
                            >
                              {showPassword ? (
                                <Eye size={16} />
                              ) : (
                                <EyeOff size={16} />
                              )}
                            </div>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {/* <div className="flex items-center">
                    <Link
                      to="/forgot-password"
                      className="ml-auto inline-block text-sm underline"
                    >
                      Forgot your password?
                    </Link>
                  </div> */}
                </div>

                <Button type="submit" className="w-full bg-primary font-medium">
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Please wait
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </form>
            </Form>

            <GoogleLogin
              onSuccess={handleGoogleSign}
              theme="outline"
              text="signin_with"
              width={350}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </div>
          <div className="mt-2 text-center text-sm">
            New to Pinecart?{"  "}
            <Link
              to="/register"
              className="underline font-semibold text-primary"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
      <AuthVisual />
    </div>
  );
}
