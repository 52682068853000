// import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useEffect } from "react";
import { getStoreDetails, updateStoreDetails } from "@/services/storeServices";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { StoreSettingsFormSchema } from "@/modules/Store/schemas/createStore";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { checkPhoneNumber } from "@/utils/utils";

const StoreSettings = () => {
  // const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    getStoreDetails()
      .then((res) => {
        const prefill = res?.data?.data;

        form.setValue("store_name", prefill?.store_name);
        form.setValue("store_type", prefill?.store_type);
        form.setValue("store_description", prefill?.store_description);
        form.setValue(
          "store_address.address_one",
          prefill?.store_address.address_one
        );
        form.setValue(
          "store_address.address_two",
          prefill?.store_address.address_two
        );
        form.setValue("store_address.state", prefill?.store_address.state);
        form.setValue("store_address.city", prefill?.store_address.city);
        form.setValue(
          "store_address.pin_code",
          prefill?.store_address.pin_code
        );
        form.setValue(
          "social_media.instagram",
          prefill?.social_media.instagram
        );
        form.setValue(
          "social_media.facebook",
          prefill?.social_media.facebook
        );
        form.setValue("social_media.youtube", prefill?.social_media.youtube);
        form.setValue(
          "business_details.business_name",
          prefill?.business_details?.business_name
        );
        form.setValue(
          "business_details.business_type",
          prefill?.business_details?.business_type
        );
        form.setValue(
          "business_details.industry",
          prefill?.business_details?.industry
        );
        form.setValue(
          "business_details.tax_id",
          prefill?.business_details?.tax_id
        );
        form.setValue("currency", prefill?.currency);
        form.setValue(
          "contact_information.email_address",
          prefill?.contact_information.email_address
        );
        form.setValue(
          "contact_information.contact_number",
          prefill?.contact_information.contact_number
        );
        form.setValue(
          "contact_information.whatsapp_number",
          prefill?.contact_information.whatsapp_number
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const form = useForm<z.infer<typeof StoreSettingsFormSchema>>({
    resolver: zodResolver(StoreSettingsFormSchema),
    defaultValues: {
      store_name: "",
      store_type: undefined,
      store_description: "",
      contact_information: {
        email_address: "",
        contact_number: "",
        whatsapp_number: "",
      },
      store_address: {
        address_one: "",
        address_two: "",
        country: "india",
        state: "",
        city: "",
        pin_code: "",
      },
      social_media: {
        instagram: "",
        facebook: "",
        youtube: "",
      },
      business_details: {
        business_name: "",
        industry: undefined,
        business_type: undefined,
        tax_id: "",
      },
      currency: undefined,
      return_policy_url: "",
      privacy_policy_url: "",
      terms_of_service: false,
    },
  });

  async function submitHandler(
    formData: z.infer<typeof StoreSettingsFormSchema>
  ) {
    const payload = {
      ...formData,
    };

    updateStoreDetails(payload)
      .then((res) => {
        res.status == 200 &&
          toast({
            duration: 3000,
            title: "Updated",
            description: "Store settings has been updated successfully",
          });
        console.log(res);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
        console.log(error);
      });
  }

  // function deleteStoreHandler() {
  //   deleteStore(storeId)
  //     .then(() => {
  //       navigate("/select-store");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  return (
    <div className="flex flex-1 flex-col gap-6">
      <div className="grid gap-4">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(submitHandler)}
            className="grid gap-4"
          >
            <Card x-chunk="dashboard-04-chunk-1">
              <CardHeader>
                <CardTitle className="text-xl">Store details</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-4 ">
                <div className="flex flex-col gap-2">
                  <FormField
                    control={form.control}
                    name="store_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Store name</FormLabel>
                        <FormControl>
                          <Input placeholder="Walmart" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <FormField
                    control={form.control}
                    name="store_type"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Store Type</FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger
                              id="model"
                              className="items-start [&_[data-description]]:hidden"
                            >
                              <SelectValue
                                placeholder="Select"
                                defaultValue=""
                              />
                            </SelectTrigger>
                            <SelectContent>
                              {store_type.map((item, index) => {
                                return (
                                  <SelectItem value={item.value} key={index}>
                                    {item.name}
                                  </SelectItem>
                                );
                              })}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <FormField
                    control={form.control}
                    name="store_description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Description{" "}
                          <span className="text-slate-500 font-normal">
                            (Optional)
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Write a small introduction about your store."
                            className="max-h-[200px]"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="p-[1px] bg-slate-100" />
                <div className="flex flex-col gap-2 ">
                  <Label>Address</Label>
                  <div className="flex flex-col gap-2">
                    <FormField
                      control={form.control}
                      name="store_address.address_one"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Apartment or Building"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <FormField
                      control={form.control}
                      name="store_address.address_two"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Street address or P.O Box"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className="flex flex-col gap-4">
                      <FormField
                        control={form.control}
                        name="store_address.state"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              State
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="State"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-4">
                      <FormField
                        control={form.control}
                        name="store_address.city"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400  font-normal">
                              city
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="City"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-4">
                      <FormField
                        control={form.control}
                        name="store_address.pin_code"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              ZIP Code
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="201301"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-[1px] bg-slate-100" />

                <div className="flex flex-col gap-2">
                  <Label>Contact</Label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className="flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name="contact_information.email_address"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              Email
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="email@yourbusiness.com"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name="contact_information.contact_number"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              Phone
                            </FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                value={field.value}
                                placeholder="9876543210"
                                className="!mt-1"
                                onChange={(event) =>
                                  checkPhoneNumber(event, field)
                                }
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name="contact_information.whatsapp_number"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              Whatsapp
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="9876543210"
                                className="!mt-1"
                                {...field}
                                value={field.value}
                                onChange={(event) =>
                                  checkPhoneNumber(event, field)
                                }
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Label>Socials</Label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className="flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name="social_media.instagram"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              Instagram
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="Instagram URL"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name="social_media.facebook"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              Facebook
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="Facebook URL"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name="social_media.youtube"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-slate-400 font-normal">
                              Youtube
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder="Youtube channel URL"
                                className="!mt-1"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="currency"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Currency</FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger
                              id="model"
                              className="items-start [&_[data-description]]:hidden"
                            >
                              <SelectValue
                                placeholder="Select"
                                defaultValue=""
                              />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="INR">INR</SelectItem>
                              <SelectItem value="USD">USD</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
            <Card x-chunk="dashboard-04-chunk-2">
              <CardHeader>
                <CardTitle>Business details</CardTitle>
                <CardDescription>
                  The directory within your project, in which your plugins are
                  located.
                </CardDescription>
              </CardHeader>
              <CardContent className="flex flex-col gap-4">
                <div className=" grid grid-cols-1 md:grid-cols-3 gap-2">
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="business_details.business_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="">Business name</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Business name pvt ltd"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="business_details.business_type"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Business type</FormLabel>
                          <FormControl>
                            <Select
                              onValueChange={field.onChange}
                              value={field.value}
                            >
                              <SelectTrigger
                                id="model"
                                className="items-start [&_[data-description]]:hidden"
                              >
                                <SelectValue
                                  placeholder="Select"
                                  defaultValue=""
                                />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Sole proprietorship">
                                  Sole proprietorship
                                </SelectItem>
                                <SelectItem value="Corporation">
                                  Corporation
                                </SelectItem>
                                <SelectItem value="other">Other</SelectItem>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="business_details.industry"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Industry</FormLabel>
                          <FormControl>
                            <Select
                              onValueChange={field.onChange}
                              value={field.value}
                            >
                              <SelectTrigger
                                id="model"
                                className="items-start [&_[data-description]]:hidden"
                              >
                                <SelectValue
                                  placeholder="Select"
                                  defaultValue=""
                                />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Retail">Retail</SelectItem>
                                <SelectItem value="Food">Food</SelectItem>
                                <SelectItem value="other">Other</SelectItem>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="business_details.tax_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="">Tax Id</FormLabel>
                        <FormControl>
                          <Input placeholder="GST" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <Label>Location</Label>
                  <Input placeholder="Location with lat-long" />
                </div>
              </CardContent>
            </Card>
            <Button size="sm" type="submit">
              Update
            </Button>
          </form>
        </Form>
        {/* <div className="flex flex-col gap-2">
        <CardTitle>Danger Zone</CardTitle>
        <Card className="border-red-500">
          <CardHeader>
            <CardTitle>Delete this store</CardTitle>
            <CardDescription>
              By deleting this store you agree to our terms of removal of data.
              All the data associated to the store will be permanently removed,
              Kindly check if you need any backup in future.
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Dialog>
              <DialogTrigger>
                <Button className="bg-red-500 border border-red-500 cursor-pointer">
                  Delete
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Are you absolutely sure?</DialogTitle>
                  <DialogDescription>
                    This action cannot be undone. Are you sure you want to
                    permanently delete this file from our servers?
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button type="submit" onClick={deleteStoreHandler}>
                    Confirm
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </CardFooter>
        </Card>
        </div> */}
      </div>
    </div>
  );
};

export default StoreSettings;

const store_type = [
  {
    name: "Books",
    value: "books",
  },
  {
    name: "Home Decor",
    value: "home-decor",
  },
  {
    name: "Furniture",
    value: "furniture",
  },
  {
    name: "Electronics",
    value: "electronics",
  },
  {
    name: "Beauty",
    value: "beauty",
  },
  {
    name: "Medical",
    value: "medical",
  },
  {
    name: "Sports",
    value: "sports",
  },
  {
    name: "Services",
    value: "service",
  },
  {
    name: "Cafe",
    value: "cafe",
  },
  {
    name: "Restaurant",
    value: "restaurant",
  },
  {
    name: "Dairy",
    value: "dairy",
  },
  {
    name: "Grocery",
    value: "grocery",
  },
  {
    name: "Others",
    value: "other",
  },
];
