import CreatableSelect from "react-select/creatable";
import React from "react";
import { createTag } from "@/services/tagServices";

type OptionType = {
  value: string;
  label: string;
};

type props = {
  selected: OptionType[];
  setSelected: React.Dispatch<React.SetStateAction<OptionType[]>>;
  option: OptionType[];
  setOption: React.Dispatch<React.SetStateAction<OptionType[]>>;
};

function Tags({ selected, setSelected, option, setOption }: props) {

  const handleCreate = (inputValue: string) => {
    createTag({ name: inputValue.toLowerCase() })
      .then(({ data: { id } }) => {
        const newOption = {
          value: id,
          label: inputValue,
        };
        setOption((prevOptions) => [...prevOptions, newOption]);
        setSelected((prevSelected: any) => [...prevSelected, newOption]);
      })
      .catch((err) => console.log(err));
  };



  return (
    <CreatableSelect
      isMulti
      value={selected}
      onChange={(option: any) => setSelected(option)}
      onCreateOption={handleCreate}
      options={option}
    />
  );
}

export default Tags;
