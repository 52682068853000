import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getBlogList(filter?: string | null) {
  return axiosInterceptor.get("/blog", {
    params: {
      filter,
    },
  });
}

export function getBlogDetails(id: string | null) {
  return axiosInterceptor.get(`/blog/${id}`);
}

export function createBlog(payload: any) {
  return axiosInterceptor.post(`/blog`, payload);
}

export function updateBlog(payload: any, id: any) {
  return axiosInterceptor.put(`/blog/${id}`, payload);
}

export function deleteBlog(id: any) {
  return axiosInterceptor.delete(`/blog/${id}`);
}
