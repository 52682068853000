import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useEffect } from "react";

export default function Layout() {
  useEffect(() => {
    if (!localStorage?.getItem("access_token")) {
      localStorage.clear();
      <Navigate to={`/login`} />;
    }
  }, []);

  return (
    <div className="flex flex-col">
      <Header />
      <Sidebar />
      <div className="flex">
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
          <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-60">
            <main className="flex flex-1 flex-col gap-4 md:gap-8 md:px-6 p-4">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
