import { CardDescription } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import React from "react";
import { HexColorPicker } from "react-colorful";
import { Palette } from "lucide-react";

type ColorPickerProps = {
  value: string | undefined;
  setValue: (value: string) => void;
  title: string | undefined;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  title,
  value,
  setValue,
}) => {
  return (
    <Dialog>
      <div className="w-fit">
        <DialogTrigger className="h-full flex flex-col gap-2">
          <Label>{title}</Label>
          <div className="flex items-center gap-2 border rounded-md p-2">
            {value && (
              <div
                className={`h-full aspect-square rounded-md border`}
                style={
                  { backgroundColor: value || "#FFFFFF" } as React.CSSProperties
                }
              ></div>
            )}
            <CardDescription>
              {value ? (
                value
              ) : (
                <span className="flex items-center gap-2">
                  <Palette className="text-purple-500" /> Select color{" "}
                </span>
              )}
            </CardDescription>
          </div>
        </DialogTrigger>
      </div>
      <DialogContent className="w-fit py-10 pb-6">
        <DialogTitle>{title}</DialogTitle>
        <div className="flex items-center gap-2">
          <div
            className={`h-full aspect-square rounded-md border`}
            style={
              { backgroundColor: value || "#FFFFFF" } as React.CSSProperties
            }
          ></div>
          <Input
            value={value || ""}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setValue(e.target.value);
              }
            }}
            className="flex w-[155px]"
          />
        </div>
        <HexColorPicker color={value || "#FFFFFF"} onChange={setValue} />{" "}
      </DialogContent>
    </Dialog>
  );
};

export default ColorPicker;
