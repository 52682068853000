import { Tabs, TabsContent } from "@/components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { ScrollArea } from "@/components/ui/scroll-area";
import dateFormat from "@/utils/dateFormt";
import { useEffect, useState } from "react";
import {
  getAbandonedCartList,
  sendAbandonedCartSMS,
} from "@/services/abandonedCartService";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";

export default function AbandonedCartTable() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getAbandonedCartList()
      .then((res) => {
        setData(res?.data?.cartList);
      })
      .catch((err) => console.log(err));
  }, []);

  const sendSMS = (id: any) => {
    sendAbandonedCartSMS(id)
      .then((res) => {
        res?.data?.message === "success" &&
          toast({
            duration: 3000,
            title: "SMS Sent",
            description: "Abandoned cart SMS has been sent successfully!",
          });
      })
      .catch(() => {
        toast({
          duration: 3000,
          title: "Oops! Something went wrong. Please try again.",
        });
      });
  };

  return (
    <Tabs defaultValue="all">
      <TabsContent value="all">
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="px-7">
            <CardTitle>Abandoned Cart</CardTitle>
            <CardDescription>
              Manage messaging for abandoned cart users
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <ScrollArea className="h-[390px]">
                <TableHeader>
                  <TableRow className="text-[12px]">
                    <TableHead className="hidden sm:table-cell">
                      Customer Name
                    </TableHead>
                    <TableHead>Phone Number</TableHead>
                    <TableHead>Email Id</TableHead>
                    <TableHead>Cart Items</TableHead>
                    <TableHead className="hidden md:table-cell">Date</TableHead>
                    <TableHead>Total</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.map((item: any, index: any) => (
                    <TableRow className="bg-accent" key={index}>
                      <TableCell>
                        <p className="text-xs capitalize">
                          {item?.userId?.name}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p className="text-xs capitalize">
                          {item?.userId?.phone_number}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p className="text-xs">{item?.userId?.email}</p>
                      </TableCell>
                      <TableCell>
                        <HoverCard>
                          <HoverCardTrigger asChild>
                            <div className="text-xs underline decoration-dotted decoration-gray-400 decoration-1 cursor-pointer">
                              {item?.products[0]?.productId?.name}
                              {item?.products?.length > 1 &&
                                ` + ${item?.products?.length - 1}`}
                            </div>
                          </HoverCardTrigger>
                          <HoverCardContent className="w-80">
                            <div className="flex flex-col justify-between gap-4">
                              {item?.products?.map((i: any, index: any) => {
                                return (
                                  <div key={index}>
                                    <h4 className="text-sm font-semibold">
                                      {i?.productId?.name}
                                    </h4>
                                    <p className="text-sm">
                                      ₹{i?.productId?.discount_price} x{" "}
                                      {i?.quantity} unit
                                      {i?.quantity > 1 ? "s" : ""}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </HoverCardContent>
                        </HoverCard>
                      </TableCell>

                      <TableCell className="hidden md:table-cell">
                        <p className="text-xs">{dateFormat(item?.createdAt)}</p>
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem className=" p-0">
                              <button
                                className="w-full text-left p-2"
                                onClick={() => {
                                  sendSMS(item?._id);
                                }}
                              >
                                Trigger SMS
                              </button>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ScrollArea>
            </Table>
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}
