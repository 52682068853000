import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Dot } from "lucide-react";
import { useEffect, useState } from "react";
import dateFormat from "@/utils/dateFormt";
import AddDomains from "@/components/common/AddDomains";
import { Badge } from "@/components/ui/badge";
import { getDomains } from "@/services/domainService";
import Dropdown from "../components/Dropdown";

type domainSchema = {
  url: String;
  type: String;
  createdAt: String;
  status: String;
  statusCode: Number;
  is_primary: Boolean;
};

export default function CustomDomain() {
  const [domains, setDomains] = useState<domainSchema[]>([]);

  useEffect(() => {
    getDomains().then((res) => {
      setDomains(res?.data?.domain);
    });
  }, []);
  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Custom Domains</CardTitle>
          <CardDescription>
            Manage the domains for your online store.
          </CardDescription>
        </CardHeader>
        <CardContent className="gap-2 flex flex-col">
          {domains?.map((item, index) => {
            return (
              <Card className="shadow-none" key={index}>
                <CardHeader className="p-3 flex flex-row justify-between items-center">
                  <CardTitle className="flex flex-row items-center gap-3 font-normal text-sm">
                    <div
                      className={
                        "bg-[#ccc] border-gray-200 border w-[30px] aspect-square rounded-full flex justify-center items-center"
                      }
                    >
                      <p>{index + 1}</p>
                    </div>
                    <div>
                      <div className="flex items-center gap-4">
                        <p>{item?.type === 'default' ? `${item?.url}.pinecart.in` : `https://${item?.url}`}</p>
                        {item?.is_primary && (
                          <Badge className="bg-[#4D5F47] shadow-none">
                            Primary
                          </Badge>
                        )}
                      </div>
                      <p className="text-[12px] text-gray-500 flex flex-row items-center">
                        Added on {dateFormat(item.createdAt)}
                        <Dot
                          size={30}
                          className={`${
                            item.status === "Connected"
                              ? "text-green-700"
                              : item.status === "Verification Pending"
                              ? "text-orange-500"
                              : item.status === "Verifying"
                              ? "text-yellow-500"
                              : "text-red-600"
                          }`}
                        />
                        {item.status}
                      </p>
                    </div>
                  </CardTitle>
                  <Dropdown data={item} />
                </CardHeader>
              </Card>
            );
          })}
        </CardContent>
        {domains?.length < 2 && (
          <CardFooter className="flex justify-end">
            <AddDomains />
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
