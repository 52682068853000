import {
  ChevronDown,
  Copy,
  CreditCard,
  FileDown,
  MapPin,
  Phone,
  User,
  X,
} from "lucide-react";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Separator } from "../../../components/ui/separator";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getOrderDetail,
  updateOrderDimensions,
  // updateShippingStatus
} from "@/services/orderService";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import {
  availableCouriers,
  cancelShipment,
  createShippingOrder,
  pickup,
  pickupAddress,
  shiprocketDocument,
} from "@/services/shiprocket";
import moment from "moment";

import { Badge } from "@/components/ui/badge";
import OrderItem from "../components/OrderItem";
import Courier from "../components/Courier";
import { ScrollArea } from "@/components/ui/scroll-area";
import { downloadFile } from "@/utils/downloadFile";
import { toast } from "@/components/ui/use-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import DimensionModal from "../components/order-detail/DimensionModal";

type ShiprocketState = {
  shipment_id: Number;
  order_id: Number;
  awb: String;
};

type OrderDimension = {
  length: string;
  breadth: string;
  height: string;
  weight: string;
};

export default function OrderDetailsPage() {
  const { id } = useParams();
  const [order, setOrder] = useState<any>(null);
  const [status, setStatus] = useState<string>();
  const [sheet, setSheet] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const [shiprocket, setShiprocket] = useState<ShiprocketState>();
  const [dimensionModal, setDimensionModal] = useState<boolean>(false);
  const [orderDimension, setOrderDimension] = useState<OrderDimension | null>(
    null
  );
  const [isFulfillment, setIsFulfillment] = useState<boolean>(false);

  const paymentData = [
    {
      title: "Subtotal",
      amount: order?.total,
    },
    {
      title: "Shipping Cost",
      amount: order?.shipping,
    },
    // {
    //   title: "Tax",
    //   percentage: "8",
    //   amount: (8 * order?.total) / 100,
    // },
  ];

  useEffect(() => {
    getOrderDetail(id)
      .then((res) => {
        const response = res.data.data;
        setOrder(response);

        if (response?.shiprocket) {
          setShiprocket({
            order_id: response.shiprocket.order_id,
            shipment_id: response.shiprocket.shipment_id,
            awb: response.shiprocket.awb,
          });
        }
        setOrderDimension(response?.dimension);
        setStatus(response?.status);

        setIsFulfillment(
          response.type === "Digital"
            ? response.paymentInfo.status === "captured"
            : true
        );
      })
      .catch((err) => console.log(err));
  }, []);

  function createOrder(courier: any) {
    const order_items = order?.products?.map((item: any, index: number) => {
      let productName = item?.name;

      if (item?.variant) {
        productName = `${item?.name} (${item?.variant?.option_1_value}${
          item?.variant?.option_2_value
            ? `/ ${item?.variant?.option_2_value}`
            : ""
        })`;
      }

      let price = item?.variant ? item?.variant?.price : item?.price;

      let discount_price = item?.variant
        ? item?.variant?.discount_price
        : item?.discount_price;

      return {
        name: productName,
        sku: `10${index}`,
        units: item?.quantity,
        selling_price: price,
        discount: price - discount_price,
        tax: "",
        hsn: item?.hsn,
      };
    });

    const obj = {
      order_id: order.razorpay_order_id,
      order_date: order.createdAt,
      pick_location: "Home",
      shipping_is_billing: true,
      billing_customer_name: order.user.name,
      billing_last_name: "",
      billing_address: order.user.address.address1,
      billing_address_2: order.user.address.address2,
      billing_city: order.user.address.city,
      billing_pincode: order.user.address.pincode,
      billing_country: "India",
      billing_state: order.user.address.stats,
      billing_email: "",
      billing_phone: order.user.phone_number,
      order_items: order_items,
      payment_method: "Prepaid",
      shipping_charges: 0,
      giftwrap_charges: 0,
      transaction_charges: 0,
      total_discount: 0,
      sub_total: order?.total,
      length: 25,
      breadth: 10,
      height: 10,
      weight: 0.5,
    };

    createShippingOrder({
      order: obj,
      shipping: {
        courier_company_id: courier?.courier_company_id,
      },
    })
      .then((res) => {
        if (res.data.data.status_code == 350) {
          toast({
            duration: 3000,
            title: "Insufficient funds",
            description: res?.data?.data?.message,
          });
        }

        if (res?.data?.data?.hasOwnProperty("shipment_id")) {
          const { order_id, shipment_id, status, awb } = res.data?.data;

          setShiprocket({
            order_id,
            shipment_id,
            awb,
          });
          setStatus(status);
          toast({
            duration: 3000,
            title: "Shipment Created",
            description: res?.data?.message,
          });

          setSheet(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          toast({
            duration: 3000,
            title: "Shipment Error",
            description: err?.response?.data?.message,
          });
        }
        console.log(err);
      });
  }

  async function courierList(weight: string) {
    try {
      const {
        data: {
          data: {
            data: { shipping_address },
          },
        },
      } = await pickupAddress();

      if (!shipping_address.length) {
        return toast({
          duration: 3000,
          title: "Add an address",
          description: "Add pickup address in shiprocket",
        });
      }

      const { pin_code } = shipping_address.find(
        (el: any) => el.is_primary_location == 1
      );
      const payload = {
        pickup_postcode: pin_code,
        delivery_postcode: order.user.address.pincode,
        cod: order.payment_method === "prepaid" ? 0 : 1,
        weight: +weight,
      };

      const {
        data: {
          data: {
            data: { available_courier_companies },
          },
        },
      } = await availableCouriers(payload);

      if (available_courier_companies.length) {
        setSheet(true);
        setCouriers(available_courier_companies);
      }
    } catch (error) {
      console.log(">>>>>>>>> courier-list", error);
    }
  }

  function handlePickup(shipment_id: number) {
    pickup({ shipment_id, _id: id })
      .then(() => {
        toast({
          duration: 3000,
          title: "Pickup Requested",
          description: "Shipment is ready for Pickup",
        });
        setStatus("ready_for_pickup");
      })
      .catch((err) => {
        console.log(err);
        toast({
          duration: 3000,
          title: "Error",
          description: err?.response?.data?.message,
        });
      });
  }

  function handlePrimary() {
    if (status === "pending") {
      if (orderDimension) {
        courierList(orderDimension?.weight);
      } else {
        setDimensionModal(true);
      }
    } else {
      handlePickup(shiprocket?.shipment_id as number);
    }
  }

  function handleCancelShipment() {
    if (shiprocket)
      cancelShipment({
        awb: shiprocket?.awb,
        _id: id,
      })
        .then((res) => {
          toast({
            duration: 3000,
            title: "Shipment Cancelled",
            description: res?.data?.message,
          });
          setStatus(res?.data?.status);
        })
        .catch((err) => console.log(err));
  }

  function handleDocument(type: "label" | "invoice" | "manifest") {
    shiprocketDocument(type, {
      shipment_id: shiprocket?.shipment_id,
      order_id: shiprocket?.order_id,
    })
      .then(async (res) => {
        let url = "";
        if (type == "label") {
          url = res.data?.response?.label_url;
        } else if (type == "invoice") {
          url = res.data?.response?.invoice_url;
        } else if (type == "manifest") {
          url = res.data?.response?.manifest_url;
        }
        if (!url) {
          toast({
            duration: 3000,
            title: "File not Found",
            description: res?.data?.response?.response,
          });
          return;
        }
        await downloadFile(type, url);
      })
      .catch((err) => console.log(err));
  }

  function handleDimension(value: OrderDimension) {
    let payload = {
      id,
      ...value,
    };

    updateOrderDimensions(payload)
      .then((res) => {
        console.log(">>>> update dimension", res.data);
        setOrderDimension(value);
        courierList(value?.weight);
        setDimensionModal(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Drawer direction="right" open={sheet} onOpenChange={setSheet}>
        <DrawerContent className="max-h-screen top-0 right-0 left-auto mt-0 md:w-[1200px] rounded-none border-none ring-0 outline-none p-4">
          <DrawerHeader className="flex justify-between">
            <div className="flex flex-col gap-1">
              <DrawerTitle>Select Shipping Partner</DrawerTitle>
            </div>
            <DrawerClose>
              <Button size="sm" variant="ghost">
                <X color="#666666" />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <ScrollArea className="p-2">
            <Courier data={couriers} createOrder={createOrder} />
          </ScrollArea>
        </DrawerContent>
      </Drawer>

      {dimensionModal && (
        <DimensionModal
          handleSubmit={handleDimension}
          modal={dimensionModal}
          setModal={setDimensionModal}
        />
      )}

      <div className="flex flex-row justify-between items-center mb-4">
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center gap-2 text-lg">
            Order #{order?._id}
            <Button
              size="icon"
              variant="outline"
              className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
            >
              <Copy className="h-3 w-3" />
              <span className="sr-only">Copy Order ID</span>
            </Button>
          </CardTitle>
          <CardDescription className="flex gap-2 items-center">
            <p>Date: {moment(order?.createdAt).format("DD/MM/YYYY")}</p>
            {isFulfillment ? (
              <>
                {order?.type === "Digital" ? (
                  <Badge
                    variant="secondary"
                    className="text-[#4D5F47] bg-green-100"
                  >
                    Paid
                  </Badge>
                ) : (
                  <Badge
                    variant="secondary"
                    className="text-orange-500 bg-orange-100"
                  >
                    COD
                  </Badge>
                )}
              </>
            ) : (
              <Badge
                variant="secondary"
                className="text-orange-500 bg-orange-100"
              >
                Failed
              </Badge>
            )}

            {isFulfillment && (
              <Badge
                variant="secondary"
                className="text-orange-500 bg-orange-100 capitalize"
              >
                {status === "pending" ? "Pending" : "Fulfilled"}
              </Badge>
            )}

            {/* {status ? (
              <Badge
                variant="secondary"
                className="text-green-500 bg-green-100"
              >
                Fulfilled
              </Badge>
            ) : (
              <Badge
                variant="secondary"
                className="text-orange-500 bg-orange-100"
              >
                Unfulfilled
              </Badge>
            )} */}
          </CardDescription>
        </div>

        <div className="flex flex-row items-start">
          <div className="ml-auto flex items-center gap-1">
            {isFulfillment &&
              (status == "pending" || status == "ready_to_ship") && (
                <Button
                  size="sm"
                  onClick={handlePrimary}
                  className="bg-primary"
                >
                  {status == "pending" ? "Fulfill Order" : "Request Pickup"}
                </Button>
              )}
            {isFulfillment && status !== "pending" && (
              <Link
                to={`https://shiprocket.co/tracking/${order?.shiprocket?.awb}`}
                target="_blank"
              >
                <Button size="sm" className="bg-primary">
                  Track Order
                </Button>
              </Link>
            )}

            {status !== "pending" && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm" className="gap-2">
                    More actions <ChevronDown size={14} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => handleDocument("invoice")}>
                    Download Invoice
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleDocument("label")}>
                    Print Label
                  </DropdownMenuItem>
                  {status === "ready_for_pickup" && (
                    <DropdownMenuItem
                      onClick={() => handleDocument("manifest")}
                    >
                      Print Manifest
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem
                    className="text-red-600"
                    onClick={handleCancelShipment}
                  >
                    Cancel Shipment
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>
      <div className="flex-row gap-2 grid grid-cols-5">
        <Card className="overflow-hidden col-span-3 shadow-none h-fit">
          <CardContent className="p-6 text-sm">
            <div className="grid gap-3">
              <div className="flex flex-col gap-6">
                <div className="font-semibold">Order Items</div>
                {order?.products?.map((item: any, index: number) => {
                  return <OrderItem data={item} key={index} />;
                })}
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="col-span-2 flex flex-col gap-2">
          <Card className="shadow-none overflow-hidden">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                Payment
                {status !== "pending" && (
                  <Button
                    size="sm"
                    className="gap-2 border"
                    variant="secondary"
                    onClick={() => handleDocument("invoice")}
                  >
                    <FileDown size={14} /> Download Invoice
                  </Button>
                )}
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 pt-0">
              <div className="bg-gray-50 w-full gap-2 flex flex-col p-4 rounded-md text-xs">
                {paymentData?.map((item, index) => {
                  return (
                    <div key={index} className="flex justify-between">
                      <p>
                        {item?.title}{" "}
                        {/* {item?.percentage && `(${item?.percentage}%)`} */}
                      </p>
                      <p>₹{item?.amount}</p>
                    </div>
                  );
                })}
                <Separator />
                <div className="flex justify-between font-semibold">
                  <p>Total</p>
                  <p>₹{paymentData.reduce((n, { amount }) => n + amount, 0)}</p>
                </div>
              </div>
            </CardContent>
            {order?.paymentInfo && (
              <CardFooter className="bg-gray-50 flex items-center px-6 py-2">
                <div className="flex items-center justify-between text-xs w-full">
                  {order?.paymentInfo?.status === "captured" ? (
                    <p className="flex items-center gap-1">
                      <CreditCard size={14} />
                      Paid via {order?.paymentInfo?.method}
                    </p>
                  ) : (
                    "Error"
                  )}
                </div>
              </CardFooter>
            )}
          </Card>
          <Card className="shadow-none">
            <CardHeader className="pb-4">
              <CardTitle className="flex justify-between items-center">
                Contact Information
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 pt-0">
              <div className="flex flex-col gap-2">
                <p className="text-xs flex gap-2 items-center capitalize">
                  <User size={14} /> {order?.user_id?.name}
                </p>
                <p className="text-xs flex gap-2 items-center">
                  <Phone size={14} /> {order?.user_id?.phone_number}
                </p>
              </div>
            </CardContent>
          </Card>
          <Card className="shadow-none">
            <CardHeader className="pb-4">
              <CardTitle className="flex justify-between items-center">
                Shipping Address
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 pt-0">
              <div className="flex flex-col gap-2">
                <p className="text-xs flex gap-2 items-center">
                  <User size={14} /> {order?.user?.name}
                </p>
                <p className="text-xs flex gap-2 items-center">
                  <Phone size={14} /> {order?.user?.phone_number}
                </p>
                <p className="text-xs flex gap-2 items-start">
                  <MapPin size={14} />
                  {order?.user?.address?.address1},{" "}
                  {order?.user?.address?.address2}, {order?.user?.address?.city}{" "}
                  {order?.user?.address?.state}, {order?.user?.address?.pincode}
                </p>
              </div>
            </CardContent>
          </Card>
          <Card className="shadow-none">
            <CardHeader className="pb-4">
              <CardTitle className="flex justify-between items-center">
                Billing Address
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 pt-0">
              <div className="flex flex-col gap-2">
                <p className="text-xs flex gap-2 items-center">
                  <User size={14} /> {order?.user?.name}
                </p>
                <p className="text-xs flex gap-2 items-center">
                  <Phone size={14} /> {order?.user?.phone_number}
                </p>
                <p className="text-xs flex gap-2 items-start">
                  <MapPin size={14} />
                  {order?.user?.address?.address1},{" "}
                  {order?.user?.address?.address2}, {order?.user?.address?.city}{" "}
                  {order?.user?.address?.state}, {order?.user?.address?.pincode}
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
