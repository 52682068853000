import axios from "axios";

const axiosInterceptor = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const requestInterceptor = (config: any) => {
  if (localStorage.getItem("token")) {
    // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "ngrok-skip-browser-warning": "true",
      "x-access-token": localStorage.getItem("access_token"),
    };
  }
  return config;
};

const responseInterceptor = (response: any) => {
  return response;
};

const responseErrorInterceptor = (error: any) => {
  console.error("Error:", error);
  return Promise.reject(error);
};

axiosInterceptor.interceptors.request.use(requestInterceptor);
axiosInterceptor.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

export default axiosInterceptor;
