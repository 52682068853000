import { z } from "zod";

export const createStoreFormSchema = z.object({
  store_name: z.string().min(2).max(20),
  store_type: z.enum([
    "books",
    "home-decor",
    "furniture",
    "electronics",
    "fashion",
    "beauty",
    "medical",
    "sports",
    "service",
    "cafe",
    "restaurant",
    "dairy",
    "grocery",
    "other",
  ]),
  store_description: z.string().min(0).max(100).optional(),
  domain: z.string(),
  contact_information: z.object({
    email_address: z.string().email("Invalid email format"),
    contact_number: z
      .string()
      .min(10)
      .max(10)
      .regex(/^[6-9][0-9]{9}$/gm, "Phone must contain 10 digits"),
    whatsapp_number: z
      .string()
      // .regex(/^[6-9][0-9]{9}$/gm, "Phone must contain 10 digits")
      .optional(),
  }),
  store_address: z.object({
    address_one: z.string().min(2).max(40),
    address_two: z.string().min(0).max(40).optional(),
    country: z.string().min(0).max(20).optional(),
    state: z.string().min(2).max(20),
    city: z.string().min(2).max(20),
    pin_code: z
      .string()
      .min(6)
      .max(6)
      .regex(/^[1-9][0-9]{5}$/gm, "Pincode must contain 6 digits"),
  }),
  store_banner: z.array(z.string()).optional(),
  logoUrl: z.string().optional(),
  social_media: z
    .object({
      instagram: z.string().url("Invalid URL").optional(),
      facebook: z.string().url("Invalid URL").optional(),
      youtube: z.string().url("Invalid URL").optional(),
    })
    .optional(),
  business_details: z
    .object({
      business_name: z.string().optional(),
      business_type: z
        .enum(["Sole proprietorship", "Corporation", "other"])
        .optional(),
      tax_id: z.string().optional(),
      industry: z.enum(["Retail", "Food", "other"]).optional(),
    })
    .optional(),
  currency: z.enum(["INR", "USD"]).optional(),
  return_policy_url: z.string().optional(),
  privacy_policy_url: z.string().optional(),
  terms_of_service: z.boolean().optional(),
});

export const StoreSettingsFormSchema = z.object({
  store_name: z.string().min(2).max(20),
  store_type: z.enum([
    "books",
    "home-decor",
    "furniture",
    "electronics",
    "fashion",
    "beauty",
    "medical",
    "sports",
    "service",
    "cafe",
    "restaurant",
    "dairy",
    "grocery",
    "other",
  ]),
  store_description: z.string().min(0).max(600).optional(),
  contact_information: z.object({
    email_address: z.string().email("Invalid email format"),
    contact_number: z
      .string()
      .min(10)
      .max(10)
      .regex(/^[6-9][0-9]{9}$/gm, "Phone must contain 10 digits"),
    whatsapp_number: z
      .string()
      // .regex(/^[6-9][0-9]{9}$/gm, "Phone must contain 10 digits")
      .optional(),
  }),
  store_address: z.object({
    address_one: z.string().min(2).max(40),
    address_two: z.string().min(0).max(40).optional(),
    country: z.string().min(0).max(20).optional(),
    state: z.string().min(2).max(20),
    city: z.string().min(2).max(20),
    pin_code: z
      .string()
      .min(6)
      .max(6)
      .regex(/^[1-9][0-9]{5}$/gm, "Pincode must contain 6 digits"),
  }),
  store_banner: z.array(z.string()).optional(),
  logoUrl: z.string().optional(),
  social_media: z
    .object({
      instagram: z.string().url("Invalid URL").optional(),
      facebook: z.string().url("Invalid URL").optional(),
      youtube: z.string().url("Invalid URL").optional(),
    })
    .optional(),
  business_details: z
    .object({
      business_name: z.string().optional(),
      business_type: z
        .enum(["Sole proprietorship", "Corporation", "other"])
        .optional(),
      tax_id: z.string().optional(),
      industry: z.enum(["Retail", "Food", "other"]).optional(),
    })
    .optional(),
  currency: z.enum(["INR", "USD"]).optional(),
  return_policy_url: z.string().optional(),
  privacy_policy_url: z.string().optional(),
  terms_of_service: z.boolean().optional(),
});
