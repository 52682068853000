import { Button } from "@/components/ui/button";
import { File } from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { uploadProductCSV } from "@/services/productService";

function ProductImport() {
  const [modal, setModal] = useState(false);

  const [file, setFile] = useState<FileList | null>(null);

  function uploadCSV() {
    if (!file || file.length === 0) {
      return false;
    }

    const formData = new FormData();
    formData.append("file", file[0]);

    uploadProductCSV(formData)
      .then(() => {
        setModal(false);
        setFile(null);
        location.reload();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {modal && (
        <Dialog onOpenChange={setModal} open={modal}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Are you absolutely sure?</DialogTitle>
              <DialogDescription>
                <a href="/assets/product_sample.csv" download>
                  Download CSV
                </a>
              </DialogDescription>
              <input
                type="file"
                accept=".csv"
                onChange={(e) => setFile(e.target.files)}
              />
            </DialogHeader>
            <DialogFooter>
              <Button type="button" disabled={!file} onClick={uploadCSV}>
                Upload
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      <Button
        size="sm"
        variant="outline"
        className="h-7 gap-1"
        type="button"
        onClick={() => setModal(true)}
      >
        <File className="h-3.5 w-3.5" />
        <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
          Import
        </span>
      </Button>
    </>
  );
}

export default ProductImport;
