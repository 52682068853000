import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function createStore(data: object) {
  return axiosInterceptor.post("/store", data);
}

export function getStores() {
  return axiosInterceptor.get("/store");
}

export function getStoreDetails() {
  return axiosInterceptor.get(`/store/detail`);
}

export function verifyStoreDomainUnique(domain: string) {
  return axiosInterceptor.get(`/store/verify-domain/${domain}`);
}

export function updateStoreDetails(data: object) {
  return axiosInterceptor.put(`/store`, data);
}

export function deleteStore() {
  return axiosInterceptor.delete(`/store`);
}

export function getStoreAccessToken(id: string, type?: string) {
  return axiosInterceptor.get(`/store/access-token/${id}`,{
    params:{
      type
    }
  });
}
