import { useState } from "react";
import { Button } from "@/components/ui/button";
import MediaBoxUpload from "../media/MediaBoxUpload";
import { Pencil, X } from "lucide-react";
import { file } from "@/utils/Interface";

const fileType = {
  image: "image/png, image/jpeg, image/jpg, image/webp",
  video: "video/mp4",
};

type props = {
  maxLength: number;
  accept: "image" | "video";
  files: file[];
  setFiles: React.Dispatch<React.SetStateAction<file[]>>;
};


function UploadFile({ maxLength, files, setFiles, accept }: props) {
  const [openMedia, setOpenMedia] = useState(false);

  function removeImage(index: number) {
    setFiles((prev) => prev.filter((_, i) => i != index));
  }

  return (
    <>
      {openMedia && (
        <MediaBoxUpload
          model={openMedia}
          onChangeModel={setOpenMedia}
          inputType={fileType[accept]}
          maxImage={maxLength}
          setSelected={setFiles}
          selected={files}
        />
      )}
      <div className="flex gap-2 flex-wrap">
        {files?.map((item, index) => (
          <div
            className=" w-[100px] h-[100px] aspect-square relative group rounded-md"
            key={index}
          >
            <div className="w-full h-full bg-slate-400/[0.6] absolute hidden group-hover:grid place-items-center z-50">
              <div className="absolute right-[-7px] top-[-7px] flex flex-row gap-1">
                <span
                  onClick={() => setOpenMedia(true)}
                  className="bg-black rounded-full p-1 border border-white"
                >
                  <Pencil color="white" size={15} className="" />
                </span>
                <span
                  onClick={() => removeImage(index)}
                  className="bg-black rounded-full p-1 border border-white"
                >
                  <X color="white" size={15} className="" />
                </span>
              </div>
            </div>
            {accept == "video" ? (
              <video src={item.url} controls={true} />
            ) : (
              <img
                src={item.url}
                className=" w-[100px] h-[100px] object-cover rounded-md"
              />
            )}
          </div>
        ))}

        {files.length < maxLength && (
          <Button
            type="button"
            className=" bg-slate-300 shadow-sm w-[100px] h-[100px] aspect-square"
            onClick={() => setOpenMedia(true)}
          >
            <h1>upload</h1>
          </Button>
        )}
      </div>
    </>
  );
}

export default UploadFile;
