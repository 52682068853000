import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Badge } from "../../../components/ui/badge";
import { Settings, EllipsisVertical, CircleCheck, CircleX } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  activatePlugin,
  deactivatePlugin,
  installPlugin,
  uninstallPlugin,
  updateCredential,
} from "@/services/credentialService";

export default function PowerupCard({ data }: any) {

  const [model, setModel] = useState(false);
  const [isInstalled, setIsInstalled] = useState(data?.is_installed);
  const [isActive, setIsActive] = useState(data?.is_active);

  function handleInstallPlugin() {
    installPlugin({ plugin_key: data.key })
      .then(() => {
        setIsInstalled(true);
      })
      .catch((err) => console.log(err));
  }

  function handleDeactivate() {
    deactivatePlugin({ type: data.key })
      .then(() => {
        setIsActive(false);
      })
      .catch((err) => console.log(err));
  }

  function handleUninstall() {
    uninstallPlugin({ type: data.key })
      .then(() => {
        setIsInstalled(false);
      })
      .catch((err) => console.log(err));
  }

  function handleActivate() {
    activatePlugin({ type: data.key })
      .then(() => {
        setIsActive(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {model && (
        <InputModal
          setModel={setModel}
          model={model}
          formFields={data.formFields}
          type={data.key}
          script={data?.script}
          // isInstall={data?.is_installed}
        />
      )}

      <Card>
        <CardHeader className="pb-2">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-4 items-center">
              <div className="p-2 h-[80px] w-[80px] rounded-md border border-gray-300">
                <img
                  src={data?.icon}
                  className="h-full w-full object-contain rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <CardTitle>{data?.name}</CardTitle>
              </div>
            </div>
            {isInstalled && (
              <div
                className={`w-[10px] h-[10px] ${
                  isActive ? "bg-green-600" : "bg-gray-400"
                } rounded-full`}
              ></div>
            )}
          </div>
        </CardHeader>
        <CardContent>
          <Badge variant="outline" className="w-fit mb-2">
            {data?.category?.name}
          </Badge>
          <CardDescription className="mb-4">
            {data?.description}
          </CardDescription>
          <div className="flex flex-row justify-between datas-center">
            <Button
              disabled={isInstalled}
              variant={isInstalled ? "secondary" : "default"}
              size="sm"
              onClick={handleInstallPlugin}
            >
              {isInstalled ? "Installed" : "Install"}
            </Button>
            <DropdownMenu>
              {isInstalled && (
                <DropdownMenuTrigger asChild>
                  <div className="p-2 pr-0 cursor-pointer">
                    <EllipsisVertical color="#3b3b3b" size={20} />
                  </div>
                </DropdownMenuTrigger>
              )}
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Configurations</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => setModel(true)}>
                    <Settings className="mr-2 h-4 w-4" />
                    <span>Settings</span>
                  </DropdownMenuItem>
                  {isActive ? (
                    <DropdownMenuItem onClick={handleDeactivate}>
                      <CircleX className="mr-2 h-4 w-4" />
                      <span>Deactivate</span>
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem
                      onClick={handleActivate}
                      disabled={!data?.data}
                    >
                      <CircleCheck className="mr-2 h-4 w-4" />
                      <span>Activate</span>
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem onClick={handleUninstall}>
                    <Settings className="mr-2 h-4 w-4" />
                    <span>Uninstall</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

type props = {
  formFields: { name: "key"; type: "text" }[];
  setModel: any;
  model: boolean;
  type: string;
  script?: (id: string) => string;
};

function InputModal({ model, setModel, formFields, type, script }: props) {
  function handleSubmit(e: any) {
    e.preventDefault();
    const formData = new FormData(e.target);

    let payload = Object.fromEntries(formData.entries());

    console.log(payload)

    console.log(script)

    let jsScript = script && script(payload?.id.toString());

    updateCredential({ type, payload, script: jsScript })
      .then(() => {
        // setIsActive((prev: boolean) => !prev);
        setModel(false);
        location.reload();
      })
      .catch((err) => console.log(err));
  }

  return (
    <Dialog onOpenChange={setModel} open={model}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Credential</DialogTitle>
        </DialogHeader>
        <form className="grid gap-4 py-4" onSubmit={handleSubmit}>
          {formFields.map((field, index) => (
            <div className="grid grid-cols-4 items-center gap-4" key={index}>
              <Label className="text-right capitalize">{field.name}</Label>
              <Input
                name={field.name}
                type={field.type}
                className="col-span-3"
                required={true}
              />
            </div>
          ))}
          <DialogFooter>
            <Button type="submit">Save changes</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
