import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React, { useState } from "react";
import { CirclePlus, Pencil, Plus, Settings2, Trash2 } from "lucide-react";
import { Label } from "@/components/ui/label";
import { variantInterface, variantOption } from "./interface";
import { VariantsTable } from "./VariantsTable";
import { Badge } from "@/components/ui/badge";

type props = {
  variants: variantInterface[];
  setVariants: React.Dispatch<React.SetStateAction<variantInterface[]>>;
  price: number;
  discount_price: number;
  options: variantOption[];
  setOptions: React.Dispatch<React.SetStateAction<variantOption[]>>;
};

function ProductVariants({
  variants,
  setVariants,
  price,
  discount_price,
  options,
  setOptions,
}: props) {
  // add new variant
  function createNewOption() {
    setOptions((prev) => [
      ...prev,
      {
        name: "",
        values: [""],
        isSave: false,
      },
    ]);
  }

  // edit options button
  function handleOptionEdit(index: number) {
    setOptions((prev) =>
      prev.map((item, i) => {
        if (i == index) {
          return {
            ...item,
            isSave: false,
          };
        }
        return item;
      })
    );
  }

  // generate variants table
  function generateVariantTable() {
    const variantsObject = generateCombinations(options, price, discount_price);

    const updatedVariants = updateSourceWithTarget(variants, variantsObject);

    setVariants(structuredClone(updatedVariants));
  }

  // ! test
  function afterDeletion(opt: any) {
    const variantsObject = generateCombinations(opt, price, discount_price);

    const check = variantsObject.some((el) => Object.entries(el).length);

    if (!check) {
      setVariants(structuredClone([]));
      return 
    }

    const updatedVariants = updateSourceWithTarget(variants, variantsObject);

    setVariants(structuredClone(updatedVariants));
  }

  return (
    <div>
      {Boolean(!options.length) && (
        <Button type="button" size="sm" onClick={createNewOption} className="bg-primary">
          Add Options
        </Button>
      )}

      {Boolean(options.length) && (
        <div className="flex flex-col gap-4">
          {options.map((item, index) => (
            <div key={index} className="border rounded-lg p-4">
              {item?.isSave ? (
                <div>
                  <div className=" flex flex-col gap-2">
                    <div className="flex flex-row justify-between w-full items-start">
                      <h2 className=" text-l font-bold capitalize">
                        {item?.name}
                      </h2>
                      <Button
                        type="button"
                        size="sm"
                        variant="outline"
                        className="font-semibold gap-2"
                        onClick={() => handleOptionEdit(index)}
                      >
                        Edit
                        <Pencil size={12} />
                      </Button>
                    </div>
                    <div className=" flex gap-2">
                      {item.values.map((name, index) => (
                        <Badge
                          key={index}
                          variant="outline"
                          className="capitalize"
                        >
                          {name}
                        </Badge>
                        // <span
                        //   className=" rounded-lg bg-gray-400 black p-2 text-xs"
                        //   key={index}
                        // >
                        //   {name}
                        // </span>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment key={index}>
                  <OptionCard
                    pIndex={index}
                    // item={JSON.parse(JSON.stringify(item))}
                    item={structuredClone(item)}
                    options={options}
                    setOptions={setOptions}
                    afterDeletion={afterDeletion}
                  />
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="flex flex-row justify-between">
        {options.length > 0 && options.length < 2 && (
          <Button
            type="button"
            onClick={createNewOption}
            size="sm"
            className=" mt-4 font-semibold gap-2 bg-primary"
          >
            Add another Option
            <Plus size={16} />
          </Button>
        )}
        {options.length ? (
          <Button
            className="mt-4 self-end font-semibold gap-2 bg-primary"
            type="button"
            size="sm"
            onClick={generateVariantTable}
          >
            Generate Table
            <Settings2 size={16} />
          </Button>
        ) : null}
      </div>

      {/* -------------------- variants combination --------------------- */}

      {variants?.length ? (
        <VariantsTable
          options={options}
          variants={variants}
          setVariants={setVariants}
        />
      ) : null}
    </div>
  );
}

export default ProductVariants;

function OptionCard({ item, options, setOptions, pIndex, afterDeletion }: any) {
  const [optionData, setOptionData] = useState<optionCard>(item);

  // variant option name
  function changeOptionValue(value: string, index: number) {
    optionData.values[index] = value;
    setOptionData({ ...optionData });
  }

  //  add a new empty variant option
  function addNewOptionValue() {
    const index = optionData.values.length - 1;

    if (optionData.values[index] == "") {
      return false;
    }

    optionData.values.push("");
    setOptionData({ ...optionData });
  }

  // delete variant option
  function deleteOptionValue(index: number) {
    setOptionData((prev) => ({
      ...prev,
      values: prev.values.filter((_, i) => i != index),
    }));
  }

  // variant save button
  function optionValueSave() {
    const optionCopy = {
      ...optionData,
      values: optionData.values.filter((el) => el),
      isSave: true,
    };

    setOptionData(optionCopy);

    setOptions((prev: optionCard[]) =>
      prev.map((option, index) => (index === pIndex ? optionCopy : option))
    );
  }

  // delete variant
  function deleteVariant() {
    const optionUpdated = options;
    optionUpdated.splice(pIndex, 1);
    setOptions([...optionUpdated]);
    afterDeletion(optionUpdated);
  }

  return (
    <div className="flex flex-col gap-6">
      <div className=" flex justify-between">
        <div>
          <Select
            onValueChange={(value) => setOptionData({ ...item, name: value })}
            value={optionData?.name}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select Option" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value="size"
                disabled={options?.some(({ name }: any) => name == "size")}
              >
                Size
              </SelectItem>
              <SelectItem
                value="color"
                disabled={options?.some(({ name }: any) => name == "color")}
              >
                Color
              </SelectItem>
              <SelectItem
                value="quantity"
                disabled={options?.some(({ name }: any) => name == "quantity")}
              >
                Quantity
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* {!validateInput(index) && (
        <Button
          type="button"
          variant="ghost"
          onClick={() => handleCloseOption(index)}
        >
          <X />
        </Button>
      )} */}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Label>Values</Label>
          <CirclePlus
            size={18}
            className=" self-end"
            onClick={addNewOptionValue}
          />
        </div>
        <div className="flex flex-col">
          {optionData.values.map((value, idx) => (
            <div className=" flex items-center gap-4 mb-2" key={idx}>
              <Input
                autoFocus
                placeholder="Add Value"
                onChange={(e) => {
                  if (optionData.values.includes(e.target.value)) {
                    return false;
                  }
                  changeOptionValue(e.target.value, idx);
                }}
                onKeyDown={({ key }) => key == "Enter" && addNewOptionValue()}
                value={value}
              />
              {optionData.values.length > 1 && (
                <Trash2 size={18} onClick={() => deleteOptionValue(idx)} />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className=" flex justify-between items-center">
        <Button
          type="button"
          size="sm"
          className="font-semibold"
          variant="destructive"
          onClick={deleteVariant}
        >
          Delete option
        </Button>

        <Button
          type="button"
          // disabled={validateInput(index)}
          onClick={optionValueSave}
          size="sm"
          className="font-semibold bg-primary"
        >
          Save changes
        </Button>
      </div>
    </div>
  );
}

function generateCombinations(
  options: optionCard[],
  price: number,
  discount_price: number
) {
  const result: any[] = [];

  function combine(prefix: any, index: number) {
    if (index === options.length) {
      result.push(prefix);
      return;
    }

    const option = options[index];
    for (const value of option.values) {
      const newPrefix = { ...prefix };
      newPrefix[`option_${index + 1}_name`] = option.name;
      newPrefix[`option_${index + 1}_value`] = value;
      newPrefix["sku"] = "";
      newPrefix["price"] = price;
      newPrefix["discount_price"] = discount_price;
      newPrefix["inventory"] = 0;
      newPrefix["continue_selling"] = false;
      newPrefix["variant_image"] = "";
      newPrefix["variant_image"] = "";
      newPrefix["weight"] = "";
      newPrefix["dimensions"] = {
        length: "",
        breadth: "",
        height: "",
      };

      combine(newPrefix, index + 1);
    }
  }

  combine({}, 0);
  return result;
}

type optionCard = {
  isSave: boolean;
  name: string;
  values: string[];
};

function updateSourceWithTarget(source: any, target: any) {
  const updatedVariants: any[] = [];

  target.forEach((item: any) => {
    const select = source.find(
      (el: any) =>
        item?.option_1_name === el?.option_1_name &&
        item?.option_1_value === el?.option_1_value &&
        item?.option_2_name === el?.option_2_name &&
        item?.option_2_value === el?.option_2_value
    );

    if (select) {
      updatedVariants.push(select);
    } else {
      updatedVariants.push(item);
    }
  });

  return updatedVariants;
}
