import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getOrderList(page:number) {
  return axiosInterceptor.get(`/order`,{
    params:{
      page
    }
  });
}

export function getOrderDetail(id: string | undefined) {
  return axiosInterceptor.get(`/order/${id}`);
}

export function getOrderListByCustomer(id: string | undefined) {
  return axiosInterceptor.get(`/order/customer/${id}`);
}

// total order count
export function getOrderCount() {
  return axiosInterceptor.get(`/order/count`);
}

export function updateShippingStatus(payload: {
  id: string | undefined;
  type: string;
}) {
  return axiosInterceptor.put(`/order`, payload);
}

export function updateOrderDimensions(payload: {
  id: string|undefined;
  length: string;
  breadth: string;
  height: string;
  weight: string;
}) {
  return axiosInterceptor.put(`/order/dimensions`, payload);
}
