"use client";
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from "recharts";

import { ChartContainer } from "@/components/ui/chart";

export function CourierRating({data}: any) {
    const chartData = [
      { browser: "safari", visitors: data?.rating, fill: data?.rating > 4 ? "#4D5F47" : "red"},
    ];
  return (
    <ChartContainer
      config={{}}
      className="mx-auto aspect-square max-h-[40px]"
    >
      <RadialBarChart
        data={chartData}
        startAngle={90}
        endAngle={-270*data?.rating/5}
        innerRadius={18}
        outerRadius={28}
      >
        <PolarGrid
          gridType="circle"
          radialLines={false}
          stroke="none"
          className="first:fill-muted last:fill-background"
          polarRadius={[86, 74]}
        />
        <RadialBar dataKey="visitors" background cornerRadius={10} />
        <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
          <Label
            content={({ viewBox }) => {
              if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-foreground font-semibold"
                    >
                      {data.rating}
                    </tspan>
                  </text>
                );
              }
            }}
          />
        </PolarRadiusAxis>
      </RadialBarChart>
    </ChartContainer>
  );
}
