import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function storeConfig() {
  return axiosInterceptor.get("/customise");
}

export function createStoreConfig(data: any) {
  return axiosInterceptor.post("/customise", data);
}

export function updateStoreConfig(data: any) {
  return axiosInterceptor.put("/customise", data);
}
