import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import {
  createStoreConfig,
  storeConfig,
  updateStoreConfig,
} from "@/services/configService";
import { getMenuList } from "@/services/menuService";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ColorPicker from "../../ColorPicker";
import StoreCustomisation from "./BannerSection";
import UploadFile from "@/components/media/UploadFile";
import { file } from "@/utils/Interface";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

type menuItem = {
  _id: string;
  name: string;
};
interface Banner {
  desktop: file | [];
  mobile: file | [];
}

function Customise() {
  const [menulist, setMenuList] = useState<menuItem[]>([]);
  const [formData, setFormData] = useState(null);

  const [imageUrl, setImageUrl] = useState<file[]>([]);
  const [faqBannerImage, setFaqBannerImage] = useState<file[]>([]);
  const [popupImage, setPopupImage] = useState<file[]>([]);
  const [footerImage, setFooterImage] = useState<file[]>([]);
  const [highlightImages, setHighlightImages] = useState<file[]>([]);
  const [footerBackgroundColor, setFooterBackgroundColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [banners, setBanners] = useState<Banner[]>([]);
  const [bannerVideo, setBannerVideo] = useState<file[]>([]);

  const { control, handleSubmit, setValue, register } = useForm({
    defaultValues: {
      feature: {
        category: "none",
        product: "none",
      },
      announcement: {
        title: "",
        is_visible: false,
        link: {
          title: "",
          route: "",
        },
      },
      header: {
        menu: "",
      },
      sub_header: {
        is_visible: false,
        menu: "",
      },
      youtube_video: "",
      faq: {
        title: "",
        button_text: "",
      },
      popup: {
        title: "",
        description: "",
        duration: "",
        button_text: "",
        is_visible: false,
      },
      footer: {
        menu1: "",
        menu2: "",
      },
    },
  });

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const response = await getMenuList();
      setMenuList(response?.data?.menus);

      const data = await storeConfig();
      const configData = data?.data?.data;

      if (configData) {
        setFormData(configData);
        setBanners(configData?.banner);
        setBannerVideo(configData?.banner_video);

        setHighlightImages(configData?.footer?.highlight);
        setFooterImage(configData?.footer?.logo);
        setImageUrl(configData?.header.logo);
        setFaqBannerImage(configData?.faq?.banner);
        setPopupImage(configData?.popup?.image);

        setTextColor(configData?.announcement?.text_color);
        setBackgroundColor(configData?.announcement?.background_color);
        setFooterBackgroundColor(configData?.footer?.background_color);

        setPopupImage(configData?.popup?.image);

        setTimeout(() => {
          setValue("header", configData?.header);
          setValue("announcement", configData?.announcement);
          setValue("sub_header", configData?.sub_header);
          setValue("footer", configData?.footer);
          setValue("faq", configData?.faq);
          setValue("popup", configData?.popup);
          setValue("feature", configData?.feature);
          setValue("youtube_video", configData.youtube_video);
          setValue("popup", configData.popup);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const SelectMemo = useCallback(
    ({ field }: any) => {
      return (
        <Select onValueChange={field.onChange} value={field.value}>
          <SelectTrigger>
            <SelectValue placeholder="Menu" />
          </SelectTrigger>
          <SelectContent>
            {menulist?.map((item) => (
              <SelectItem value={item?._id} key={item?._id}>
                {item?.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    },
    [menulist]
  );

  function submit(data: any) {
    const payload = {
      ...data,
      announcement: {
        ...data.announcement,
        background_color: backgroundColor,
        text_color: textColor,
      },
      banner_video: bannerVideo,
      footer: {
        ...data.footer,
        highlight: highlightImages?.length
          ? highlightImages?.map((el) => el._id)
          : "",
        logo: footerImage?.length ? footerImage?.map((el) => el._id) : "",
        background_color: footerBackgroundColor,
      },
      header: {
        ...data.header,
        logo: imageUrl?.length ? imageUrl?.map((el) => el._id) : "",
      },
      banner: banners?.length
        ? banners.map((el) => ({
            // @ts-ignore
            desktop: el.desktop?.map((itm) => itm._id),
            // @ts-ignore
            mobile: el.mobile?.map((itm) => itm._id),
          }))
        : "",
      faq: {
        title: data?.faq?.title,
        banner: faqBannerImage.length ? faqBannerImage.map((el) => el._id) : "",
        button_text: data?.faq?.button_text,
      },
      popup: {
        ...data.popup,
        title: data?.popup?.title,
        description: data?.popup?.description,
        duration: data?.popup?.duration,
        image: popupImage.length ? popupImage.map((el) => el._id) : "",
        button_text: data?.popup?.button_text,
      },
    };

    const cleanPayload = removeEmptyString(payload);

    if (formData) {
      updateStoreConfig(cleanPayload)
        .then(() => {
          toast({
            duration: 3000,
            title: "Updated",
            description: "Changes has been updated successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    } else {
      createStoreConfig(cleanPayload)
        .then(() => {
          location.reload();
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className=" grid gap-5">
        {/* ----------- Announcement ---------------- */}
        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>Announcement Bar</CardTitle>
          <div className="grid gap-3">
            <Label>Visible</Label>
            <Controller
              name="announcement.is_visible"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="grid gap-2">
            <Label>Title</Label>
            <Input {...register("announcement.title")} />
          </div>

          <div className=" grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <Label>Button Title</Label>
              <Input {...register("announcement.link.title")} />
            </div>

            <div className="grid gap-2">
              <Label>Link</Label>
              <Input {...register("announcement.link.route")} />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <ColorPicker
              value={backgroundColor}
              setValue={setBackgroundColor}
              title="Background color"
            />
            <ColorPicker
              value={textColor}
              setValue={setTextColor}
              title="Text color"
            />
          </div>
        </Card>

        {/* ----------- Header ---------------- */}
        <Card className="p-4 grid gap-5 shadow-none">
          <CardTitle>Header</CardTitle>
          <div className="grid gap-2">
            <Label>Select Menu</Label>
            <Controller
              control={control}
              name="header.menu"
              render={({ field }) => <SelectMemo field={field} />}
            />
          </div>

          <div className="grid gap-2">
            <Label>Header Logo</Label>
            <UploadFile
              maxLength={1}
              files={imageUrl}
              setFiles={setImageUrl}
              accept="image"
            />
          </div>
        </Card>

        {/* ----------- Secondary header -------------------- */}
        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>Secondary Header</CardTitle>
          <div className="grid gap-2">
            <Label>Visible</Label>
            <Controller
              name="sub_header.is_visible"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="grid gap-2">
            <Label>Menu</Label>
            <Controller
              control={control}
              name="sub_header.menu"
              render={({ field }) => <SelectMemo field={field} />}
            />
          </div>
        </Card>

        {/* -------------- category ------------------ */}
        <Card className="  p-4 grid gap-5 shadow-none">
          <div>
            <Controller
              control={control}
              name="feature.category"
              render={({ field }) => (
                <>
                  <Label>Category</Label>
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="all" id="r2" />
                      <Label htmlFor="r2">All</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="feature" id="r3" />
                      <Label htmlFor="r3">Feature</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="none" id="r1" />
                      <Label htmlFor="r1">None</Label>
                    </div>
                  </RadioGroup>
                </>
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="feature.product"
              render={({ field }) => (
                <>
                  <Label>Product</Label>
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="all" id="r2" />
                      <Label htmlFor="r2">All</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="feature" id="r3" />
                      <Label htmlFor="r3">Feature</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="none" id="r1" />
                      <Label htmlFor="r1">None</Label>
                    </div>
                  </RadioGroup>
                </>
              )}
            />
          </div>
        </Card>

        {/* ----------- Banners -------------------- */}
        <Card className="  p-4 grid gap-5 shadow-none">
          <StoreCustomisation setBanners={setBanners} banners={banners} />
        </Card>

        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>FAQs</CardTitle>
          <div className=" grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <Label>Title</Label>
              <Input {...register("faq.title")} />
            </div>

            <div className="grid gap-2">
              <Label>Button Text</Label>
              <Input {...register("faq.button_text")} />
            </div>
          </div>
          <UploadFile
            maxLength={1}
            files={faqBannerImage}
            setFiles={setFaqBannerImage}
            accept="image"
          />
        </Card>

        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>Popup</CardTitle>
          <div className=" grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <Label>Title</Label>
              <Input {...register("popup.title")} />
            </div>

            <div className="grid gap-2">
              <Label>Description</Label>
              <Input {...register("popup.description")} />
            </div>

            <div className="grid gap-2">
              <Label>Button Text</Label>
              <Input {...register("popup.button_text")} placeholder="Register Now"/>
            </div>
            <div className="grid gap-2">
              <Label>Duration<span className="text-gray-400">{` (in seconds)`}</span></Label>
              <Input {...register("popup.duration")} type="number" placeholder="5"/>
            </div>
            <div className="grid gap-3">
            <Label>Visible</Label>
            <Controller
              name="popup.is_visible"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          </div>
          <UploadFile
            maxLength={1}
            files={popupImage}
            setFiles={setPopupImage}
            accept="image"
          />
        </Card>

        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>Banner Video</CardTitle>
          <UploadFile
            maxLength={1}
            files={bannerVideo}
            setFiles={setBannerVideo}
            accept="video"
          />
        </Card>
        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>Youtube Video</CardTitle>
          <Input {...register("youtube_video")} />
        </Card>

        {/* ----------- footer -------------------- */}
        <Card className=" p-4 grid gap-5 shadow-none">
          <CardTitle>Footer</CardTitle>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="grid gap-2">
              <Label>Menu 1</Label>
              <Controller
                control={control}
                name="footer.menu1"
                render={({ field }) => <SelectMemo field={field} />}
              />
            </div>
            <div className="grid gap-2">
              <Label>Menu 2</Label>
              <Controller
                control={control}
                name="footer.menu2"
                render={({ field }) => <SelectMemo field={field} />}
              />
            </div>
          </div>

          <div className="grid gap-2">
            <Label>Footer Logo</Label>
            <UploadFile
              maxLength={1}
              files={footerImage}
              setFiles={setFooterImage}
              accept="image"
            />
          </div>

          <div className="grid gap-3">
            <Label>Footer Highlights</Label>
            <UploadFile
              maxLength={4}
              files={highlightImages}
              setFiles={setHighlightImages}
              accept="image"
            />
          </div>
          <ColorPicker
            title="Background color"
            value={footerBackgroundColor}
            setValue={setFooterBackgroundColor}
          />
        </Card>

        <Button size="sm" type="submit">
          Update Changes
        </Button>
      </form>
    </>
  );
}

export default Customise;

function removeEmptyString(obj: any) {
  if (typeof obj !== "object" || obj === null) return obj;

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (key === "banner_video") {
        // Skip processing for 'banner_video' property
        acc[key] = value;
        return acc;
      }

      if (value == "") {
        return acc;
      }

      acc[key] = removeEmptyString(value);
      return acc;
    },
    Array.isArray(obj) ? [] : ({} as any)
  );
}
