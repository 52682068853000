import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getCategoryList(filter?: string | null, page?: number,searchText?:string) {
  return axiosInterceptor.get("/category", {
    params: {
      filter,
      page,
      searchText
    },
  });
}

export function getCategoryOption() {
  return axiosInterceptor.get("/category/all");
}

export function getCategoryDetails(id: string | null) {
  return axiosInterceptor.get(`/category/${id}`);
}

export function createCategory(payload: any) {
  return axiosInterceptor.post(`/category`, payload);
}

export function updateCategory(payload: any, id: any) {
  return axiosInterceptor.put(`/category/${id}`, payload);
}

export function deleteCategory(id: any) {
  return axiosInterceptor.delete(`/category/${id}`);
}

export function addCategoryProduct(
  category_id: string | null,
  product_id: string
) {
  return axiosInterceptor.put(`/category/add-product-category/${category_id}`, {
    product_id,
  });
}

export function removeCategoryProduct(
  category_id: string | null,
  product_id: string
) {
  return axiosInterceptor.put(
    `/category/remove-product-category/${category_id}`,
    {
      product_id,
    }
  );
}
