import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { getMedia, updateAltTag } from "@/services/mediaServices";
import { PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useEffect, useRef, useState } from "react";
// import { Input } from "@/components/ui/input";
// import { inputDebounce } from "@/utils/debounce";
import { EmptyMedia } from "./EmptyMedia";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import FileUploadBox from "@/components/media/FileUploadBox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import VideoMediaCard from "./VideoMediaCard";

type Image = {
  _id: string;
  title: string;
  type: string;
  url: string;
};

type Meta = {
  page: number;
  total: number;
};

type ImageData = {
  _id: string;
  url: string;
  metaData: {
    alt: string;
  };
};

export default function MediaList() {
  const [data, setData] = useState<Image[]>([]);
  // const [searchText, setSearchText] = useState("");
  const [meta, setMeta] = useState<Meta>();
  const [filter, setFilter] = useState<string>("all");
  const [uploadModal, setUploadModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [viewImages, setViewImages] = useState<{
    image: ImageData | null;
    modal: boolean;
  }>({
    image: null,
    modal: false,
  });

  const altString = useRef<string>("");

  useEffect(() => {
    init();
    return () => {
      setData([]);
    };
  }, [filter]);

  useEffect(() => {
    if (page > 1) {
      init();
    }
  }, [page]);

  function init() {
    getMedia("image", page)
      .then((res) => {
        setMeta(res.data?.meta);
        setData((prev) => [...prev, ...res.data.data]);
      })
      .catch((err) => console.log(err));
  }

  // const debounce = inputDebounce();

  function closeModal() {
    setUploadModal(false);
    init();
  }

  function updateAlt() {
    updateAltTag(viewImages.image?._id, altString.current)
      .then(() => {
        setData((prev) => {
          return prev.map((image) => {
            if (image._id == viewImages?.image?._id) {
              return {
                ...image,
                metaData: {
                  alt: altString.current,
                },
              };
            }

            return image;
          });
        });

        setViewImages({ image: null, modal: false });
        altString.current = "";
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="flex h-full w-full flex-col gap-2 bg-muted/40">
      <Dialog onOpenChange={setUploadModal} open={uploadModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload Media</DialogTitle>
            <DialogDescription>
              <FileUploadBox successFunction={closeModal} />
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog
        onOpenChange={() => setViewImages({ image: null, modal: false })}
        open={viewImages?.modal}
      >
        <DialogContent className="min-w-[600px]">
          <DialogHeader>
            <div className="flex gap-4">
              <img
                src={viewImages?.image?.url}
                className="w-[300px] h-[300px] object-cover rounded-md"
              />
              <div className="w-[300px] flex flex-col justify-between mt-6">
                <div className="flex flex-col gap-2">
                  <Label>Alt</Label>
                  <Input
                    placeholder="Enter Image Alt"
                    defaultValue={viewImages?.image?.metaData?.alt}
                    onChange={(e) => (altString.current = e.target.value)}
                  />
                </div>

                <Button onClick={updateAlt} className="bg-[#4D5F47]">
                  Update
                </Button>
              </div>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Tabs
        value={filter}
        onValueChange={(value) => {
          setFilter(value);
        }}
      >
        <div className="flex items-center">
          <div className=" flex gap-4">
            <TabsList>
              <TabsTrigger value="all">All</TabsTrigger>
              <TabsTrigger value="image">Images</TabsTrigger>
              <TabsTrigger value="video">Videos</TabsTrigger>
              <TabsTrigger value="others" className="hidden sm:flex">
                Documents
              </TabsTrigger>
            </TabsList>

            {/* <div className="flex gap-2">
              <div className="relative ml-auto flex-1 md:grow-0">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search Images, Videos ..."
                  className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      debounce(() => {
                        setSearchText(e.target.value);
                      });
                    } else {
                      setSearchText("");
                    }
                  }}
                />
              </div>
            </div> */}
          </div>

          <div className="ml-auto flex items-center gap-2">
            <Button
              size="sm"
              className="h-7 gap-1 bg-[#4D5F47]"
              onClick={() => setUploadModal(true)}
            >
              <PlusCircle className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Upload Media
              </span>
            </Button>
          </div>
        </div>
      </Tabs>
      {data.length === 0 ? (
        <EmptyMedia />
      ) : (
        <>
          <Card>
            <CardHeader>
              <CardTitle>Media Files</CardTitle>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[75vh]">
                <div className="grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-6 gap-4 w-full">
                  {data?.map((item: any, index: number) => {
                    return (
                      <div className="border rounded-xl overflow-hidden" onClick={() =>
                        setViewImages({ modal: true, image: item })
                      }>
                        {item?.type === 'video' ? <VideoMediaCard data={item} /> :
                        <img
                          key={index}
                          src={item?.url}
                          className="aspect-square object-cover w-full"
                          width={100}
                          height={100}
                          onClick={() =>
                            setViewImages({ modal: true, image: item })
                          }
                        />}
                      </div>
                    );
                  })}
                </div>
                <div className="text-center mt-10">
                  {!Boolean(data?.length >= (meta?.total ?? 0)) && (
                    <Button
                      size="sm"
                      className="bg-[#4D5F47]"
                      onClick={() => setPage((prev) => prev + 1)}
                    >
                      Load More
                    </Button>
                  )}
                </div>
              </ScrollArea>

              <CardFooter className="p-0">
                <div className="text-xs text-muted-foreground">
                  Showing{" "}
                  <strong>{data.length}</strong> of <strong>{meta?.total}</strong> Files
                </div>
              </CardFooter>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
