// import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CircleCheckBig } from "lucide-react";
import { useState } from "react";

export default function Plans() {
  const [duration, setDuration] = useState("monthly");
  const [storePlan] = useState("paid");
  return (
    <div>
      <div className="flex flex-col justify-between items-left">
        <CardTitle className="text-xl">Subscription Plans</CardTitle>
        <CardDescription className="text-sm">
          You store is on free trial. Please subscribe to a plan.
        </CardDescription>
      </div>
      <div className="mt-10 w-full text-center justify-center flex flex-col items-center">
        <CardTitle className="text-[26px]">
          Simple Straightforward Pricing
        </CardTitle>
        <CardDescription className="text-md md:text-sm w-full md:w-[60%]">
          Choose the plan that's right fit for your brand. Whether you're just
          getting started with online selling or you're personalising your
          online store, there's a plan for you.
        </CardDescription>
        <Tabs defaultValue="monthly" className="w-[300px] mt-8">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="monthly" onClick={() => setDuration("monthly")}>Monthly</TabsTrigger>
            <TabsTrigger value="yearly" onClick={() => setDuration("yearly")}>Annual (Save 15%)</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mt-8 items-center flex-wrap w-full justify-center">
        {plan.map((item, index) => {
          return (
            <div
              key={index}
              className="border border-1 w-full md:w-[350px] h-[400px] rounded-md p-4"
            >
              <div className="flex justify-between">
              <CardTitle className="mb-4">{item.plan_name}</CardTitle>
              {/* {duration === 'yearly' && <Badge className="h-fit">
                {parseFloat((item.monthly_cost-item?.yearly_cost)*100/item?.monthly_cost).toFixed(0)}% off</Badge>} */}
              </div>
              <CardTitle className="text-[32px] font-bold">
                {duration === 'monthly' ? `₹${item.monthly_cost}` : <span><span className="line-through text-gray-400">₹{item.monthly_cost}</span> ₹{item.yearly_cost}</span>}
                <span className="text-sm font-normal"> / month</span>
              </CardTitle>
              <CardDescription className="text-sm w-fit">
                This plan is best for businesses who are starting up in online
                store.
              </CardDescription>
              <Button disabled={item.activation.is_active} className="w-full mt-4 bg-[#4D5F47]">
                {storePlan === 'paid' ? item.activation.is_active ? 'Current Plan' : `Upgrade to ${item.plan_name}` : 'Subscribe Now'}
              </Button>
              <div className="mt-2">
                <div className="flex gap-2 items-center border-b border-b-1 p-2">
                  <CircleCheckBig size={16} />
                  <p className="text-[14px]">1 Store</p>
                </div>
                <div className="flex gap-2 items-center border-b border-b-1 p-2">
                  <CircleCheckBig size={16} />
                  <p className="text-[14px]">
                    Pinecart domain{" "}
                    <span className="text-gray-400">
                      (yourstore.pinecart.com)
                    </span>
                  </p>
                </div>
                <div className="flex gap-2 items-center border-b border-b-1 p-2">
                  <CircleCheckBig size={16} />
                  <p className="text-[14px]">2% transaction fees</p>
                </div>
                <div className="flex gap-2 items-center border-b border-b-1 p-2">
                  <CircleCheckBig size={16} />
                  <p className="text-[14px]">1 Store</p>
                </div>
                <div className="flex gap-2 items-center p-2">
                  <CircleCheckBig size={16} />
                  <p className="text-[14px]">1 Store</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const plan = [
  {
    plan_id: 1,
    plan_name: "Starter",
    monthly_cost: "699",
    yearly_cost: "592",
    features: ["1 Store", "Pinecart domain", "2% transaction fees"],
    activation: {
      is_active: true,
      activationType: 'monthly'
    }
  },
  {
    plan_id: 2,
    plan_name: "Professional",
    monthly_cost: "999",
    yearly_cost: "849",
    features: ["1 Store", "Pinecart domain", "2% transaction fees"],
    activation: {
      is_active: false,
      activationType: null
    }
  },
  {
    plan_id: 3,
    plan_name: "Growth",
    monthly_cost: "1399",
    yearly_cost: "1195",
    features: ["1 Store", "Custom Domain", "2% transaction fees"],
    activation: {
      is_active: false,
      activationType: null
    }
  },
];
