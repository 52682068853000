import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function registerUser(data: object) {
  return axiosInterceptor.post("/auth/signup", data);
}

export function loginUser(data: any) {
  return axiosInterceptor.post("/auth/login", data);
}

export function verifyUser(token: string) {
  return axiosInterceptor.get("/auth/verify_token", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function googleSignup(payload: {
  credential: string;
}) {
  return axiosInterceptor.post("/auth/googleSignup", payload);
}


export function googleLogin(payload: {
  credential: string;
}) {
  return axiosInterceptor.post("/auth/googleLogin", payload);
}