import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getProductList(
  filter?: string | null,
  page?: number,
  searchText?: string
) {
  return axiosInterceptor.get(`/product`, {
    params: {
      filter,
      page,
      searchText,
    },
  });
}

export function getProductOptions() {
  return axiosInterceptor.get(`/product/all`);
}

export function createProduct(payload: object) {
  return axiosInterceptor.post(`/product`, payload);
}

export function getProductDetails(id: string | null) {
  return axiosInterceptor.get(`/product/${id}`);
}

export function editProduct(id: string | null, payload: object) {
  return axiosInterceptor.put(`/product/${id}`, payload);
}

export function deleteProduct(id: string) {
  return axiosInterceptor.delete(`/product/${id}`);
}

export function uploadProductCSV(formData: FormData) {
  return axiosInterceptor.post(`/product/csv/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function exportProductCSV() {
  return axiosInterceptor.get(`/product/csv/export`,{
    responseType: 'blob',
  });
}
