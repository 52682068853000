import { MoreHorizontal, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { useRef, useState } from "react";
import { deleteMenu } from "@/services/menuService";

export function MenuTable({ data }: any) {
  const [confirmation, setConfirmation] = useState(false);

  const selectedId = useRef();

  function handleDeletion() {
    deleteMenu(selectedId.current)
      .then((res) => {
        console.log(res.data);
        location.reload();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {confirmation && (
        <Dialog open={confirmation} onOpenChange={setConfirmation}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Are you absolutely sure?</DialogTitle>
              <DialogDescription>
                This action cannot be undone. This will permanently delete your
                account and remove your data from our servers.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button onClick={handleDeletion}>Confirm</Button>

              <Button onClick={() => setConfirmation(false)}>Cancel</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}

      <Tabs defaultValue="website" className=" p-2 flex flex-col gap-3">
        <div className="flex items-center">
          <div className="ml-auto hidden md:flex items-center gap-2">
            <NavLink to="/menu/add">
              <Button size="sm" className="h-7 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Create Menu
                </span>
              </Button>
            </NavLink>
          </div>
        </div>

        <Card x-chunk="dashboard-06-chunk-0">
          <CardHeader>
            <CardTitle>Menu Items</CardTitle>
            <CardDescription>Manage your website menu items.</CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow className="text-[12px]">
                  <TableHead>Menu Name</TableHead>
                  <TableHead className="hidden md:table-cell">
                    Menu Items
                  </TableHead>
                  <TableHead>
                    <span className="sr-only">Actions</span>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data?.map((item: any, index: any) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <NavLink
                          to={`/menu/edit?id=${item?._id}`}
                          className="flex flex-row gap-4 items-center text-xs"
                        >
                          <div>
                            <div className="font-medium">{item?.name}</div>
                          </div>
                        </NavLink>
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        {item?.order_count}
                      </TableCell>
                      <TableCell className="flex justify-end">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            {/* <DropdownMenuLabel>Actions</DropdownMenuLabel> */}
                            <NavLink to={`/menu/edit?id=${item?._id}`}>
                              <DropdownMenuItem>Edit</DropdownMenuItem>
                            </NavLink>
                            <DropdownMenuItem
                              onClick={() => {
                                selectedId.current = item._id;
                                setConfirmation(true);
                              }}
                            >
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Tabs>
    </>
  );
}
