import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

type OrderDimension = {
  length: string;
  breadth: string;
  height: string;
  weight: string;
};

type props = {
  handleSubmit: (value: OrderDimension) => void;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const formSchema = z.object({
  length: z.string().min(1, { message: 'Shipment length is mandatory' }),
  breadth: z.string().min(1, { message: 'Shipment breadth is mandatory' }),
  height: z.string().min(1, { message: 'Shipment height is mandatory' }),
  weight: z.string().min(1, { message: 'Shipment weight is mandatory' }),
});

function DimensionModal({ setModal, modal, handleSubmit }: props) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      length: "",
      breadth: "",
      height: "",
      weight: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    handleSubmit(values);
  }

  return (
    <Dialog onOpenChange={setModal} open={modal}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-primary">Dimensions</DialogTitle>
          <DialogDescription>
            Add your package details in order to generate shipment
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            {formArray.map((item, key) => (
              <FormField
                key={key}
                control={form.control}
                // @ts-ignore
                name={item?.name}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {item?.label} <span className="text-gray-400">{item?.measurement && `(in ${item?.measurement})`}</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder={item?.placeholder} {...field} type="number"/>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}

            <Button size="sm" type="submit" className="bg-primary">Proceed to Shipment</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default DimensionModal;

const formArray = [
  {
    name: "length",
    label: 'Enter package length',
    measurement: 'cms',
    placeholder: '10'
  },
  {
    name: "breadth",
    label: 'Enter package breadth',
    measurement: 'cms',
    placeholder: '20.5'
  },
  {
    name: "height",
    label: 'Enter package height',
    measurement: 'cms',
    placeholder: '10'
  },
  {
    name: "weight",
    label: 'Enter package weight',
    measurement: 'kgs',
    placeholder: '1.2'
  },
];
