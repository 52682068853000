let timeoutId: any = null;
const debounce = (event: any, func: any, delay: any) => {
  clearTimeout(timeoutId);
  return new Promise((resolve, _) => {
    timeoutId = setTimeout(async () => {
      return resolve(await func(event.target.value));
    }, delay);
  });
};


export function inputDebounce() {
  let timeout: any;
  return (func: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func();
    }, 1000);
  };
}


export default debounce;
