import { CustomerTable } from "@/modules/Customers/components/CustomerTable";
import EmptyCustomer from "./EmptyCustomer";
import { useEffect, useState } from "react";
import { getCustomerList } from "@/services/customerServices";

export function Customers() {
  const [onlineCustomers, setOnlineCustomers] = useState(true);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    getCustomerList().then((res) => {
      setData(res?.data);
    });
  }, []);
  return (
        data?.customers?.length == 0 && data?.offlineCustomers?.length == 0 ? (
          <EmptyCustomer />
        ) : (
          <CustomerTable
            customers={
              onlineCustomers ? data?.customers : data?.offlineCustomers
            }
            onlineCustomers={onlineCustomers}
            setOnlineCustomers={setOnlineCustomers}
          />
        )
  );
}
