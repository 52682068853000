import { z } from "zod";

export const createCustomerFormSchema = z.object({
  name: z.string().min(2).max(25),
  phone_number: z
    .string()
    .min(10)
    .max(10)
    .regex(/^[6-9][0-9]{9}$/gm, "Phone must contain 10 digits"),
  email: z.string().email("Invalid email format"),
});
