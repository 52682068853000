import { Input } from "../ui/input";
import { Upload } from "lucide-react";
import { uploadFiles } from "@/services/mediaServices";
import { Button } from "../ui/button";
import { toast } from "@/components/ui/use-toast";

type props = {
  inputType?: string;
  successFunction?: (data?: any) => void;
};

const FileUploadBox = ({ inputType, successFunction }: props) => {
  async function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files == null) {
      return;
    }
    try {
      const data = (await uploadFiles(event.target.files)).data;
      successFunction && successFunction(data);
      toast({
        duration: 3000,
        title: "Success",
        description: "File has been uploaded successfully!",
      });
    } catch (error) {
      console.log("image upload error ", error);
    }
  }

  return (
    <Button variant="ghost" className="relative h-full w-full">
      <Input
        type="file"
        id="imageUrl"
        accept={inputType}
        onChange={handleImageUpload}
        multiple={true}
        className="absolute top-0 bottom-0 left-0 right-0 z-10 h-full w-full opacity-0 cursor-pointer"
      />
      <div className="flex flex-col items-center justify-center gap-2 h-full w-full cursor-pointer p-8">
        <Upload />
        <p className="text-[12px] text-slate-600 font-semibold">
          Upload a file
        </p>
      </div>
    </Button>
  );
};

export default FileUploadBox;
