import { Outlet } from "react-router-dom";

import { Truck, Warehouse, Globe, Store, CodeXml } from "lucide-react";

import { NavLink } from "react-router-dom";
import { Card } from "../ui/card";

function StoreSettingLayout() {
  return (
    <div className="flex gap-4 relative">
      <Card className="h-[93vh] w-60 fixed hidden md:block">
        <nav className="grid items-start px-2 text-sm font-medium lg:px-4 py-2 lg:py-4">
          {sideMenu.map((item, i) => {
            const Icon: any = item.icon;
            return (
              <NavLink
                key={i}
                to={item?.route}
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary capitalize"
              >
                <Icon className="h-4 w-4" />
                {item?.name}
              </NavLink>
            );
          })}
        </nav>
      </Card>
      <div className="pl-0 md:pl-64 w-full">
        {/* <div className="flex justify-between items-center">
          <CardTitle className="text-xl capitalize">
            {location.pathname.replace("/", "")}
          </CardTitle>
        </div> */}
        <Outlet />
      </div>
    </div>
  );
}

export default StoreSettingLayout;

const sideMenu = [
  {
    name: "Store",
    icon: Store,
    route: "",
  },
  // {
  //   name: "payment",
  //   icon: CreditCard,
  //   route: "payment",
  // },
  // {
  //   name: "shipping",
  //   icon: Truck,
  //   route: "shipping",
  // },
  // {
  //   name: "Backup & Maintenance",
  //   icon: Truck,
  //   route: "backup",
  // },
  {
    name: "Domains",
    icon: Globe,
    route: "custom-domain",
  },
  {
    name: "Store Meta",
    icon: CodeXml,
    route: "meta",
  },
  {
    name: "Shipping",
    icon: Truck,
    route: "shipping",
  },
  {
    name: "Warehouse",
    icon: Warehouse,
    route: "warehouse",
  },
  // {
  //   name: "User Management",
  //   icon: User,
  //   route: "user-management",
  // },
];
