import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function createWarehouse(data: object) {
  return axiosInterceptor.post("/warehouse", data);
}

export function getWarehouses() {
  return axiosInterceptor.get("/warehouse");
}

export function getWarehouseDetails(id: string) {
  return axiosInterceptor.get(`/warehouse/${id}`);
}

export function updateWarehouseDetails(id: string | null, data: object) {
  return axiosInterceptor.put(`/warehouse/${id}`, data);
}

export function deleteWarehouse(id: string | null) {
  return axiosInterceptor.delete(`/warehouse/${id}`);
}
