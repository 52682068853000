import { Badge } from "@/components/ui/badge";
import { CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";

export default function OrderItem({ data }: any) {
  return (
    <div className="flex items-center justify-between border-b pb-6 border-b-gray-100">
      <div className="w-full h-20 flex">
        <div className="flex items-start gap-4 h-full w-full">
          {data?.images[0] && data?.images[0].url && (
            <>
              <img
                src={data?.images[0].url}
                width={50}
                height={50}
                className="aspect-square rounded-md h-full w-auto object-cover"
              />
            </>
          )}

          <div className="flex flex-col gap-2 justify-between w-full h-full">
            <CardTitle className="text-md">
              {data?.name}
            </CardTitle>
            {data?.sku && (
              <Label className="text-[12px] bg-yellow-100 rounded-full flex px-2 py-1 text-slate-500 items-center w-fit">
                SKU:{data?.sku}
              </Label>
            )}
            <div className="flex gap-2 justify-between">
              {data?.variant && (
                <div className="flex gap-2 items-center">
                  {data?.variant?.option_1_value && (
                    <Badge variant="outline">
                      {data?.variant?.option_1_value}
                    </Badge>
                  )}
                  {data?.variant?.option_2_value && (
                    <Badge variant="outline">
                      {data?.variant?.option_2_value}
                    </Badge>
                  )}
                </div>
              )}
              <div className="flex gap-2 items-center">
                <Badge variant="outline" className="w-fit">
                  {/* <span className="font-semibold"> */}
                  {data?.quantity} Unit x ₹
                  {data.variant
                    ? data?.variant?.discount_price
                    : data?.discount_price}
                  {/* </span> */}
                </Badge>
                <div className="justify-end flex text-sm font-semibold">
                  ₹{data?.total}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
