import {
  File,
  ListFilter,
  MoreHorizontal,
  PlusCircle,
  Search,
} from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { EmptyDiscount } from "./EmptyDiscount";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { ScrollArea } from "@/components/ui/scroll-area";
import dateFormat from "@/utils/dateFormt";
import Pagination from "@/components/common/Pagination";
import { Input } from "@/components/ui/input";
import { inputDebounce } from "@/utils/debounce";
import DiscountMetrics from "./DiscountMetrics";
import { deleteDiscount, getDiscountList } from "@/services/discountService";

export function DiscountTable() {
  const debounce = inputDebounce();

  const [data, setData] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectedId = useRef("");

  const [searchText, setSearchText] = useState("");
  const [filter] = useState<string>("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    init();
    return () => {
      setData([]);
    };
  }, [filter, page, searchText]);

  function init() {
    getDiscountList(searchText,page)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function deleteDiscountHandler() {
    deleteDiscount(selectedId.current)
      .then(() => {
        setConfirmationModal(false);
        init();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="flex h-full w-full flex-col gap-4 bg-muted/40">
      <DiscountMetrics />
      {confirmationModal && (
        <ConfirmationModal
          modal={confirmationModal}
          setModal={setConfirmationModal}
          handleDelete={deleteDiscountHandler}
        />
      )}
        <Card>
          <CardHeader className="flex flex-row justify-between items-center">
            <div>
            <CardTitle>Discounts</CardTitle>
            <CardDescription className="hidden md:block">
            Manage your discounts with ease.
            </CardDescription>
            </div>
            <div className="ml-auto flex items-center gap-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm" className="h-7 gap-1">
                    <ListFilter className="h-3.5 w-3.5" />
                    <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                      Filter
                    </span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuCheckboxItem checked>
                    Active
                  </DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem>Draft</DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem>Scheduled</DropdownMenuCheckboxItem>
                  <DropdownMenuCheckboxItem>Expired</DropdownMenuCheckboxItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button size="sm" variant="outline" className="h-7 gap-1">
                <File className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Export
                </span>
              </Button>
              <NavLink to="/discount/add">
                <Button size="sm" className="h-7 gap-1 bg-primary">
                  <PlusCircle className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Create Discount
                  </span>
                </Button>
              </NavLink>
            </div>
          </CardHeader>
          <CardContent>
            <div>
              <div className="relative ml-auto flex-1 md:grow-0">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search discounts by name or code..."
                  className="w-full rounded-lg bg-background pl-8"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      debounce(() => {
                        setPage(1);
                        setSearchText(e.target.value);
                      });
                    } else {
                      setPage(1);
                      setSearchText("");
                    }
                  }}
                />
              </div>
            </div>
            {data.length === 0 ? (
        <EmptyDiscount />
      ) : (
            <Table className="mt-2">
              <ScrollArea className="h-[370px]">
                <TableHeader>
                  <TableRow className="text-[12px]">
                    <TableHead>Name</TableHead>
                    <TableHead>Code</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Created At</TableHead>
                    <TableHead>Redemptions</TableHead>
                    <TableHead>
                      <span className="sr-only">Actions</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <NavLink
                            to={`/discount/edit?id=${item._id}`}
                            className="flex flex-row items-center gap-4 text-xs"
                          >
                            {item?.title}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <Badge
                            variant="secondary"
                            className="uppercase shadow-none font-semibold border border-gray-200"
                          >
                            {item?.code}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <Badge
                            className={`capitalize border-none font-normal ${
                              item?.status === "active"
                                ? "bg-primary"
                                : item.status === "draft"
                                ? "bg-yellow-300"
                                : "bg-red-300"
                            }`}
                          >
                            {item?.status}
                          </Badge>
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {dateFormat(item?.createdAt)}
                        </TableCell>
                        <TableCell>{item?.redemption}</TableCell>
                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                aria-haspopup="true"
                                size="icon"
                                variant="ghost"
                              >
                                <MoreHorizontal className="h-4 w-4" />
                                <span className="sr-only">Toggle menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <NavLink to={`/discount/edit?id=${item._id}`}>
                                <DropdownMenuItem>Edit</DropdownMenuItem>
                              </NavLink>
                              <DropdownMenuItem className=" p-0">
                                <button
                                  className=" w-full text-left p-2"
                                  onClick={() => {
                                    selectedId.current = item?._id;
                                    setConfirmationModal(true);
                                  }}
                                >
                                  Delete
                                </button>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </ScrollArea>
            </Table>
        )}
          </CardContent>
          <Pagination
            type="category"
            page={page}
            data={{}}
            setPage={setPage}
            nextPage={data.length < 10}
          />
        </Card>
    </div>
  );
}
