import { Card, CardTitle } from "../../../components/ui/card";
import { ChevronRight } from "lucide-react";
import { Store } from "lucide-react";

const StoreCard = ({data}:any) => {
  return (
    <Card className="mx-auto max-w-sm h-[80px] w-full p-2 flex gap-2 justify-between cursor-pointer hover:bg-primary hover:text-white shadow-none">
      <div className=" flex gap-2 h-full">
        <div className="hidden sm:block border overflow-hidden rounded-md aspect-square h-full">
          {data?.logoUrl ? (
            <img
              src={data?.logoUrl}
              alt="Store Icon"
              className="aspect-square w-full object-contain bg-white"
            />
          ) : (
            <div className="flex justify-center items-center h-full text-yellow-500">
              <Store />
            </div>
          )}
        </div>
        <div className="p-1 flex flex-col justify-center">
          <CardTitle>{data?.store_name}</CardTitle>
        </div>
      </div>
      <div className="h-full flex items-center">
        <ChevronRight />
      </div>
    </Card>
  );
};

export default StoreCard;
