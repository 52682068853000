import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { Textarea } from "@/components/ui/textarea";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import {
  addCategoryProduct,
  createCategory,
  getCategoryDetails,
  removeCategoryProduct,
  updateCategory,
} from "@/services/categoryService";
import { useEffect, useState } from "react";
import CategoryCreateHeader from "../components/CategoryCreateHeader";
import UploadFile from "@/components/media/UploadFile";
import MetaForm from "@/components/common/MetaForm";
import ReactSelect from "react-select";
import { getProductOptions } from "@/services/productService";
import { X } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { file } from "@/utils/Interface";
import { Switch } from "@/components/ui/switch";

type selectedProduct = {
  value: string;
  label: string;
  images: any[];
};

export function CategoryForm() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const category_id = searchParams.get("id");

  const [imageUrl, setImageUrl] = useState<file[]>([]);
  const [banner, setBanner] = useState<file[]>([]);

  const [products, setProducts] = useState<selectedProduct[]>([]);

  const [selectedProducts, setSelectedProducts] = useState<selectedProduct[]>(
    []
  );
  const [formData, setFormData] = useState<string[]>([]);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm<any>({
    defaultValues: {
      status: "draft",
    },
  });

  useEffect(() => {
    getProductOptions()
      .then((res) => {
        setProducts(() =>
          res.data.data.map((el: any) => ({
            value: el._id,
            label: el.name,
            images: el.images,
          }))
        );
      })
      .catch((err) => console.log(err));

    if (category_id) {
      getCategoryDetails(category_id)
        .then((res) => {
          const response = res.data.data;
          for (let key in response) {
            setValue(key, response[key]);
          }
          setImageUrl(response?.images);
          setBanner(response?.banners);
          setFormData(response?.products.map((el: any) => el?._id));
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (formData.length) {
      const selectedProducts = products.filter((item) =>
        formData.includes(item?.value)
      );
      setSelectedProducts(selectedProducts);
    }
  }, [formData, products]);

  function handleProductSelection(item: any) {
    const index = selectedProducts.findIndex(
      (product) => product?.value == item?.value
    );

    if (index != -1) {
      removeCategoryProduct(category_id, item?.value)
        .then(() =>
          setSelectedProducts((prev) => prev.filter((_, i) => i != index))
        )
        .catch((err) => console.log(err));
    } else {
      addCategoryProduct(category_id, item?.value)
        .then(() => setSelectedProducts((prev) => [...prev, item]))
        .catch((err) => console.log(err));
    }
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const payload = {
      ...data,
      images: imageUrl.map((el) => el._id),
      banners: banner.map((el) => el._id),
      products_id: selectedProducts.map((el) => el.value),
    };

    if (category_id) {
      updateCategory(payload, category_id)
        .then(() => {
          navigate(-1);
          toast({
            duration: 3000,
            title: "Category Updated",
            description: "Category has been updated successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    } else {
      createCategory(payload)
        .then(() => {
          navigate(-1);
          toast({
            duration: 3000,
            title: "Category Added",
            description: "Category has been added successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    }
  };

  return (
    <form
      className="grid flex-1 items-start gap-4 md:gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid flex-1 auto-rows-max gap-4">
        <CategoryCreateHeader create={category_id ? false : true} />
        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0 ">
              <CardHeader>
                <CardTitle>General Details</CardTitle>
                <CardDescription>
                  Lipsum dolor sit amet, consectetur adipiscing elit
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="name">Category Name</Label>
                    <Input
                      id="name"
                      type="text"
                      className="w-full"
                      placeholder="Enter category name"
                      {...register("name", {
                        onBlur(event) {
                          setValue(
                            "slug",
                            event?.target?.value
                              .toLocaleLowerCase()
                              .replace(/[^A-Z0-9]/gi, "-")
                          );
                        },
                      })}
                    />
                  </div>

                  <div className="grid gap-3">
                    <Label htmlFor="description">Description</Label>
                    <Textarea
                      id="description"
                      placeholder="Enter category description"
                      className="min-h-32"
                      {...register("description")}
                    />
                  </div>

                  <div className="grid gap-3">
                    <Controller
                      control={control}
                      name="feature"
                      render={({ field }) => (
                        <>
                          <Label htmlFor="feature">Feature</Label>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            id="feature"
                          />
                        </>
                      )}
                    />
                  </div>

                  <div className="grid gap-6">
                    <div className="grid gap-3">
                      <Label htmlFor="status">Status</Label>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                            defaultValue="draft"
                          >
                            <SelectTrigger
                              id="status"
                              aria-label="Select status"
                            >
                              <SelectValue placeholder="Select status" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="draft">Draft</SelectItem>
                              <SelectItem value="active">Active</SelectItem>
                              <SelectItem value="archived">Archived</SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            {category_id && (
              <Card className="p-4">
                <CardContent className="p-0 flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <Label>Select Products</Label>
                    <ReactSelect
                      options={products}
                      placeholder="Select products to add"
                      className="text-sm font-semibold"
                      onChange={handleProductSelection}
                    />
                  </div>

                  <div className="flex flex-wrap gap-2">
                    {selectedProducts.map((product) => (
                      <Badge
                        className="gap-2 border border-gray-300 cursor-default p-1 rounded-md"
                        variant="secondary"
                        key={product?.value}
                      >
                        <img
                          src={product?.images[0]?.url}
                          width={100}
                          height={100}
                          className="w-[30px] h-[30px] object-cover aspect-square rounded-md"
                        />
                        {product?.label}
                        <div
                          onClick={() => handleProductSelection(product)}
                          className="cursor-pointer p-2"
                        >
                          <X size={14} />
                        </div>
                      </Badge>
                    ))}
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card className="overflow-hidden" x-chunk="dashboard-07-chunk-4">
              <CardHeader>
                <CardTitle>Category Image</CardTitle>
                <CardDescription>
                  You need at least 4 images. Pay attention to the quality of
                  the pictures you add
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-2">
                  <UploadFile
                    maxLength={1}
                    files={imageUrl}
                    setFiles={setImageUrl}
                    accept="image"
                  />
                </div>
              </CardContent>
            </Card>

            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Banner Image</CardTitle>
                <CardDescription>
                  Upload category banner image. Only .jpg, .png, .webp allowed
                  (max 1MB)
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-2">
                  <UploadFile
                    maxLength={1}
                    files={banner}
                    setFiles={setBanner}
                    accept="image"
                  />
                </div>
              </CardContent>
            </Card>

            <MetaForm register={register} />
          </div>
        </div>
      </div>
    </form>
  );
}
