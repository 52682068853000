import OrderMetrics from "@/modules/Orders/components/OrderMetrics";
import OrderTable from "@/modules/Orders/components/OrderTable";
import { getMetrics } from "@/services/metricService";
import { getOrderList } from "@/services/orderService";
import { useEffect, useState } from "react";

export function Orders() {

  const [data, setData] = useState<[]>();
  const [weekMetrics, setWeekMetrics] = useState(null);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    getOrderList(page)
      .then((res) => {
        setData(res.data?.orders);
        setMeta(res.data.meta);
      })
      .catch((err) => console.log(err));

    return () => {
      setData([]);
    };
  }, [page]);

  useEffect(() => {
    getMetrics("week")
      .then((res) => {
        setWeekMetrics(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="grid flex-1 auto-rows-max items-start gap-4 md:gap-6 lg:col-span-4">
      {weekMetrics && <OrderMetrics data={weekMetrics} />}
      <OrderTable data={data} page={page} setPage={setPage} meta={meta} />
    </div>
  );
}
