import { z } from "zod";

export const registerFormSchema = z.object({
  first_name: z.string().min(2).max(15),
  last_name: z.string().min(2).max(15),
  email: z.string().min(5).max(30).email("Invalid email format"),
  password: z
    .string()
    .min(6, "Password must be at least 6 characters long")
    .max(32, "Password cannot be longer than 32 characters")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]+$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character"
    )
    .trim(),
});
