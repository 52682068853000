import { Button } from "@/components/ui/button"
import { NavLink } from "react-router-dom"

export function EmptyDiscount() {
  return (
    <div className="grid h-[40vh] w-full mt-6">
        <main className="flex flex-1 flex-col gap-4">
          <div
            className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm" x-chunk="dashboard-02-chunk-1"
          >
            <div className="flex flex-col items-center gap-1 text-center">
              <h3 className="text-2xl font-bold tracking-tight">
              Level Up Your Sales with Discounts
              </h3>
              <p className="text-sm text-muted-foreground">
              Create custom discounts that perfectly match your sales goals and target audience.
              </p>
              <NavLink to="/discount/add">
              <Button className="mt-4">Create Discount</Button>
              </NavLink>
            </div>
          </div>
        </main>
    </div>
  )
}
