import CustomerForm from "./CustomerForm";

const AddCustomer = () => {
  return (
    <div className="flex justify-center items-center h-screen w-full sm:pl-14">
      <div className="p-4 w-full flex justify-center items-center">
        <CustomerForm />
      </div>
    </div>
  );
};

export default AddCustomer;
