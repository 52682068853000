import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { CreditCard, IndianRupee, Users } from "lucide-react";

export default function DiscountMetrics() {
  return (
    <div className="grid gap-4 md:grid-cols-3 md:gap-4 lg:grid-cols-3">
      <Card x-chunk="dashboard-01-chunk-0">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Total Redemptions
          </CardTitle>
          <CreditCard
            className="h-4 w-4 text-muted-foreground"
            color="#4D5F47"
          />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">0</div>
          <p className="text-xs text-muted-foreground">0% from last month</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-2">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">New Redemptions</CardTitle>
          <Users className="h-4 w-4 text-muted-foreground" color="#4D5F47" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">0</div>
          <p className="text-xs text-muted-foreground">
            0% from last month
          </p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-1">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Redemptions Amount
          </CardTitle>
          <IndianRupee className="h-4 w-4" color="#4D5F47" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">₹0</div>
          <p className="text-xs">0% from last month</p>
        </CardContent>
      </Card>
    </div>
  );
}
