import axiosInterceptor from "@/Middleware/axiosInterceptor";

export function getDiscountList(search: string, page: number) {
  return axiosInterceptor.get("/discount", {
    params: {
      search,
      page,
    },
  });
}

export function getDiscountDetail(id: string | null) {
  return axiosInterceptor.get(`/discount/${id}`);
}

export function createDiscount(payload: any) {
  return axiosInterceptor.post(`/discount`, payload);
}

export function updateDiscount(payload: any, id: any) {
  return axiosInterceptor.put(`/discount/${id}`, payload);
}

export function deleteDiscount(id: any) {
  return axiosInterceptor.delete(`/discount/${id}`);
}
