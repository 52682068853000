import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "../ui/button";
import { CardFooter } from "../ui/card";

type props = {
  type: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  nextPage: Boolean;
  data: any;
};

function Pagination({ type, page, setPage, nextPage, data }: props) {
  return (
    <CardFooter className="flex justify-between">
      <div className="text-xs text-muted-foreground">
        Showing <strong>{(data?.page-1)*10 + 1}-{(data?.page)*10 < data?.total ? (data?.page)*10 : data?.total}</strong> of <strong>{data?.total ? data?.total : '32'}</strong> {type}
      </div>

      <div className=" flex gap-3">
        <Button
          className="p-1 "
          variant="outline"
          disabled={page == 1}
          onClick={() => setPage((prev) => prev - 1)}
        >
          <ChevronLeft />
        </Button>

        <Button
          className="p-1 "
          variant="outline"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={Boolean(nextPage)}
        >
          <ChevronRight />
        </Button>
      </div>
    </CardFooter>
  );
}

export default Pagination;
