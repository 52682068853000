import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CreateMenuHeader from "./CreateMenuHeader";
import MenuItemContainer from "./MenuItemContainer";
import { createMenu, menuDetail, updatedMenu } from "@/services/menuService";

type MenuItem = {
  name: string;
  route: string;
  childrens?: MenuItem[];
};

export default function CreateMenu() {
  const navigate = useNavigate();

  const [searchParam] = useSearchParams();
  const menuId = searchParam.get("id");

  const [menuJson, setMenuJson] = useState<MenuItem[]>([]);
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (menuId) {
      menuDetail(menuId)
        .then((res) => {
          const response = res.data.data;
          setName(response?.name);
          setMenuJson(response?.menuJson);
        })
        .catch((err) => console.log(err));
    }

    return () => {};
  }, []);

  function handleSubmit() {
    const payload = { name, menuJson };

    if (menuId) {
      updatedMenu(menuId, payload)
        .then(() => navigate("/menu"))
        .catch((err) => console.log(err));
    } else {
      createMenu(payload)
        .then(() => navigate("/menu"))
        .catch((err) => console.log(err));
    }
  }

  return (
    <div className="grid flex-1 auto-rows-max gap-4">
      <CreateMenuHeader handleSubmit={handleSubmit} menuId={menuId} />
      <div className="w-full">
        <Card>
          <CardHeader>
            <CardTitle>Fill the details below to create the menu</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Label>Menu Name</Label>
              <Input
                placeholder="Menu Name"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <MenuItemContainer setData={setMenuJson} data={menuJson} />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
