import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ToastAction } from "@/components/ui/toast";
import { toast } from "@/components/ui/use-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { useEffect } from "react";

import UserCreateHeader from "../components/UserCreateHeader";
import { Button } from "@/components/ui/button";
import { addUser, getUserDetails, removeUser } from "@/services/userManagementService";

export function UserForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("id");

  const { register, handleSubmit, setValue } = useForm<any>({
    defaultValues: {
      is_active: false,
      is_default: false,
    },
  });

  useEffect(() => {
    if (user_id) {
      getUserDetails(user_id)
        .then((res) => {
          const response = res?.data;
          for (let key in response?.data) {
            setValue(key, response.data[key]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const onSubmit: SubmitHandler<any> = (data) => {
    const payload = {
      ...data,
    };

    if (user_id) {
      removeUser(user_id)
        .then(() => {
          navigate(-1);
          toast({
            duration: 3000,
            title: "User has been removed",
            description: "Warehouse has been updated successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    } else {
      addUser(payload)
        .then(() => {
          navigate(-1);
          toast({
            duration: 3000,
            title: "User Added",
            description: "User has been added successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "There was a problem with your request.",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        });
    }
  };

  return (
    <form
      className="grid flex-1 items-start gap-4 md:gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid flex-1 auto-rows-max gap-4">
        <UserCreateHeader add={user_id ? false : true} />
        <div className="grid gap-4 lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-3 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0 ">
              <CardHeader>
                <CardTitle>General Details</CardTitle>
                <CardDescription>
                  Manage your user permissions here
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      type="text"
                      className="w-full"
                      placeholder="Enter name"
                      {...register("name", {
                        onBlur(event) {
                          setValue("name", event?.target?.value);
                        },
                      })}
                    />
                  </div>

                  <div className="grid gap-3">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      className="w-full"
                      placeholder="Enter email"
                      {...register("email", {
                        onBlur(event) {
                          setValue("email", event?.target?.value);
                        },
                      })}
                    />
                  </div>
                  <Button className="w-full" size="sm" variant={user_id ? "destructive" : "default"}>
                    {user_id ? "Update User" : `Add User`}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </form>
  );
}
