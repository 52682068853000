import Logo from "/logo_white.svg";

function AuthVisual() {
  return (
    <div className="hidden relative overflow-hidden gap-4 p-10 md:flex flex-col items-start rounded-xl bg-gradient-to-br from-primary via-[#141414] via-50% to-primary to-95%">
    <div className="flex flex-col gap-10 items-start justify-start">
      <img src={Logo} className="h-6 object-contain" />
      <div className="flex flex-col gap-4">
        <h2 className="text-4xl text-gray-50 font-medium leading-snug">
          Simplifying Commerce for Everyone.
        </h2>
        <p className="text-base text-gray-50 font-light leading-snug">
          Take your business online in just 2 minutes.
        </p>
      </div>
    </div>
    <div className="rounded-md overflow-hidden">
      <img
        src="/assets/images/auth_placeholder.png"
        alt="Girl in a jacket"
        className="object-contain"
      />
    </div>
  </div>
  )
}

export default AuthVisual