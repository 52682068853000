import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import { EllipsisVertical } from "lucide-react";
import DomainConnect from "@/modules/Settings/components/DomainConnect";
import { useState } from "react";

const Dropdown = ({ data }: { data: any }) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div>
      <DomainConnect
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        data={data}
      />
      <DropdownMenu>
        <DropdownMenuTrigger
          asChild
          className="outline-none focus-visible:ring-0"
        >
          <Button
            className="outline-none focus-visible:ring-0"
            aria-haspopup="true"
            size="icon"
            variant="ghost"
          >
            <EllipsisVertical size={16} className="text-gray-600" />
            <span className="sr-only">Toggle menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            className="outline-none"
            onClick={() => setOpenMenu(true)}
          >
            Edit
          </DropdownMenuItem>
          {!data.is_primary && <DropdownMenuItem className=" p-0 outline-none">
            <button className="w-full text-left p-2">Make Primary</button>
          </DropdownMenuItem>}
          {data.type !== 'default' && <DropdownMenuItem className=" p-0 outline-none">
            <button className="w-full text-left p-2">Delete</button>
          </DropdownMenuItem>}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default Dropdown;
