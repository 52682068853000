import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Pencil } from "lucide-react";

// import { Button } from "@/components/ui/button";
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuTrigger,
// } from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { variantInterface } from "./interface";
import { useState } from "react";
import EditVariant from "./EditVariant";
import { Badge } from "@/components/ui/badge";

type option = {
  name: string;
  values: string[];
  isSave: boolean;
};

type props = {
  options: option[];
  variants: variantInterface[];
  setVariants: React.Dispatch<React.SetStateAction<variantInterface[]>>;
};

export function VariantsTable({ options, variants, setVariants }: props) {
  const [modal, setModal] = useState<null | variantInterface>(null);

  const columns: ColumnDef<variantInterface>[] = [
    // {
    //   header: "Image",
    //   cell: () => <Image />,
    // },
    {
      id: "1",
      header: ({
        table: {
          options: { data },
        },
      }) => {
        let item = data[0];

        return (
          <h1 className="capitalize">
            {item?.option_1_name}
            {item?.option_2_name && ` / ${item.option_2_name}`}
          </h1>
        );
      },
      cell: ({ row }) => (
        <Badge variant="outline" className="capitalize">
          {row?.original?.option_1_value}
          {row?.original?.option_2_value &&
            ` / ${row?.original?.option_2_value}`}
        </Badge>
      ),
    },
    {
      accessorKey: "price",
      header: "Original Price",
      cell: ({ row }) => <div>₹{row.getValue("price")}</div>,
    },
    {
      accessorKey: "discount_price",
      header: "Discounted Price",
      cell: ({ row }) => <div>₹{row.getValue("discount_price")}</div>,
    },
    {
      accessorKey: "inventory",
      header: "Inventory",
      cell: ({ row }) => <div>{row.original?.inventory}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <Pencil onClick={() => setModal(row.original)} size={16} />
          // <DropdownMenu>
          //   <DropdownMenuTrigger asChild>
          //     <Button variant="ghost" className="h-8 w-8 p-0">
          //       <span className="sr-only">Open menu</span>
          //       <MoreHorizontal className="h-4 w-4" />
          //     </Button>
          //   </DropdownMenuTrigger>
          //   <DropdownMenuContent align="end">
          //     <DropdownMenuItem >
          //       Edit
          //     </DropdownMenuItem>
          //     <DropdownMenuItem>Delete</DropdownMenuItem>
          //   </DropdownMenuContent>
          // </DropdownMenu>
        );
      },
    },
  ];

  const table = useReactTable({
    // @ts-ignore
    data: variants,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="w-full mt-10">
      {Boolean(modal) && (
        <EditVariant
          modal={modal}
          setModal={setModal}
          options={options}
          setVariants={setVariants}
        />
      )}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
