import { Button } from "@/components/ui/button";
import { User } from "lucide-react";
import { NavLink } from "react-router-dom";

export function EmptyUser() {
  return (
    <div className="grid h-screen w-full">
      <div className="flex flex-col">
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
          <div className="flex items-center">
            <h1 className="text-lg font-semibold md:text-2xl">User Management</h1>
          </div>
          <div
            className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm"
            x-chunk="dashboard-02-chunk-1"
          >
            <div className="flex flex-col items-center gap-1 text-center">
              <div className="flex mb-6">
                <User size={80} className=" text-slate-600" />
              </div>
              <h3 className="text-2xl font-bold tracking-tight">
                You have no users to give permissions
              </h3>
              <p className="text-sm text-muted-foreground">
                Streamline your access with our user management solutions, where
                efficiency meets reliability!
              </p>
              <NavLink to="/settings/user-management/add">
                <Button className="mt-4">Add User</Button>
              </NavLink>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
