import { z } from "zod";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import ProfileAvatar from "./ProfileAvatar";
import { createCustomerFormSchema } from "@/modules/Customers/schemas/customer";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { useEffect } from "react";

const CustomerForm = ({userData}: any) => {
  const form = useForm<z.infer<typeof createCustomerFormSchema>>({
    resolver: zodResolver(createCustomerFormSchema),
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
    },
  });

  useEffect(() => {
    form.setValue("name", userData?.name);
    form.setValue("email", userData?.email);
    form.setValue("phone_number", userData?.phone_number);
  }, [userData]);

  return (
    <Card className="lg:max-w-[400px] flex flex-col w-full">
      <CardHeader>
        <CardTitle>Customer Overview</CardTitle>
      </CardHeader>
      <Form {...form}>
        <form>
          <CardContent className=" w-full relative pt-6 border-t flex flex-col sm:flex-row gap-4">
            <CardDescription className="absolute right-4 -top-11">
              <ProfileAvatar />
            </CardDescription>
            <div className="w-full">
              <div className="grid gap-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Customer's Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter customer's name"
                          {...field}
                          className="w-full"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone number</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter customer's number"
                          {...field}
                          className="w-full"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter customer's email"
                          {...field}
                          className="w-full"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
          {/* <CardFooter className="border-t pt-4 flex gap-2">
            <Button className="w-full" type="submit">
              Add Customer
            </Button>
          </CardFooter> */}
        </form>
      </Form>
    </Card>
  );
};

export default CustomerForm;
