import {
  File,
  ImageOff,
  ListFilter,
  MoreHorizontal,
  PlusCircle,
} from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { ScrollArea } from "@/components/ui/scroll-area";
import { deleteBlog, getBlogList } from "@/services/blogServices";
import dateFormat from "@/utils/dateFormt";
import Pagination from "@/components/common/Pagination";

type blog = {
  organization: {
    author: string;
  };
  _id: string;
  title: string;
  status: string;
  thumbnail: any[];
  createdAt: Date;
};

export function BlogTable() {
  const [data, setData] = useState<blog[]>([]);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const selectedId = useRef("");

  const [filter, setFilter] = useState<string | null>(null);

  useEffect(() => {
    init();
  }, [filter, page]);

  function init() {
    getBlogList(filter)
      .then((res) => {
        setData(res.data.data);
        setMeta(res.data.meta);
      })
      .catch((err) => console.log(err));
  }

  function deleteProductHandler() {
    deleteBlog(selectedId.current)
      .then(() => {
        setConfirmationModal(false);
        init();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="flex h-full w-full flex-col gap-2 bg-muted/40">
      {confirmationModal && (
        <ConfirmationModal
          modal={confirmationModal}
          setModal={setConfirmationModal}
          handleDelete={deleteProductHandler}
        />
      )}
      <Tabs
        defaultValue="all"
        onValueChange={(value) =>
          setFilter(() => (value == "all" ? null : value))
        }
      >
        <div className="flex items-center">
          <TabsList>
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="draft">Draft</TabsTrigger>
            <TabsTrigger value="archived" className="hidden sm:flex">
              Archived
            </TabsTrigger>
          </TabsList>
          <div className="ml-auto flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-7 gap-1">
                  <ListFilter className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filter
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem checked>
                  Active
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Draft</DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Archived</DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button size="sm" variant="outline" className="h-7 gap-1">
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Export
              </span>
            </Button>
            <NavLink to="/blog/create">
              <Button size="sm" className="h-7 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Create Blog
                </span>
              </Button>
            </NavLink>
          </div>
        </div>
      </Tabs>
      <Card x-chunk="dashboard-06-chunk-0">
        <CardHeader>
          <CardTitle>Blog</CardTitle>
          <CardDescription>Manage all your blogs at one place</CardDescription>
        </CardHeader>

        <Table>
          <ScrollArea className="h-[400px]">
            <TableHeader>
              <TableRow className="text-[12px]">
                <TableHead>Title</TableHead>
                <TableHead>Author</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="hidden md:table-cell">
                  Created at
                </TableHead>
                <TableHead>
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.length ? (
                data?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <NavLink
                          to={`/blog/edit?id=${item._id}`}
                          className="flex flex-row items-center gap-4 text-xs"
                        >
                          <div>
                            {item?.thumbnail?.length ? (
                              <img
                                src={item?.thumbnail[0].url}
                                alt="Girl in a jacket"
                                className="object-cover rounded-md aspect-square bg-gray-200 w-[40px]"
                                width="64"
                                height="64"
                              />
                            ) : (
                              <div className="bg-slate-50 aspect-square w-[40px] border-[1px] border-gray-200 rounded-md flex justify-center flex-col items-center text-center text-slate-300">
                                <ImageOff />
                              </div>
                            )}
                          </div>
                          {item?.title}
                        </NavLink>
                      </TableCell>

                      <TableCell className="text-xs">
                        {item?.organization.author}
                      </TableCell>

                      <TableCell>
                        <Badge
                          className={`capitalize border-none shadow-none font-normal ${
                            item?.status === "active"
                              ? "bg-primary"
                              : item.status === "draft"
                              ? "bg-yellow-300 text-black"
                              : "bg-red-300 text-black"
                          }`}
                        >
                          {item?.status}
                        </Badge>
                      </TableCell>
                      <TableCell className="hidden md:table-cell text-xs">
                        {dateFormat(item?.createdAt)}
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <NavLink to={`/blog/edit?id=${item._id}`}>
                              <DropdownMenuItem>Edit</DropdownMenuItem>
                            </NavLink>
                            <DropdownMenuItem className=" p-0">
                              <button
                                className=" w-full text-left p-2"
                                onClick={() => {
                                  selectedId.current = item?._id;
                                  setConfirmationModal(true);
                                }}
                              >
                                Delete
                              </button>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <div className="p-6">
                <h2>No data found</h2>
                </div>
              )}
            </TableBody>
          </ScrollArea>
        </Table>

        <Pagination
          type="blogs"
          page={page}
          data={meta}
          setPage={setPage}
          nextPage={data.length < 10}
        />
      </Card>
    </div>
  );
}
