import { Button } from "@/components/ui/button";
import { CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Minus, Plus } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import UploadFile from "@/components/media/UploadFile";
import { file } from "@/utils/Interface";
import { useEffect, useState } from "react";

interface Banner {
  desktop: file | [];
  mobile: file | [];
}

interface Props {
  setBanners: React.Dispatch<React.SetStateAction<Banner[]>>;
  banners: Banner[];
}

const StoreCustomisation = ({ setBanners, banners }: Props) => {
  const addSection = () => {
    setBanners([...banners, { desktop: [], mobile: [] }]);
  };

  const removeSection = (index: number) => {
    banners.splice(index, 1);
    setBanners([...banners]);
  };

  function handleImageUpdate(desktop: file, mobile: file, index: number) {
    const updatedBanners = [...banners];
    updatedBanners[index] = { desktop, mobile };
    setBanners(updatedBanners);
  }

  return (
    <Accordion type="single" collapsible className="w-full gap-2 grid">
      <CardTitle className="text-xl p-0">Store Customisation</CardTitle>
      {banners?.map((item, index) => (
        <div className="flex items-start gap-2" key={index}>
          <AccordionItem
            value={`${index + 1}`}
            className="border rounded-xl w-[95%] overflow-hidden"
          >
            <AccordionTrigger className="py-3 px-4 hover:bg-slate-50 hover:no-underline">
              <Label>Banner {index + 1}</Label>
            </AccordionTrigger>
            <AccordionRender
              data={item}
              handleImageUpdate={handleImageUpdate}
              index={index}
            />
          </AccordionItem>
          <Minus
            onClick={() => removeSection(index)}
            size={20}
            className="bg-gray-600 hover:bg-gray-950 cursor-pointer text-white p-1 aspect-square rounded-full my-2"
          />

          <Plus
            size={20}
            className={`bg-gray-600 hover:bg-gray-950 cursor-pointer text-white p-1 aspect-square rounded-full my-2 ${
              (index !== banners?.length - 1 || banners?.length > 3) &&
              "invisible"
            }`}
            onClick={() => {
              if (index == banners?.length - 1 || banners?.length < 4) {
                addSection();
              }
            }}
          />
        </div>
      ))}

      <div className="grid gap-2">
        {banners.length < 1 && (
          <Button
            type="button"
            className="w-fit gap-2"
            size={"sm"}
            onClick={addSection}
          >
            <Plus size={16} /> Add Banner
          </Button>
        )}
      </div>
    </Accordion>
  );
};

export default StoreCustomisation;

function AccordionRender({ data, handleImageUpdate, index }: any) {
  const [desktopImage, setDesktopImage] = useState<file[]>([]);
  const [mobileImage, setMobileImage] = useState<file[]>([]);

  useEffect(() => {
    setDesktopImage(data?.desktop ?? []);
    setMobileImage(data?.mobile ?? []);
  }, []);

  useEffect(() => {
    if (desktopImage.length || mobileImage.length) {
      handleImageUpdate(desktopImage, mobileImage, index);
    }
  }, [desktopImage, mobileImage]);

  return (
    <AccordionContent className="px-4 pt-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="grid gap-3">
          <Label className="text-[12px] text-slate-600">Desktop</Label>
          <UploadFile
            maxLength={1}
            files={desktopImage}
            setFiles={setDesktopImage}
            accept="image"
          />
        </div>
        <div className="grid gap-3">
          <Label className="text-[12px] text-slate-600">Mobile</Label>
          <UploadFile
            maxLength={1}
            files={mobileImage}
            setFiles={setMobileImage}
            accept="image"
          />
        </div>
      </div>
    </AccordionContent>
  );
}
