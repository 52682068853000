import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet'
import { Button } from '../ui/button'
import { Home, LineChart, Package, PanelLeft, ShoppingCart, Users2 } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react';
import Logo from "@/assets/images/pinecart.svg";

export default function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <Sheet open={isOpen}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="sm:hidden" onClick={() => setIsOpen(true)}>
            <PanelLeft className="h-5 w-5" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="sm:max-w-xs">
          <nav className="grid gap-3 text-lg font-medium">
            <NavLink
              to="#"
              onClick={() => setIsOpen(false)}
              className="group h-10 w-200 shrink-0"
            >
            <img src={Logo} className=" w-full h-[24px] object-contain" />
              <span className="sr-only">Pinecart</span>
            </NavLink>
            <NavLink
              to="/"
              onClick={() => setIsOpen(false)}
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <Home className="h-5 w-5" />
              Home
            </NavLink>
            <NavLink
              to="/orders"
              onClick={() => setIsOpen(false)}
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <ShoppingCart className="h-5 w-5" />
              Orders
            </NavLink>
            <NavLink
              to="/products"
              onClick={() => setIsOpen(false)}
              className="flex items-center gap-4 px-2.5 text-foreground"
            >
              <Package className="h-5 w-5" />
              Products
            </NavLink>
            <NavLink
              to="/customers"
              onClick={() => setIsOpen(false)}
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <Users2 className="h-5 w-5" />
              Customers
            </NavLink>
            <NavLink
              to="/settings"
              onClick={() => setIsOpen(false)}
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <LineChart className="h-5 w-5" />
              Settings
            </NavLink>
          </nav>
        </SheetContent>
      </Sheet>
  )
}
