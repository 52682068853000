import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Marketing() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/abandoned-cart");
    }, [])
    
  return (
    <></>
  )
}

export default Marketing