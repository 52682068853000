const integrationData = [
  {
    name: "Google Analytics",
    category: { id: 1, name: "Analytics" },
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/integrations/6d5ecgoogle analytics.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "google_analytic",
    formFields: [
      {
        name: "id",
        type: "text",
      },
    ],
    script: (
      id: string
    ) => `<script async src="https://www.googletagmanager.com/gtag/js?id=${id}"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', ${id});</script>
      `,
  },
  {
    name: "Shiprocket",
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/integrations/1b92aShiprocket-logo-colored-1-690x153.png",
    category: { id: 2, name: "Shipping" },
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "ship_rocket",
    formFields: [
      {
        name: "email",
        type: "email",
      },
      {
        name: "password",
        type: "password",
      },
    ],
  },
  {
    name: "Razorpay",
    category: { id: 3, name: "Payments" },
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/integrations/a09bcRazorpay_logo.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "razorpay",
    formFields: [
      {
        name: "key",
        type: "text",
      },
      {
        name: "secret",
        type: "text",
      },
    ],
  },
  {
    name: "WhatsApp Chat Icon",
    category: { id: 4, name: "Customer Support" },
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/integrations/0a6a3whatsapp-logo-icon-png-svg.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "whatsapp",
    formFields: [
      {
        name: "Number",
        type: "text",
      },
    ],
  },
  // {
  //   name: "Wati.io",
  //   category: { id: 5, name: "Marketing" },
  //   icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/pinecart/integrations/wati.webp",
  //   description:
  //     "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
  //   is_installed: false,
  //   is_active: false,
  //   key: "wati",
  // },
  {
    name: "Google Tag Manager",
    category: { id: 1, name: "Analytics" },
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/pinecart/integrations/google_tag_manager.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "google_tag_manager",
    formFields: [
      {
        name: "id",
        type: "text",
      },
    ],
    script: (id: string) =>
      `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',${id});</script>`,
  },
  {
    name: "Meta Pixel",
    category: { id: 1, name: "Analytics" },
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/pinecart/integrations/facebook_meta.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "meta_pixel",
    formFields: [
      {
        name: "id",
        type: "text",
      },
    ],
    script: (
      id: string
    ) => `<script> !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=[];t=b.createElement(e);t.async=true;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', ${id});fbq('track', 'PageView');</script>
      `,
  },
  {
    name: "Microsoft Clarity",
    category: { id: 1, name: "Analytics" },
    icon: "https://csg100320027170d0f4.blob.core.windows.net/pinecart/pinecart/integrations/clarity.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "microsoft_clarity",
    formFields: [
      {
        name: "id",
        type: "text",
      },
    ],
    script: (id: string) =>
      `<script type="text/javascript">(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", ${id});</script>`,
  },
  {
    name: "HubSpot",
    category: { id: 1, name: "Marketing" },
    icon: "https://pinecart.blob.core.windows.net/pinecart-assets/hubspot.png",
    description:
      "Unlock website secrets! Google Analytics plugin tracks visitors & helps you optimize your site",
    is_installed: false,
    is_active: false,
    key: "hub_spot",
    formFields: [
      {
        name: "id",
        type: "text",
      },
    ],
    script: (id: string) =>
      `<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/${id}.js"></script>`,
  },
];

export default integrationData;
