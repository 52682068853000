import DomainConnect from "@/modules/Settings/components/DomainConnect";
import { useState } from "react";
import { Button } from "../ui/button";

const AddDomains = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <Button onClick={() => setOpenMenu(true)} size="sm" className="bg-primary">
        Add Custom Domain
      </Button>
      <DomainConnect openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </>
  );
};

export default AddDomains;
