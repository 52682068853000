import {  useRef } from "react";
import { ControllerRenderProps } from "react-hook-form";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import SunEditorCore from "suneditor/src/lib/core";

type props = {
  field: ControllerRenderProps<any, any>;
};

function RichText({ field }: props) {

  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  return (
    <>
      <SunEditor
        onChange={field.onChange}
        setContents={field.value}
        //@ts-ignore
        // onImageUploadBefore={onImageUploadBefore()}
        setOptions={{
          buttonList: [
            ["font", "fontSize", "formatBlock"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["align", "horizontalRule", "list", "table"],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["undo", "redo"],
            ["outdent", "indent"],
            ["link"],
            // ["preview", "print"],
            ["fullScreen", "showBlocks", "codeView"],
          ],
          //   image
          //   "imageGallery"
          //   imageGalleryUrl: `https://upload-g799.onrender.com/folder/${container_name}/${store_id}`,
        }}
        height="40vh"
        getSunEditorInstance={getSunEditorInstance}
      />
    </>
  );
}

export default RichText;

//   function handleImageUpload(file: any, info: any, uploadHandler: any) {
//     if (!file.length) {
//       return;
//     }

//     console.log("------------ first --------");

//     (async () => {
//       const { data } = await uploadFiles("blogs", file);

//       console.log(data);
//       uploadHandler(data[0]);
//     })();

//     console.log("------------- end ------------");

//     uploadHandler();

//     return true;
//   }

//   function onImageUploadBefore() {
//     return (files, _info, _core, uploadHandler) => {
//       (async () => {
//         const formData = new FormData();
//         formData.append("file", files[0]);

//         const { data } = await axios.post(
//           "http://localhost:1000/api/v1/upload/single",
//           formData
//         );

//         const res = {
//           result: [
//             {
//               url: data?.url,
//               name: "thumbnail",
//             },
//           ],
//         };

//         uploadHandler(res);
//       })();

//       // called here for stop double image
//       uploadHandler();
//     };
//   }
