const ComingSoon = () => {
  return (
    <div className="grid h-[80vh] w-full">
      <div className="flex flex-col">
        <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
          <div
            className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm"
            x-chunk="dashboard-02-chunk-1"
          >
            <div className="flex flex-col items-center gap-1 text-center">
              <h3 className="text-2xl font-bold tracking-tight">
                Coming Soon
              </h3>
              <p className="text-sm text-muted-foreground">
                Our expert team is cooking this module. It will be live soon. You will be notified once it's live.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ComingSoon;
